import EvaluadorJS from './evaluadorjs.vue';
import EvaluadorJSForm from './evaluadorjs-form.vue';
import RoutesUtils from '../../js/RoutesUtils.js';

var routes = [{
        path: '/evaluadorjs/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('evaluadorjs', ['ADMIN', 'CHECK_MAN'], EvaluadorJS),
        keepAlive: true
    },
    {
        path: '/evaluadorjs/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('evaluadorjs/new', ['ADMIN', 'CHECK_MAN'], EvaluadorJSForm)
    },
    {
        path: '/evaluadorjs/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('evaluadorjs/edit', ['ADMIN', 'CHECK_MAN'], EvaluadorJSForm)
    },
    {
        path: '/evaluadorjs/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('evaluadorjs/view', ['ADMIN', 'CHECK_MAN'], EvaluadorJSForm, { editable: false })
    },
];
export default routes;