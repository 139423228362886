<template>
  <r-page :page-content="true">
    <f7-navbar :back-link="$t('atras')">
      <!-- <f7-nav-title v-if="id && !readonly" sliding>{{$t('centrostrabajo.editar')}}</f7-nav-title> -->
      <f7-nav-title v-if="id" sliding>{{$t('centrostrabajo.detalle.title') + ' ' + id}}</f7-nav-title>
      <f7-nav-title v-else sliding>{{$t('centrostrabajo.nuevo')}}</f7-nav-title>
      <f7-nav-right>
        <f7-button
          v-if="!readonly"
          fill
          icon-f7="floppy_disk"
          :text="$t('common.guardar')"
          class="display-flex"
          @click="$refs.rform.saveItem()"
        ></f7-button>
        <template slot="right" v-else>
          <f7-button color="blue" @click="readonly=false">Editar</f7-button>
          <f7-button v-if="$refs.rform && $refs.rform.formData && $refs.rform.formData.estado && $refs.rform.formData.estado.estado !== 'CERRADO'" color="red" @click="deleteItem">Eliminar</f7-button>
        </template>
      </f7-nav-right>
    </f7-navbar>
    <r-form
      ref="rform"
      v-slot="{rform}"
      :itemId="id"
      :beforeSave="beforeSave"
      @save="onSave"
      @loadItem="loadItem"
      :readonly="readonly"
      :dbAdapter="CentrosTrabajo"
      :defaultData="{valores: [], tipo: 'SIMPLE'}"
    >
      <f7-card class="padding-vertical-half padding-right">
        <f7-card-content>
          <f7-row>
            <r-input
              class="col-10 list list-form no-hairlines"
              floating-label
              :label="$t('centrostrabajo.codigo')"
              :form="rform"
              type="text"
              name="codigo"
            ></r-input>
            <r-input
              class="col-25 list list-form no-hairlines"
              floating-label
              :label="$t('centrostrabajo.nombre')"
              :form="rform"
              type="text"
              name="nombre"
            ></r-input>
            <r-input
              class="col-25 list list-form no-hairlines"
              floating-label
              :label="$t('centrostrabajo.email')"
              :form="rform"
              type="text"
              name="email"
            ></r-input>
            <r-input
              v-if="rform.formData && rform.formData.estado && rform.formData.estado"
              class="col-10 list list-form no-hairlines"
              floating-label
              :label="$t('centrostrabajo.estado')"
              :form="rform"
              type="badge"
              name="estado.estado"
              :text="rform.formData && rform.formData.estado && rform.formData.estado.estado"
              :color="rform.formData && rform.formData.estado && rform.formData.estado.estado == 'CERRADO'? 'grey':'green'"
              :decorationConfig="readonly?{}:{
                icon: rform.formData && rform.formData.estado && (rform.formData.estado.estado == 'CERRADO' || rform.formData.estado.estado == 'CREADO')?'lock_open':'lock',
                color: rform.formData && rform.formData.estado && rform.formData.estado.estado == 'CERRADO'? 'green':'grey',
                tooltip: rform.formData && rform.formData.estado && (rform.formData.estado.estado == 'CERRADO' || rform.formData.estado.estado == 'CREADO')? 'Abrir':'cerrar',
                action: rform.formData && rform.formData.estado && (rform.formData.estado.estado == 'CERRADO' || rform.formData.estado.estado == 'CREADO')? abrir:cerrar
              }"
            ></r-input>
            <r-input
              class="col-10 list list-form no-hairlines"
              floating-label
              :label="'(1) ' + $t('centrostrabajo.prefijo')"
              :form="rform"
              type="text"
              name="prefijo"
            ></r-input>
            <r-input
              class="col-10 list list-form no-hairlines"
              floating-label
              :label="'(2) ' + $t('centrostrabajo.patron')"
              :form="rform"
              type="text"
              name="patron"
            ></r-input>
          </f7-row>
        </f7-card-content>
      </f7-card>
      <pre style="display:none;">{{rform.formData}}</pre>
    </r-form>
  </r-page>
</template>
<style scoped>
  .label-group {
    margin-top: 12px;
    margin-left: 20px;
    font-weight: bold;
  }
  .label-group-inspeccion {
    margin-top: 30px;
  }
  label.btn {
    padding: 1px;
    cursor: pointer;
    border: 1px solid;
  }
  .valor-row {
    position: relative;
  }
  .valor-codigo {
    padding-left: 3em;
  }
  .valor-controles {
    position:absolute;
    left: 0;
    top: 0;
    z-index: 2;
  }
  .valor-delete {
    position:absolute;
    left: 0;
    top: 7px;
  }
  .valor-reordenar {
    margin-top:2px;
    position:absolute;
    left: 12px;
    top: 11px;
  }

</style>
<style>
  #tab-expediente .list .bordeAzul .item-input-outline input,
  #tab-expediente .list .bordeAzul .item-input-outline textarea {
    color: blue;
    padding: 5px;
  }
  #tab-expediente .bordeAzul .item-input-outline .item-input-wrap:after {
    border-color: lightblue;

  }
  #tab-cliente .list .bordeAzul .item-input-outline input {
    color: blue;
  }
  #tab-cliente .bordeAzul .item-input-outline .item-input-wrap:after {
    border-color: lightblue;
  }

</style>
<script>
import Vue from "vue";
import rForm from "../../components/rForm.vue";
import rInput from "../../components/rInput.vue";
import rFiles from "../../components/rFiles.vue";
import Documento from "../../js/db/Documento.js";
import rAutocomplete from "../../components/rAutocomplete.vue";
import rTimepicker from "../../components/rTimepicker.vue";
import rRichTextInput from "../../components/rRichTextInput.vue";
import { Container, Draggable } from "vue-smooth-dnd";
import CentrosTrabajo from "../../js/db/CentrosTrabajo.js";

export default {
  components: {
    rForm,
    rInput,
    rTimepicker,
    rFiles,
    rRichTextInput,
    rAutocomplete,
    Container,
    Draggable
  },
  data: function() {
    var self = this;
    return {
      CentrosTrabajo,
      Documento,
      readonly: !this.editable
    };
  },
  props: {
    id: {
      type: String
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  computed: {},
  beforeDestroy() {},
  methods: {
    deleteItem: function() {
      var self = this;
      var app = self.$f7;
      app.dialog.confirm(
        self.$t("rlist.pregunta_eliminar_registro"),
        "Eliminar",
        function() {
          CentrosTrabajo.delete({itemId: self.id}).then(function(res) {
            if (res) {
              app.toast
                .create({
                  icon: '<i class="f7-icons">checkmark</i>',
                  text: self.$t("rlist.registro_eliminado"),
                  position: "center",
                  destroyOnClose: true,
                  closeTimeout: 2000
                })
                .open();
              self.$f7router.back()
            }
          }).catch(function(error){
            if(error.message){
              app.dialog.alert(error.message);
            } else {
              app.dialog.alert(error);
            }
          });
        }
      );
    },
    onDropValor: function(arr, dragResult){
      var self = this;
      const { removedIndex, addedIndex, payload } = dragResult;
      if (removedIndex === null && addedIndex === null) return arr;
      if (!arr) arr = [];
      var result = arr;
      let itemToAdd = payload;
      if (removedIndex !== null) {
        itemToAdd = Object.assign({},  result.splice(removedIndex, 1)[0]);
      }
      if (addedIndex !== null) {
        result.splice(addedIndex, 0, Object.assign({}, itemToAdd));
      }
    },
    deleteValor: function(centrotrabajo, indexValor){
      // centrotrabajo.valores.splice(indexValor,1);
    },
    beforeSave: function(data, resolve, reject) {
      // data.valores.forEach(valor => {
      //   if(!valor.nombre){
      //     valor.nombre = valor.valor;
      //   }
      // });
      resolve(data);
    },
    abrir: function() {
      var self = this;
      self.$set(self.$refs.rform.formData.estado,'estado','ABIERTO');
    },
    cerrar: function() {
      var self = this;
      self.$set(self.$refs.rform.formData.estado,'estado','CERRADO');
    },
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.$refs.rform.formData = res;
      self.readonly = true;
      app.toast
        .create({
          text: self.$t("centrostrabajo.centrotrabajo_guardada"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
    },
    loadItem: function(res) {
      var self = this;
      if(!res.tipo){
        res.tipo = "SIMPLE";
      }
    }
  },
  created: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;
  }
};
</script>