import DatabaseModel from './DatabaseModel.js';
import CacheService from './Cache.js';

var Selecciones = Object.create(DatabaseModel);

Selecciones.model = 'seleccion';
Selecciones.modelFront = 'seleccion';

Selecciones.envioEmail = function (id, forceReload) {
    var self = this;
    var forceReload = typeof forceReload == "undefined" || forceReload ? true : false;
    if (!id) reject({ error: 'itemId not defined' });
    var params = {
        url: self.server_zipado + self.prefix + self.model + '/' + id + '/envioEmail',
        method: 'POST',
        data: {}
    };
    return self.sendRequest(params, !forceReload);
};


export default Selecciones;