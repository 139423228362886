<template>
  <r-page :page-content="true">
    <f7-navbar :back-link="$t('atras')">
      <!-- <f7-nav-title v-if="id && !readonly" sliding>{{$t('paises.editar')}}</f7-nav-title> -->
      <f7-nav-title v-if="id" sliding>{{$t('paises.detalle.title') + ' ' + id}}</f7-nav-title>
      <f7-nav-title v-else sliding>{{$t('paises.nuevo')}}</f7-nav-title>
      <f7-nav-right>
        <f7-button
          v-if="!readonly"
          fill
          icon-f7="floppy_disk"
          :text="$t('common.guardar')"
          class="display-flex"
          @click="$refs.rform.saveItem()"
        ></f7-button>
        <template slot="right" v-else>
          <f7-button v-if="readonly" color="blue" @click="readonly=false">Editar</f7-button>
          <f7-button v-if="readonly" color="red" @click="deleteItem">Eliminar</f7-button>
        </template>
      </f7-nav-right>
    </f7-navbar>
    <r-form
      ref="rform"
      v-slot="{rform}"
      :itemId="id"
      :beforeSave="beforeSave"
      @save="onSave"
      @loadItem="loadItem"
      :readonly="readonly"
      :dbAdapter="Paises"
      :defaultData="{valores: [], tipo: 'SIMPLE'}"
    >
      <f7-card class="padding-vertical-half padding-right">
        <f7-card-content>
          <f7-row>
            <r-input
              class="col-10 list list-form no-hairlines"
              floating-label
              :label="$t('paises.codigo')"
              :form="rform"
              type="text"
              name="codigo"
            ></r-input>
            <r-input
              class="col-30 list list-form no-hairlines"
              floating-label
              :label="$t('paises.nombre')"
              :form="rform"
              type="text"
              name="nombre"
            ></r-input>

            <div class="block-chips col-30 list list-form no-hairlines">
              {{$t('paises.alias')}}
              <div style="border: 1px solid gray;border-radius: 5px;width: 100%;">
                <template>
                  <f7-chip 
                    v-for="(alias,index) in rform.formData.alias" :key="'alias['+index+readonly+']'"
                    style="margin:2px;"
                    :text="alias" 
                    :tooltip="alias"
                    :deleteable="!readonly"
                    @delete="deleteAlias(index)"
                  ></f7-chip>
                </template>
                <f7-link v-if="!readonly" icon-f7="plus_circle" color="blue" @click="nuevoAlias"></f7-link>
              </div>
            </div>

            <r-input
              class="col-15 list list-form no-hairlines"
              floating-label
              :label="$t('paises.plazo')"
              :form="rform"
              type="number"
              name="plazoDias"
            ></r-input>
          </f7-row>
        </f7-card-content>
      </f7-card>
      <pre style="display:none;">{{rform.formData}}</pre>
    </r-form>
  </r-page>
</template>
<style scoped>
  .label-group {
    margin-top: 12px;
    margin-left: 20px;
    font-weight: bold;
  }
  .label-group-inspeccion {
    margin-top: 30px;
  }
  label.btn {
    padding: 1px;
    cursor: pointer;
    border: 1px solid;
  }
  .valor-row {
    position: relative;
  }
  .valor-codigo {
    padding-left: 3em;
  }
  .valor-controles {
    position:absolute;
    left: 0;
    top: 0;
    z-index: 2;
  }
  .valor-delete {
    position:absolute;
    left: 0;
    top: 7px;
  }
  .valor-reordenar {
    margin-top:2px;
    position:absolute;
    left: 12px;
    top: 11px;
  }

</style>
<style>
  #tab-expediente .list .bordeAzul .item-input-outline input,
  #tab-expediente .list .bordeAzul .item-input-outline textarea {
    color: blue;
    padding: 5px;
  }
  #tab-expediente .bordeAzul .item-input-outline .item-input-wrap:after {
    border-color: lightblue;

  }
  #tab-cliente .list .bordeAzul .item-input-outline input {
    color: blue;
  }
  #tab-cliente .bordeAzul .item-input-outline .item-input-wrap:after {
    border-color: lightblue;
  }

</style>
<script>
import Vue from "vue";
import rForm from "../../components/rForm.vue";
import rInput from "../../components/rInput.vue";
import rFiles from "../../components/rFiles.vue";
import Documento from "../../js/db/Documento.js";
import rAutocomplete from "../../components/rAutocomplete.vue";
import rTimepicker from "../../components/rTimepicker.vue";
import rRichTextInput from "../../components/rRichTextInput.vue";
import { Container, Draggable } from "vue-smooth-dnd";
import Paises from "../../js/db/Paises.js";

export default {
  components: {
    rForm,
    rInput,
    rTimepicker,
    rFiles,
    rRichTextInput,
    rAutocomplete,
    Container,
    Draggable
  },
  data: function() {
    var self = this;
    return {
      Paises,
      Documento,
      readonly: !this.editable
    };
  },
  props: {
    id: {
      type: String
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  computed: {},
  beforeDestroy() {},
  methods: {
    deleteItem: function() {
      var self = this;
      var app = self.$f7;
      app.dialog.confirm(
        self.$t("rlist.pregunta_eliminar_registro"),
        "Eliminar",
        function() {
          Paises.delete({itemId: self.id}).then(function(res) {
            if (res) {
              app.toast
                .create({
                  icon: '<i class="f7-icons">checkmark</i>',
                  text: self.$t("rlist.registro_eliminado"),
                  position: "center",
                  destroyOnClose: true,
                  closeTimeout: 2000
                })
                .open();
              self.$f7router.back()
            }
          }).catch(function(error){
            if(error.message){
              app.dialog.alert(error.message);
            } else {
              app.dialog.alert(error);
            }
          });
        }
      );
    },
    nuevoAlias: function() {
      const self = this;
      const app = self.$f7;
      const pais = self.$refs.rform.formData;
      if(!pais.alias) {
        self.$set(pais, "alias", []);
      }
      app.dialog.prompt(null, 'Nuevo alias', (valor) => {
        pais.alias.push(valor.toUpperCase());
      });
    },
    deleteAlias: function(index) {
      const self = this;
      self.$refs.rform.formData.alias.splice(index, 1);
    },
    beforeSave: function(data, resolve, reject) {
      // data.valores.forEach(valor => {
      //   if(!valor.nombre){
      //     valor.nombre = valor.valor;
      //   }
      // });
      resolve(data);
    },
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.$refs.rform.formData = res;
      self.readonly = true;
      app.toast
        .create({
          text: self.$t("paises.centrotrabajo_guardada"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
    },
    loadItem: function(res) {
      var self = this;
      if(!res.tipo){
        res.tipo = "SIMPLE";
      }
    }
  },
  created: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;
  }
};
</script>