<template>
  <r-page>
    <!-- Top Navbar -->
    <f7-navbar :title="$t('alertas.title')" :back-link="$t('atras')"></f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height">
      <!-- rList Table CARD-->
      <r-dynamic-list
        ref="rlist"
        :dbAdapter="Alertas"
        :tableCode="'ALERTAS'"
        :tableMode="true"
        :virtualListParams="{height:32, rowsBefore:60, rowsAfter:60}"
        class="card full-height data-table"
        classContent="card-content full-height"
        :shownFields="['securityUserId','numero']"
        :massiveActions="true"
        :funcShowCheck="showCheckInList"
        :controlsWidth="110"
        :searchOnInit="true"
        :primary="['username']"
      >
        <template v-slot:tableLinks>
          <!-- <a class="link" href="/alertas/new/">{{$t('alertas.nueva')}}</a> -->
        </template>
        <template v-slot:optionsColumn="{item,rlist}" style="width: 100px;">
          <f7-link v-tooltip="$t('common.ver')" icon-f7="eye" color="green" :href="`/alertas/view/${item.id}/`"></f7-link>
          <f7-link v-roles="{route: $f7route.url, roles: ['ADMIN']}" v-tooltip="$t('common.editar')" icon-f7="square_pencil" color="blue" :href="`/alertas/edit/${item.id}/`"></f7-link>
          <f7-link v-roles="{route: $f7route.url, roles: ['ADMIN']}" v-tooltip="$t('common.eliminar')" icon-f7="trash" color="red" @click="rlist.deleteItem(item.id);"></f7-link>
        </template>
      </r-dynamic-list>
    </f7-page-content>

  </r-page>
</template>
<script>
import rForm from "../../components/rForm.vue";
import rDynamicList from "../../components/rDynamicList.vue";
import Alertas from "../../js/db/Alerta.js";
import rFiles from "../../components/rFiles.vue";
export default {
  components: {
    rDynamicList,
    rFiles,
    rForm
  },
  data: function() {
    return {
      Alertas
    };
  },
  computed: {},
  methods: {
    showCheckInList: function(item) {
      return false; 
    }
  },
  mounted: function() {
    var self = this;
    var app = self.$f7;
    /*app.dataTable.create({
      el: self.$refs.datatable
    });*/
  }
};
</script>