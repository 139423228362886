import { render, staticRenderFns } from "./empleados-form.vue?vue&type=template&id=2f756bf4&scoped=true"
import script from "./empleados-form.vue?vue&type=script&lang=js"
export * from "./empleados-form.vue?vue&type=script&lang=js"
import style0 from "./empleados-form.vue?vue&type=style&index=0&id=2f756bf4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f756bf4",
  null
  
)

export default component.exports