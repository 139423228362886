<template>
  <r-page>
    <!-- Top Navbar -->
    <f7-navbar :title="$t('selecciones.title')" :back-link="$t('atras')"></f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height">
      <!-- rList Table CARD-->
      <r-dynamic-list
        ref="rlist"
        :dbAdapter="Selecciones"
        :tableCode="'SELECCION'"
        :tableMode="true"
        :virtualListParams="{height:32, rowsBefore:60, rowsAfter:60}"
        class="card full-height data-table"
        classContent="card-content full-height"
        :shownFields="['abierta', 'gama.estado']"
        :massiveActions="true"
        :funcShowCheck="showCheckInList"
        :controlsWidth="90"
        :searchOnInit="true"
      >
        <template v-slot:tableLinks>
          <a class="link" href="/selecciones/new/">{{$t('selecciones.nuevo')}}</a>
          <!-- <f7-link v-tooltip="$t('selecciones.importar')" color="green" @click="importar()">{{$t('selecciones.importar')}}</f7-link> -->
        </template>
        <template v-slot:optionsColumn="{item,rlist}">
          <f7-link v-tooltip="$t('common.ver')" icon-f7="eye" color="green" :href="`/selecciones/view/${item.id}/`"></f7-link>
          <!-- <f7-link v-tooltip="$t('common.editar')" icon-f7="square_pencil" color="blue" :href="`/selecciones/edit/${item.id}/`"></f7-link> -->
          <f7-link v-if="!item.abierta && item.gama && item.gama.estado && item.gama.estado.estado == 'ABIERTA'" v-tooltip="$t('common.sendEmail')" icon-f7="envelope" color="blue" @click="enviarEmail(item);"></f7-link>
          <f7-link v-if="item.abierta" v-tooltip="$t('common.eliminar')" icon-f7="trash" color="red" @click="rlist.deleteItem(item.id);"></f7-link>
        </template>
      </r-dynamic-list>
    </f7-page-content>

  </r-page>
</template>
<script>
import rForm from "../../components/rForm.vue";
import rDynamicList from "../../components/rDynamicList.vue";
import Selecciones from "../../js/db/Selecciones.js";
import Utils from "../../js/Utils.js";
import rFiles from "../../components/rFiles.vue";
import Documento from "../../js/db/Documento.js";
export default {
  components: {
    rDynamicList,
    rFiles,
    rForm
  },
  data: function() {
    var self = this;
    return {
      Selecciones,
      Utils,
      Documento
    };
  },
  computed: {},
  methods: {
    showCheckInList: function(item) {
      return false; 
      // return item.estado && item.estado.estado && item.estado.estado == "ABIERTA"; 
    },
    importar(){
      var self = this;
      var app = self.$f7;
      app.popup.open(".popupimportar");
    },
    enviarEmail(item) {
      var self = this;
      var app = self.$f7;
      if (item.abierta) {
        app.dialog.alert("La selección elegida aún no está cerrada.");
        return;
      }
      app.dialog.confirm(
        "¿Esta seguro de enviar email de disponibilidad de recogida en transferencia para la referencia \"" + item.madre.referencia + "\" y etiqueta \"" + item.madre.etiqueta + "\"?",
        "Enviar email",
        function () {
          app.preloader.show();
          Selecciones.envioEmail(item.id).then((res) => {
            app.preloader.hide();
            app.dialog.alert("Envío de email realizado.");
          }).catch(function (e) {
            app.preloader.hide();
            let strMessage = "";
            if (typeof e == 'string') {
              strMessage = e;
            } else if (e.message) {
              strMessage = e.message;
            }
            console.log(e);
            app.dialog.alert("Ha habido algún error en el envío de email. " + strMessage);
          });
        }
      );

    },
    onImport: function (res) {
      var self = this;
      var app = self.$f7;
      self.Selecciones.importar(res.documento).then((res) => {
        self.$set(self.$refs.rformImport.formData,'documento',[]);
        self.$refs.rlist.submitFilterForm();
        app.preloader.hide();
      }).finall;
    },
    onImportError: function (e) {
      var self = this;
      var app = self.$f7;
      app.preloader.hide(); 
      // TODO mensaje error;
    },
    beforeSaveImport: function(data, resolve, reject) {
      var self = this;
      var app = self.$f7;
      var o = Object.assign({}, data);
      o.documento = Array.isArray(o.documento) ? o.documento[0] : o.documento;
      app.preloader.hide();
      resolve(o);
    },
    loadFile: function () {
      var self = this;
      var app = self.$f7;
      self.$refs.rformImport.saveItem();
      app.preloader.show();
    },
  },
  mounted: function() {
    var self = this;
    var app = self.$f7;
    /*app.dataTable.create({
      el: self.$refs.datatable
    });*/
  }
};
</script>