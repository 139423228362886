import Dispositivos from './dispositivos.vue';
import RoutesUtils from '../../js/RoutesUtils.js';

var routes = [{
        path: '/dispositivos/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('dispositivos', ['ADMIN'], Dispositivos),
        keepAlive: true
    }
];
export default routes;