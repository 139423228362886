import Utils from './Utils'

var DateUtils = {
    defaultConfig : {
        dayOfWeekNames: [
            'Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves',
            'Viernes', 'Sabado'
        ],
        dayOfWeekNamesShort: [
            'D', 'L', 'M', 'X', 'J', 'V', 'S'
        ],
        monthNames: [
            'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
            'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
        ],
        monthNamesShort: [
            'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun',
            'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'
        ]
    },
    dateFormat : function (inputOriginal, format, config) {
        var input = inputOriginal;
        if (typeof inputOriginal == "string") {
            input = new Date(input);
        }
        if (isNaN(Date.parse(input))) {
            return inputOriginal;
        }
        if (format === void 0) { format = 'YYYY.MM.DD HH:mm:ss'; }
        if (config === void 0) { config = {}; }
        config = Object.assign(Object.assign({}, this.defaultConfig), config);
        var year = input.getFullYear();
        var month = input.getMonth() + 1;
        var date = input.getDate();
        var hours24 = input.getHours();
        var hours12 = hours24 % 12 === 0 ? 12 : hours24 % 12;
        var minutes = input.getMinutes();
        var seconds = input.getSeconds();
        var weekday = input.getDay();
        var weekyear = DateUtils.getWeekNumber(input);
        return format
            // Normalize tokens
            .replace('YYYY', '%01%')
            .replace('YY', '%02%')
            .replace('MMMM', '%03%')
            .replace('MMM', '%04%')
            .replace('MM', '%05%')
            .replace('M', '%06%')
            .replace('DD', '%07%')
            .replace('D', '%08%')
            .replace('HH', '%09%')
            .replace('H', '%10%')
            .replace('hh', '%11%')
            .replace('h', '%12%')
            .replace('mm', '%13%')
            .replace('m', '%14%')
            .replace('ss', '%15%')
            .replace('s', '%16%')
            .replace('A', '%17%')
            .replace('a', '%18%')
            .replace('dddd', '%19%')
            .replace('dd', '%20%')
            .replace('d', '%21%')
            .replace('WW', '%22%')
            // Insert values
            .replace('%01%', Utils.padZeros(year, 4))
            .replace('%02%', Utils.padZeros(year % 100, 2))
            .replace('%03%', config.monthNames[month - 1])
            .replace('%04%', config.monthNamesShort[month - 1])
            .replace('%05%', Utils.padZeros(month, 2))
            .replace('%06%', "" + month)
            .replace('%07%', Utils.padZeros(date, 2))
            .replace('%08%', "" + date)
            .replace('%09%', Utils.padZeros(hours24, 2))
            .replace('%10%', "" + hours24)
            .replace('%11%', Utils.padZeros(hours12, 2))
            .replace('%12%', "" + hours12)
            .replace('%13%', Utils.padZeros(minutes, 2))
            .replace('%14%', "" + minutes)
            .replace('%15%', Utils.padZeros(seconds, 2))
            .replace('%16%', "" + seconds)
            .replace('%17%', hours24 < 12 ? 'AM' : 'PM')
            .replace('%18%', hours24 < 12 ? 'am' : 'pm')
            .replace('%19%', config.dayOfWeekNames[weekday])
            .replace('%20%', config.dayOfWeekNamesShort[weekday])
            .replace('%21%', "" + weekday)
            .replace('%22%', "" + weekyear);
    },

    getWeekNumber: function(d) {
        // Copy date so don't modify original
        d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
        // Set to nearest Thursday: current date + 4 - current day number
        // Make Sunday's day number 7
        d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
        // Get first day of year
        var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
        // Calculate full weeks to nearest Thursday
        var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
        // Return array of year and week number
        return weekNo;
    }
}

export default DateUtils;