import Alertas from './alertas.vue';
import AlertaForm from './alertas-form.vue';
import RoutesUtils from '../../js/RoutesUtils.js';

var routes = [{
        path: '/alertas/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('alertas', ['ADMIN', 'CHECK_MAN'], Alertas),
        keepAlive: true
    },
    {
        path: '/alertas/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('alertas/new', ['ADMIN', 'CHECK_MAN'], AlertaForm)
    },
    {
        path: '/alertas/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('alertas/edit', ['ADMIN', 'CHECK_MAN'], AlertaForm)
    },
    {
        path: '/alertas/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('alertas/view', ['ADMIN', 'CHECK_MAN'], AlertaForm, { editable: false })
    },
];
export default routes;