<template>
  <r-page>
    <!-- Top Navbar -->
    <f7-navbar :title="$t('empleados.title')" :back-link="$t('atras')"></f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height">
      <!-- rList Table CARD-->
      <r-dynamic-list
        ref="rlist"
        :dbAdapter="Empleados"
        :tableCode="'EMPLEADOS'"
        :tableMode="true"
        :virtualListParams="{height:32, rowsBefore:60, rowsAfter:60}"
        class="card full-height data-table"
        classContent="card-content full-height"
        :shownFields="['securityUserId','numero']"
        :massiveActions="true"
        :funcShowCheck="showCheckInList"
        :controlsWidth="110"
        :searchOnInit="true"
        :primary="['username']"
      >
        <template v-slot:tableLinks>
          <a class="link" href="/empleados/new/">{{$t('empleados.nuevo')}}</a>
          <!-- <f7-link v-tooltip="$t('empleados.importar')" color="green" @click="importar()">{{$t('empleados.importar')}}</f7-link> -->
          <!-- <r-form
            cass="form-inline"
            ref="rformImport"
            v-slot="{rform}"
            :readonly="false"
            :dbAdapter="null"
            :documentoAdapter="Documento"
            :beforeSave="beforeSaveImport"
            @save="onImport"
            @error="onImportError"
          >
            <r-files path="/empleados/" :form="rform" name="documento" :maxFiles='1' label="Importar Documento" @loadFile="loadFile" :preview="false"></r-files>
          </r-form> -->
        </template>
        <template v-slot:optionsColumn="{item,rlist}" style="width: 100px;">
          <f7-link v-tooltip="$t('common.ver')" icon-f7="eye" color="green" :href="`/empleados/view/${item.username}/`"></f7-link>
          <f7-link v-tooltip="$t('common.editar')" icon-f7="square_pencil" color="blue" :href="`/empleados/edit/${item.username}/`"></f7-link>
          <f7-link v-if="item.securityUserId" v-roles="{route: $f7route.url, roles: ['ADMIN']}" icon-f7="lock" color="orange" @click="resetPass(item.securityUserId)" v-tooltip="'Resetear contraseña'"></f7-link>
          <f7-link v-tooltip="$t('common.eliminar')" icon-f7="trash" color="red" @click="rlist.deleteItem(item.username);"></f7-link>
        </template>
      </r-dynamic-list>
    </f7-page-content>

  </r-page>
</template>
<script>
import rForm from "../../components/rForm.vue";
import rDynamicList from "../../components/rDynamicList.vue";
import Empleados from "../../js/db/Empleado.js";
import Utils from "../../js/Utils.js";
import rFiles from "../../components/rFiles.vue";
import Documento from "../../js/db/Documento.js";
import Usuario from "./../../js/db/Usuario.js";
import CacheService from '../../js/db/Cache.js';
export default {
  components: {
    rDynamicList,
    rFiles,
    rForm
  },
  data: function() {
    return {
      Empleados,
      Utils,
      Documento,
      Usuario,
      CacheService
    };
  },
  computed: {},
  methods: {
    showCheckInList: function(item) {
      return false; 
      // return item.estado && item.estado.estado && item.estado.estado == "ABIERTA"; 
    },
    importar(){
      var self = this;
      var app = self.$f7;
      app.popup.open(".popupimportar");
    },
    onImport: function (res) {
      var self = this;
      var app = self.$f7;
      self.Empleados.importar(res.documento).then((res) => {
        self.$set(self.$refs.rformImport.formData,'documento',[]);
        self.$refs.rlist.submitFilterForm();
        app.preloader.hide();
      }).finall;
    },
    onImportError: function (e) {
      var self = this;
      var app = self.$f7;
      app.preloader.hide(); 
      // TODO mensaje error;
    },
    beforeSaveImport: function(data, resolve, reject) {
      var self = this;
      var app = self.$f7;
      var o = Object.assign({}, data);
      o.documento = Array.isArray(o.documento) ? o.documento[0] : o.documento;
      app.preloader.hide();
      resolve(o);
    },
    loadFile: function () {
      var self = this;
      var app = self.$f7;
      self.$refs.rformImport.saveItem();
      app.preloader.show();
    },
    resetPass: function(itemId) {
      var self = this;
      var app = self.$f7;
      app.dialog.confirm(
        "¿Seguro que desea resetear la contraseña del usuario seleccionado?",
        "Resetear Contraseña",
        function() {
          app.preloader.show();
          Empleados.resetPass({itemId: itemId})
            .then(function(res) {
              app.preloader.hide();
              CacheService.clearCache(Empleados.model);
              self.$refs.rlist.refresh();
              app.toast
                .create({
                  text: 'Contraseña Reseteada',
                  position: "center",
                  closeTimeout: 2000
                })
                .open();
            })
            .catch(function(error) {
              app.preloader.hide();
              app.dialog.alert(error.error.message);
            });
        }
      );
    }
  },
  mounted: function() {
    var self = this;
    var app = self.$f7;
    /*app.dataTable.create({
      el: self.$refs.datatable
    });*/
  }
};
</script>