import RoutesUtils from '../../js/RoutesUtils.js';
import SeleccionForm from './seleccion-form.vue';
import Selecciones from './selecciones.vue';
import LineasSelecciones from './lineasselecciones.vue';

var routes = [{
        path: '/selecciones/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('selecciones', ['ADMIN', 'CHECK_MAN'], Selecciones),
        keepAlive: true
    },
    {
        path: '/selecciones/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('selecciones/new', ['ADMIN', 'CHECK_MAN'], SeleccionForm)
    },
    {
        path: '/selecciones/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('selecciones/edit', ['ADMIN', 'CHECK_MAN'], SeleccionForm)
    },
    {
        path: '/selecciones/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('selecciones/view', ['ADMIN', 'CHECK_MAN'], SeleccionForm, { editable: false })
    },
    {
        path: '/selecciones/lineas/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('selecciones/lineas', ['ADMIN', 'CHECK_MAN'], LineasSelecciones),
        keepAlive: true
    },
];
export default routes;