var render = function render(){var _vm=this,_c=_vm._self._c;return _c('r-page',{attrs:{"page-content":true}},[_c('f7-navbar',{attrs:{"back-link":_vm.$t('atras')}},[(_vm.id)?_c('f7-nav-title',{attrs:{"sliding":""}},[_vm._v(_vm._s(_vm.$t('demanda.detalle.title')))]):_c('f7-nav-title',{attrs:{"sliding":""}},[_vm._v(_vm._s(_vm.$t('demanda.detalle.nuevo')))]),_vm._v(" "),_c('f7-nav-right',[(!_vm.readonly)?_c('f7-button',{staticClass:"display-flex",attrs:{"fill":"","icon-f7":"floppy_disk","text":"Guardar"},on:{"click":function($event){return _vm.$refs.rform.saveItem()}}}):_c('template',{slot:"right"},[_c('f7-button',{attrs:{"color":"red"},on:{"click":_vm.deleteItem}},[_vm._v("Eliminar")])],1)],2)],1),_vm._v(" "),_c('r-form',{ref:"rform",attrs:{"itemId":_vm.id,"beforeSave":_vm.beforeSave,"readonly":_vm.readonly,"dbAdapter":_vm.PrevisionDemandas,"defaultData":{valores: [], tipo: 'SIMPLE'}},on:{"save":_vm.onSave,"loadItem":_vm.loadItem},scopedSlots:_vm._u([{key:"default",fn:function({rform}){return [_c('f7-row',{staticClass:"list list-form no-hairlines",attrs:{"no-gap":""}},[_c('f7-col',{attrs:{"width":"100","medium":"90"}},[_c('f7-card',{staticClass:"padding-vertical-half padding-right",staticStyle:{"z-index":"3000"}},[_c('f7-card-content',[_c('f7-row',{staticClass:"list list-form no-hairlines",attrs:{"no-gap":""}},[_c('r-input',{staticClass:"col-50",attrs:{"floating-label":"","label":_vm.$t('demanda.detalle.nombre'),"form":rform,"type":"text","name":"nombre"}}),_vm._v(" "),_c('r-input',{staticClass:"col-15",attrs:{"floating-label":"","label":_vm.$t('demanda.fecha_carga'),"form":rform,"type":"datepicker","name":"fechaCarga","readonly":true,"overrideReadonly":true,"calendar-params":{
                    closeOnSelect: true,
                    routableModals: false,
                    dateFormat: {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    },
                  }}}),_vm._v(" "),_c('r-input',{staticClass:"col-15 bordeAzul",attrs:{"floating-label":"","label":_vm.$t('demanda.detalle.fecha_prevision'),"form":rform,"type":"datepicker","name":"fechaPrevision","calendar-params":{
                    closeOnSelect: true,
                    routableModals: false,
                    dateFormat: {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    },
                  }}}),_vm._v(" "),_c('r-input',{staticClass:"col-15",attrs:{"floating-label":"","label":_vm.$t('demanda.usuario_creacion'),"form":rform,"type":"text","name":"creationUserName","readonly":true,"overrideReadonly":true}})],1)],1)],1)],1),_vm._v(" "),_c('f7-col',{attrs:{"width":"100","medium":"10"}},[_c('r-files',{attrs:{"path":"/gamas/","form":rform,"documentoAdapter":_vm.Documento,"name":"documento","maxFiles":1,"label":"Documento","preview":true,"readonly":true,"overrideReadonly":true},on:{"loadFile":_vm.loadFile}})],1)],1),_vm._v(" "),_c('pre',{staticStyle:{"display":"none"}},[_vm._v(_vm._s(rform.formData))])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }