import DatabaseModel from './DatabaseModel.js';

var Usuario = Object.create(DatabaseModel);

Usuario.model = 'user';

Usuario.getList = function(params) {
    var self = this;
    params = params || {};
    return new Promise(function(resolve, reject) {
        self.request.promise({
            url: self.server + 'security/' + self.model + '/GL-ZIPADO',
            method: 'GET',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response).error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
};

Usuario.get = function(params) {
    var self = this;
    return new Promise(function(resolve, reject) {
        if (!params.itemId) reject({ error: 'itemId not defined' });
        self.request.promise({
            url: self.server + 'security/' + self.model + '/GL-ZIPADO/' + params.itemId + '/',
            method: 'GET',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true,
            data: {}
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data.dto);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response).error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
};

Usuario.save = function(params) {
    var self = this;
    if (params.itemId) {
        return self.put(params);
    } else {
        return self.post(params);
    }
}

Usuario.put = function(params) {
    var self = this;
    return new Promise(function(resolve, reject) {
        if (!params.itemId) reject({ error: 'itemId not defined' });
        self.request.promise({
            url: self.server + 'security/' + self.model + '/GL-ZIPADO/' + params.itemId + '/',
            method: 'PUT',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true,
            data: params.data
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data.dto);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response).error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
}

Usuario.post = function(params) {
    var self = this;
    return new Promise(function(resolve, reject) {
        self.request.promise({
            url: self.server + 'security/' + self.model + '/GL-ZIPADO/',
            method: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true,
            data: params.data
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data.dto);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response).error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
}

Usuario.delete = function(params) {
    var self = this;
    return new Promise(function(resolve, reject) {
        if (!params.itemId) reject({ error: 'itemId not defined' });
        self.request.promise({
            url: self.server + 'security/' + self.model + '/GL-ZIPADO/' + params.itemId + '/',
            method: 'DELETE',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true,
            data: params.data
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data.dto);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response).error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
}

Usuario.auth = function(params) {
    var self = this;
    //params.username = 'USER_INSPECCIONES_ADMIN';
    //params.password = 'ADMIN';
    return new Promise(function(resolve, reject) {
        self.request.promise({
            url: self.server + 'security/oauth/token',
            method: 'POST',
            contentType: 'application/x-www-form-urlencoded',
            dataType: 'json',
            crossDomain: true,
            headers: {
                'Authorization': 'Basic ' + btoa(self.client_id + ":" + self.client_secret)
            },
            data: {
                grant_type: 'password',
                username: params.username,
                password: params.password
            }
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data);
            }

        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response));
            } catch (e) {
                reject(xhr.response);
            }
        });

    });
};

Usuario.me = function(params) {
    var self = this;
    return new Promise(function(resolve, reject) {
        self.request.promise({
            url: self.server_zipado + self.prefix + 'me',
            method: 'GET',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data.dto);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response));
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
};

Usuario.cambiarPass = function(params) {
    var self = this;
    return new Promise(function(resolve, reject) {
        self.request.promise({
            url: self.server + 'security/' + self.model + '/change_password',
            method: 'PUT',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true,
            data: params
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data);
            }

        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response));
            } catch (e) {
                reject(xhr.response);
            }
        });

    });
};

Usuario.resetPass = function(params) {
    var self = this;
    if (!params.itemId) reject({ error: 'itemId not defined' });
    return new Promise(function(resolve, reject) {
        self.request.promise({
            url: self.server + 'security/' + self.model + '/reset_password/' + params.itemId,
            method: 'PUT',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data);
            }

        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response));
            } catch (e) {
                reject(xhr.response);
            }
        });

    });
};

Usuario.getCodigoAcceso = function(params) {
    var self = this;
    return new Promise(function(resolve, reject) {
        self.request.promise({
            url: self.server_zipado + self.prefix + 'getCodigoAcceso',
            method: 'GET',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data.dto);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response));
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
};

export default Usuario;