<template>
  <r-page>
    <!-- Top Navbar -->
    <f7-navbar :title="$t('ubicaciones.title')" :back-link="$t('atras')"></f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height">
      <!-- rList Table CARD-->
      <r-dynamic-list
        ref="rlist"
        :dbAdapter="Ubicaciones"
        :tableCode="'UBICACIONES'"
        :tableMode="true"
        :virtualListParams="{height:32, rowsBefore:60, rowsAfter:60}"
        class="card full-height data-table"
        classContent="card-content full-height"
        :shownFields="['ignorarGalia']"
        :massiveActions="true"
        :primary="['nombre']"
        :funcShowCheck="showCheckInList"
        :controlsWidth="110"
        :searchOnInit="true"
      >
        <template v-slot:tableLinks>
          <a class="link" href="/ubicaciones/new/">{{ $t('ubicaciones.nueva') }}</a>
        </template>
        <template v-slot:optionsColumn="{item,rlist}">
          <f7-link v-tooltip="$t('common.ver')" icon-f7="eye" color="green" :href="`/ubicaciones/view/${item.nombre}/`"></f7-link>
          <f7-link v-roles="{ route: $f7route.url, roles: ['ADMIN', 'CHECK_MAN'] }" v-tooltip="$t('common.editar')" icon-f7="square_pencil" color="blue" :href="`/ubicaciones/edit/${item.nombre}/`"></f7-link>
          <f7-link v-roles="{ route: $f7route.url, roles: ['ADMIN', 'CHECK_MAN'] }" v-tooltip="$t('common.eliminar')" icon-f7="trash" color="red" @click="rlist.deleteItem(item.nombre);"></f7-link>
        </template>
      </r-dynamic-list>
    </f7-page-content>

  </r-page>
</template>
<script>
import rForm from "../../components/rForm.vue";
import rDynamicList from "../../components/rDynamicList.vue";
import Ubicaciones from "../../js/db/Ubicaciones.js";
import Utils from "../../js/Utils.js";
import rFiles from "../../components/rFiles.vue";
import Documento from "../../js/db/Documento.js";
export default {
  components: {
    rDynamicList,
    rFiles,
    rForm
  },
  data: function() {
    return {
      Ubicaciones,
      Utils,
      Documento
    };
  },
  computed: {},
  methods: {
    showCheckInList: function(item) {
      return false; 
    },
  },
  mounted: function() {
    var self = this;
    var app = self.$f7;
    /*app.dataTable.create({
      el: self.$refs.datatable
    });*/
  }
};
</script>