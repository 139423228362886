<template>
  <r-page :page-content="true">
    <f7-navbar :back-link="$t('atras')">
      <f7-nav-title v-if="id && !readonly" sliding>{{$t('empleados.editar')}}</f7-nav-title>
      <f7-nav-title v-else-if="id" sliding>{{$t('empleados.detalle.title')}}</f7-nav-title>
      <f7-nav-title v-else sliding>{{$t('empleados.nuevo')}}</f7-nav-title>
      <f7-nav-right>
        <f7-button
          v-if="!readonly"
          fill
          icon-f7="floppy_disk"
          text="Guardar"
          class="display-flex"
          @click="$refs.rform.saveItem()"
        ></f7-button>
        <template slot="right" v-else>
          <f7-button color="blue" @click="readonly=false">Editar</f7-button>
          <f7-button color="red" @click="deleteItem">Eliminar</f7-button>
        </template>
      </f7-nav-right>
    </f7-navbar>
    <r-form
      ref="rform"
      v-slot="{rform}"
      :itemId="id"
      @save="onSave"
      :readonly="readonly"
      :dbAdapter="Empleado"
      :beforeSave="beforeSaveEmpleado"
      :documentoAdapter="Documento"
    >
      <f7-card class="padding-vertical-half padding-right" style="z-index: 3000">
        <f7-card-content>
          <f7-row no-gap class="list list-form no-hairlines">
            <f7-col width="50" medium="25">
              <ul>
                <r-input
                  class="col-50 medium-50"
                  floating-label
                outline
                  :label="$t('empleados.username')"
                  :form="rform"
                  type="text"
                  name="username"
                ></r-input>
              </ul>
            </f7-col>
            <f7-col width="50" medium="25">
              <ul>
                <r-input
                  floating-label
                outline
                  :label="$t('empleados.codOperario')"
                  :form="rform"
                  type="text"
                  name="codOperario"
                ></r-input>
              </ul>
            </f7-col>
            <f7-col width="100" medium="50">
              <ul>
                <r-input
                  floating-label
                outline
                  :label="$t('empleados.nombre')"
                  :form="rform"
                  type="text"
                  name="nombre"
                ></r-input>
              </ul>
            </f7-col>
            <f7-col width="100" medium="50">
              <ul>
                <r-input
                  floating-label
                  outline
                  :label="$t('empleados.email')"
                  :form="rform"
                  type="text"
                  name="email"
                ></r-input>
              </ul>
            </f7-col>
            <f7-col width="100" medium="50">
              <ul>
                <r-input
                  floating-label
                  outline
                  :label="$t('empleados.telefono')"
                  :form="rform"
                  type="text"
                  name="telefono"
                ></r-input>
              </ul>
            </f7-col>
            <f7-col width="50" medium="10">
              <r-autocomplete
                :dbAdapter="Rol"
                floating-label 
                outline
                :label="$t('empleados.rol')"
                :form="rform"
                name="rol"
                idProp="valor"
                labelProp="nombre"
              ></r-autocomplete> 
            </f7-col>
            <f7-col width="50" medium="40">
              <r-autocomplete-m2m
                :dbAdapter="CentrosTrabajo"
                floating-label
                outline
                :label="$t('empleados.centros_trabajo')"
                :form="rform"
                type="text"
                name="centrosTrabajo"
                :idProp="null"
                :valueProperty="'codigo'"
                labelProp="nombre"
                :readonly="readonly"
                :lineBreak="true"
              ></r-autocomplete-m2m>
            </f7-col>
            <f7-col width="50" medium="10" small="20">
              <ul>
                <r-input
                  :label="$t('empleados.crearuser')"
                floating-label
                outline
                  :form="rform"
                  type="check"
                  name="createdUser"
                ></r-input>
              </ul>
            </f7-col>
            <f7-col width="50" medium="10">
              <ul>
                <r-input
                  :label="$t('empleados.activo')"
                floating-label
                outline
                  :form="rform"
                  type="check"
                  name="activo"
                ></r-input>
                <!-- <r-files
                  class="col-100 medium-50 margin-top"
                  :form="rform"
                  name="firma"
                  :documentoAdapter="Documento"
                  label="Añadir Firma"
                  :maxFiles="1"
                ></r-files> -->
              </ul>
            </f7-col>
            <f7-col width="50" medium="10">
              <ul>
                <r-input
                floating-label
                outline
                  :label="$t('empleados.fecha_alta')"
                  :form="rform"
                  type="datepicker"
                  :name="'fechaAlta'"
                  :calendar-params="{
                    closeOnSelect: true,
                    routableModals: false,
                    dateFormat: {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    },
                  }"
                />
              </ul>
            </f7-col>
            <f7-col width="50" medium="10">
              <ul>
                <r-input
                floating-label
                outline
                  :label="$t('empleados.fecha_baja')"
                  :form="rform"
                  type="datepicker"
                  :name="'fechaBaja'"
                  :calendar-params="{
                    closeOnSelect: true,
                    routableModals: false,
                    dateFormat: {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    },
                  }"
                />
              </ul>
            </f7-col>
            <f7-col width="50" medium="10">
              <ul>
                <r-input
                floating-label
                outline
                  :label="$t('empleados.dni')"
                  :form="rform"
                  type="text"
                  name="dni"
                />
                <!-- enlace para mostrar popup de colegas -->
                <!-- <f7-col class="col-100 medium-100">
                  <r-autocomplete-m2m
                    :dbAdapter="Empleado"
                    floating-label
                    outline
                    :label="$t('empleados.colegas')"
                    icon-f7="person_badge_plus"
                    :form="rform"
                    name="colegas"
                    idProp="username"
                    labelProp="nombre"
                    prefixProp="username"
                    type="textarea"
                    :lineBreak="true"
                  ></r-autocomplete-m2m>
                </f7-col> -->
              </ul>
            </f7-col>
          </f7-row>
        </f7-card-content>
      </f7-card>
      <pre style="display:none;">{{rform.formData}}</pre>
  </r-form>
  </r-page>
</template>
<style scoped>
  .label-group {
    margin-top: 12px;
    margin-left: 20px;
    font-weight: bold;
  }
  .label-group-inspeccion {
    margin-top: 30px;
  }
  label.btn {
    padding: 1px;
    cursor: pointer;
    border: 1px solid;
  }

</style>
<script>
import Vue from "vue";
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import rFiles from "./../../components/rFiles.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import rAutocompleteM2m from "./../../components/rAutocompleteM2m.vue";
import rTimepicker from "./../../components/rTimepicker.vue";
import Empleado from "./../../js/db/Empleado.js";
import Documento from "./../../js/db/Documento.js";
import CentrosTrabajo from "./../../js/db/CentrosTrabajo.js";
import Rol from "./../../js/db/Rol.js";

export default {
  components: {
    rForm,
    rInput,
    rTimepicker,
    rFiles,
    rAutocomplete,
    rAutocompleteM2m
  },
  data: function() {
    var self = this;
    return {
      Empleado,
      Rol,
      readonly: !this.editable,
      Documento,
      CentrosTrabajo
    };
  },
  props: {
    id: {
      type: String
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  computed: {},
  beforeDestroy() {},
  methods: {
    beforeSaveEmpleado: function(data, resolve, reject) {
      const self = this;
      if (data.firma && typeof data.firma.length != "undefined") {
        data.firma = data.firma[0];
      }
      resolve(data);
    },
    deleteItem: function() {
      var self = this;
      var app = self.$f7;
      app.dialog.confirm(
        self.$t("rlist.pregunta_eliminar_registro"),
        "Eliminar",
        function() {
          Empleado.delete({itemId: self.id}).then(function(res) {
            if (res) {
              app.toast
                .create({
                  icon: '<i class="f7-icons">checkmark</i>',
                  text: self.$t("rlist.registro_eliminado"),
                  position: "center",
                  destroyOnClose: true,
                  closeTimeout: 2000
                })
                .open();
              self.$f7router.back()
            }
          }).catch(function(error){
            app.dialog.alert(error.message);
          });
        }
      );
    },
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.$refs.rform.formData = res;
      self.readonly = true;
      app.toast
        .create({
          text: self.$t("empleados.guardado_ok"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
    },
    // Código pendiente de terminar para filtrar el autocomplete de roles
    rolesPermitidos: function(autocomplete){
      const self = this;
      return function(query, render) {
        var results = autocomplete.items.filter(item => {  return !["DISPOSITIVO"].includes(item.valor)}).map(el => {el['txtValue'] = el["valor"]; return el});
        render(results);
      };
    }

  },
  created: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;
  }
};
</script>