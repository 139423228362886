<template>
  <r-page>
    <!-- Top Navbar -->
    <f7-navbar :title="$t('dispositivo.dispositivos')" :back-link="$t('atras')"></f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height">
      <!-- rList Table CARD-->
      <r-dynamic-list
        ref="rlist"
        :dbAdapter="Dispositivo"
        :tableCode="'DISPOSITIVO'"
        :tableMode="true"
        :virtualListParams="{height:32, rowsBefore:60, rowsAfter:60}"
        class="card full-height data-table"
        classContent="card-content full-height"
        :massiveActions="true"
        :funcShowCheck="showCheckInList"
        :controlsWidth="50"
        :searchOnInit="true"
        :primary="['uuid']"
      >
        <template v-slot:tableLinks>
        </template>
        <template v-slot:optionsColumn="{item}" style="width: 100px;">
          <f7-link v-if="item.activo" v-roles="{route: $f7route.url, roles: ['ADMIN']}" icon-f7="lock" color="red" @click="desactivar(item.uuid)" v-tooltip="'Desactivar dispositivo'"></f7-link>
          <f7-link v-else v-roles="{route: $f7route.url, roles: ['ADMIN']}" icon-f7="lock_open" color="green" @click="activar(item.uuid)" v-tooltip="'Activar dispositivo'"></f7-link>
        </template>
      </r-dynamic-list>
    </f7-page-content>

  </r-page>
</template>
<script>
import rForm from "../../components/rForm.vue";
import rDynamicList from "../../components/rDynamicList.vue";
import Dispositivo from "../../js/db/Dispositivo.js";
import Utils from "../../js/Utils.js";
import rFiles from "../../components/rFiles.vue";
import Documento from "../../js/db/Documento.js";
export default {
  components: {
    rDynamicList,
    rFiles,
    rForm
  },
  data: function() {
    return {
      Dispositivo,
      Utils,
      Documento,
    };
  },
  computed: {},
  methods: {
    showCheckInList: function(item) {
      return false; 
      // return item.estado && item.estado.estado && item.estado.estado == "ABIERTA"; 
    },
    activar: function(itemId) {
      var self = this;
      var app = self.$f7;
      app.dialog.confirm(
        "¿Seguro que desea activar el dispositivo seleccionado?",
        "Activar dispositivo",
        function() {
          app.preloader.show();
          Dispositivo.activar(itemId)
            .then(function(res) {
              app.preloader.hide();
              app.toast
                .create({
                  text: 'Dispositivo activado',
                  position: "center",
                  closeTimeout: 2000
                })
                .open();
              self.$refs.rlist.reload();
            })
            .catch(function(error) {
              app.preloader.hide();
              app.dialog.alert(error.error.message);
            });
        }
      );
    },
    desactivar: function(itemId) {
      var self = this;
      var app = self.$f7;
      app.dialog.confirm(
        "¿Seguro que desea desactivar el dispositivo seleccionado?",
        "Desactivar dispositivo",
        function() {
          app.preloader.show();
          Dispositivo.desactivar(itemId)
            .then(function(res) {
              app.preloader.hide();
              app.toast
                .create({
                  text: 'Dispositivo desactivado',
                  position: "center",
                  closeTimeout: 2000
                })
                .open();
              self.$refs.rlist.reload();
            })
            .catch(function(error) {
              app.preloader.hide();
              app.dialog.alert(error.error.message);
            });
        }
      );
    }
  },
  mounted: function() {
    var self = this;
    var app = self.$f7;
    /*app.dataTable.create({
      el: self.$refs.datatable
    });*/
  }
};
</script>