<template>
  <f7-list-input
    :value="getValue()"
    :type="type"
    autocomplete="off"
    @input="updateIfEmpty"
    v-bind="{...$attrs,...$props}"
    v-on="$listeners"
    :error-message-force="!!form.errors[name]"
    :error-message="form.errors[name]"
    :readonly="overrideReadonly ? readonly : readonly || form.readonly"
    :disabled="overrideReadonly ? readonly : readonly || form.readonly"
    :class="{readonly:overrideReadonly ? readonly : readonly || form.readonly, disabled:overrideReadonly ? readonly : readonly || form.readonly}"
    v-tooltip="viewTooltip && form.viewTooltip ? (label ? label + ':<br>' : '') + (getValue() ? getValue() : '') : ''"
  ></f7-list-input>
</template>

<script>

import DatabaseModel from '../js/db/DatabaseModel'

export default {
  data() {
    return {
      _jqxhr: null,
      isLoading: false,
      items: [],
      autocomplete: null,
      DatabaseModel,
      dbAdapterData: null
    };
  },
  computed: {},
  methods: {
    onSelectAll: function (e) {
      const self = this;
      let labelItems = self.autocomplete.$el[0].firstElementChild.getElementsByClassName("item-checkbox");
      for (let index = 0; index < labelItems.length; index++) {
        const element = labelItems[index];
        var inputsCheckbox = element.getElementsByTagName("input");
        for (let i2 = 0; i2 < inputsCheckbox.length; i2++) {
          const checkElem = inputsCheckbox[i2];
          if (checkElem.type ==  "checkbox") {
            checkElem.checked = true;
          }
        }
      }
      self.setValue(self.items);
    },
    onDeSelectAll: function (e) {
      const self = this;
      var labelItems = self.autocomplete.$el[0].firstElementChild.getElementsByClassName("item-checkbox");
      for (let index = 0; index < labelItems.length; index++) {
        const element = labelItems[index];
        var inputsCheckbox = element.getElementsByTagName("input");
        for (let i2 = 0; i2 < inputsCheckbox.length; i2++) {
          const checkElem = inputsCheckbox[i2];
          if (checkElem.type ==  "checkbox") {
            checkElem.checked = false;
          }
        }
      }
      self.setValue([]);
    },
    renderSearchbar: function () {
      const self = this;
      return `
      <form class="searchbar">
        <div class="searchbar-inner">
          <div class="searchbar-input-wrap">
            <input type="search" placeholder="${self.$t('searchBarBuscar')}" class="">
            <i class="searchbar-icon"></i>
            <span class="input-clear-button"></span>
          </div>
        </div>
      </form>
      <div class="selectAllSearchBar">
        <a data-v-459f1c3b="" href="#" class="link icon-only select-all-btn has-tooltip" small="">
          <i class="icon f7-icons">checkmark_square</i>
        </a>
        <a data-v-459f1c3b="" href="#" class="link icon-only deselect-all-btn has-tooltip" small="">
          <i class="icon f7-icons">multiply_square</i>
        </a>
      </div>
      `;
    },
    updateIfEmpty: function(e) {
      if (!e.target.value && e.inputType == "deleteContentBackward") {
        this.setValue({});
        this.form.$emit("change", this.name, {});
      }
    },
    getRemoteData: function(filterInput) {
      const self = this;
      var filters = filterInput || self.filter;
      var params = Object.assign({}, filters);
      var shownFields = new Set(self.shownFields);
      shownFields.add('id');
      if(self.labelProp) shownFields.add(self.labelProp);
      if(self.prefixProp) shownFields.add(self.prefixProp);
      if(self.idProp) shownFields.add(self.idProp);
      if(self.searchProp) shownFields.add(self.searchProp);
      return {params: { filter: params }, shownFields: shownFields};
    },
    reload() {
      var self = this;
      if (self.dbAdapterData) {
        const {params, shownFields} = self.getRemoteData();
        self.dbAdapterData
          .getCombo(params, [...shownFields])
          .then(function(res) {
            self.items = res.list;
            self.$emit("loaded", res.list);
          })
          .catch(function(e) {
            console.log(e);
          });
      } else {
        self.items = self.dbItems;
        self.$emit("loaded", self.dbItems);
      }
    },
    getValue: function() {
      var self = this;
      var namespaces = self.name.split(".");
      var context = self.form.formData;
      for (var i = 0; i < namespaces.length; i++) {
        if (typeof context == "undefined") {
          let n = namespaces[i];
          if (0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n)) {
            //Número entero positivo
            context = [];
          } else {
            context = {};
          }
        }
        if (typeof context == "undefined" || context == null) context = {};
        context = context[namespaces[i]];
      }
      if (typeof context == "undefined" || context == null) context = [];
      var selected = self.items.filter(function(i){
        return self.multiple ? (context.filter(c=> self.nameId ? c[self.nameId] == i[self.idProp] : c == i[self.idProp]).length > 0) : (self.nameId ? context[self.nameId] == i[self.idProp] : context == i[self.idProp]);
      });
      /*if (selected && selected.length > 0) {
        return selected[0][self.labelProp];
      }*/
      var joinStr = ", ";
      if (self.lineBreak) {
        joinStr = ",\n";
      }
      if (selected && selected.length > 0) {
        if (self.autocomplete) self.autocomplete.value = selected;
        return selected.map(el => self.prefixProp && el[self.prefixProp] ? el[self.prefixProp] + ' - ' + el[self.labelProp] : el[self.labelProp]).join(joinStr);
      }

      if (self.autocomplete) self.autocomplete.value = context;
      return self.multiple ? context.map(el => self.prefixProp && el[self.prefixProp] ? el[self.prefixProp] + ' - ' + el[self.labelProp] : el[self.labelProp]).join(joinStr): context;

    },
    setValue: function(val) {
      if (typeof val == 'undefined' || val == null) {
        val = [];
      }
      var self = this;
      var namespaces = self.name.split(".");
      var context = self.form.formData;
      for (var i = 0; i < namespaces.length; i++) {
        if (i == namespaces.length - 1) {
          self.$set(context, namespaces[i], val);
          if (self.nameId || self.idProp) {
            var namespace = namespaces[i];
            var value = val;
            if (self.nameId) {
              namespace = self.nameId;
            }
            if (self.idProp) {
              value = val.map(el => el[self.idProp]);
            }
            self.$set(context, namespace, self.multiple ? value: value[0]);
          }
          self.$emit("change", val);
          self.form.$emit("change", namespace, val);
        } else if (typeof context[namespaces[i]] == "undefined") {
          let n = namespaces[i + 1];
          if (0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n)) {
            //Número entero positivo
            self.$set(context, namespaces[i], []);
          } else {
            self.$set(context, namespaces[i], {});
          }
        }
        context = context[namespaces[i]];
      }
    }
  },
  props: {
    lineBreak: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "text"
    },
    dbAdapter: {
      type: Object
    },
    name: {
      required: true,
      type: String
    },
    nameId: {
      type: String
    },
    form: {
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    labelProp: {
      type: String,
      default: "name"
    },
    prefixProp: {
      type: String
    },
    idProp: {
      type: String,
      default: ""
    },
    valueProperty: {
      type: String,
      default: ""
    },
    minLength: {
      type: Number,
      default: 0
    },
    searchProp: {
      type: String,
      default: function() {
        return this.labelProp;
      }
    },
    filter: {
      type: Object,
      default: function() {
        return {};
      }
    },
    shownFields: {
      type: Array,
      default: function (){
        return [];
      }
    },
    source: {
      type: Function,
      default: function(self) {
        return function(query, render) {
          var results = [];
          if (query.length >= self.minLength) {
            for (var i = 0; i < self.items.length; i++) {
              var txtValue = self.items[i][self.searchProp];
              if (self.prefixProp && self.items[i][self.prefixProp]) {
                txtValue = self.items[i][self.prefixProp] + ' - ' + self.items[i][self.searchProp];
              }
              if (
                (!txtValue && (!query || query == "")) ||
                (txtValue && txtValue.toLowerCase().indexOf(query.toLowerCase()) >= 0)
              ) {
                self.items[i]['txtValue'] = txtValue;
                results.push(self.items[i]);
              }
            }
          }
          render(results);
        };
      }
    },
    onchange: {
      type: Function,
      default: function(self) {
        return function(value) {
          self.setValue(value);
          // self.$emit("change", value[0]);
          // self.form.$emit("change", self.name, value[0]);
        };
      }
    },
    onopened: {
      type: Function,
      default: function(self) {
        return function(value) {
          self.autocomplete.$el[0].getElementsByClassName("select-all-btn")[0].addEventListener("click", function(event) {self.onSelectAll(event)});
          self.autocomplete.$el[0].getElementsByClassName("deselect-all-btn")[0].addEventListener("click", function(event) {self.onDeSelectAll(event)});
        };
      }
    },
    autocompleteProps: {
      type: Object,
      default: function() {
        var $ = this.$$;
        var $el = $(this.$el);
        var self = this;
        return {};
      }
    },
    overrideReadonly: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: true
    },
    viewTooltip: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: ""
    },
    dbItems: {
      type: Array,
      default: function() {
        return [];
      }
    },
    model: {
      type: String
    }
  },
  created: function() {
    var self = this;
  },
  mounted() {
    var $ = this.$$;
    var $el = $(this.$el);
    var self = this;
    if (self.model) {
      self.DatabaseModel.model = self.model;
      self.dbAdapterData = self.DatabaseModel;
    } else {
      self.dbAdapterData = self.dbAdapter;
    }
    self.autocomplete = self.$f7.autocomplete.create({
      ...{
        openIn: "popup",
        popupCloseLinkText: self.$t('cerrar'),
        multiple: self.multiple,
        routableModals: false,
        openerEl: $el,
        inputEl: $el.find("input"),
        preloader: true,
        highlightMatches: true,
        typeahead: true,
        requestSourceOnOpen: true,
        valueProperty: self.valueProperty || self.idProp || 'id',
        textProperty: 'txtValue',
        source: self.source(self),
        closeOnSelect: !self.multiple,
        autoFocus: true,
        searchbarPlaceholder: self.$t('searchBarBuscar'),
        notFoundText: self.$t('searchBarSinResultados'),
        renderSearchbar: self.multiple ? self.renderSearchbar : false,
        on: {
          change: self.onchange(self),
          opened: self.onopened(self)
        }
      },
      ...self.autocompleteProps
    });
    self.reload();
  }
};
</script>
<style>
.readonly input,
.readonly select {
  pointer-events: none;
}
.autocomplete-dropdown-inner {
  max-height: 170px;
}
.autocomplete-page .selectAllSearchBar input  {
  /* width: 25%; */
}
.autocomplete-page .selectAllSearchBar {
  display: flex;
}
</style>