<template>
    <div class="item-content item-input item-label-up">
      <div class="item-inner">
        <div class="item-title item-label item-floating-label">{{label}}</div>
        <div class="item-input-wrap">
          <vue-editor v-if="!disabled && !(overrideReadonly ? readonly : readonly || form.readonly)"
            v-on="$listeners"
            v-model="innerText"
            @text-change="updateValue()"
            :editor-toolbar="customToolbar"
            :placeholder="placeholder"
          ></vue-editor>
          <div v-else v-html="innerText"></div>
        </div>
      </div>
    </div>
</template>
<style>
</style>
<script>
import { VueEditor } from "vue2-editor";

export default {
  components: {
    VueEditor
  },
  data() {
    return {
      self: this,
      innerText: '',
    };
  },
  computed: {
    text: function(){
      var self = this;
      return self.getValue();
    },
    customToolbar: function(){
      // return [
      //   [{ 'font': [] }],
      //   [{ 'header': [false, 1, 2, 3, 4, 5, 6, ] }],
      //   [{ 'size': ['small', false, 'large', 'huge'] }],
      //   ['bold', 'italic', 'underline', 'strike'],
      //   [{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
      //   [{ 'header': 1 }, { 'header': 2 }],
      //   ['blockquote', 'code-block'],
      //   [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
      //   [{ 'script': 'sub'}, { 'script': 'super' }],
      //   [{ 'indent': '-1'}, { 'indent': '+1' }],
      //   [{ 'color': [] }, { 'background': [] }],
      //   ['link', 'image', 'video', 'formula'],
      //   [{ 'direction': 'rtl' }],
      //   ['clean'],
      // ];
      var self = this;
      var toolbar = [
        // [{ 'font': [] }],
        [{ 'header': [false, 1, 2, 3, 4, 5, 6, ] }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
        ['blockquote', 'code-block'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],
        [{ 'indent': '-1'}, { 'indent': '+1' }],
        [{ 'color': [] }, { 'background': [] }],
        ['link', 'image'],
        [{ 'direction': 'rtl' }],
        ['clean'],
      ];
      if(self.showVideoButton){
        toolbar[10].push('video');
      }
      return toolbar;
    }
  },
  watch: {
    text: function(){
      var self = this;
      if(self.innerText != self.text){
        self.innerText = self.text;
      }
    }
  },
  methods: {
    getValue: function() {
      var self = this;
      var namespaces = self.name.split(".");
      var context = self.form.formData;
      for (var i = 0; i < namespaces.length; i++) {
        if (typeof context == "undefined") {
          let n = namespaces[i];
          if (0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n)) {
            //Número entero positivo
            context = [];
          } else {
            context = {};
          }
        }
        if (typeof context == "undefined" || context == null) context = {};
        context = context[namespaces[i]];
      }
      return context;
    },
    updateValue: function() {
      var self = this;
      var val = self.innerText;
      self.setValue(val);
    },
    setValue: function(val) {
      if (val != undefined && val === '') {
        val = undefined;
      }
      var self = this;
      var namespaces = self.name.split(".");
      var context = self.form.formData;
      for (var i = 0; i < namespaces.length; i++) {
        if (i == namespaces.length - 1) {
          self.$set(context, namespaces[i], val);
          self.$emit("change", val, self);
        } else if (context[namespaces[i]] == null) {
          self.$set(context, namespaces[i], {});
          self.$emit("change", {}, self);
        } else if (typeof context[namespaces[i]] == "undefined") {
          let n = namespaces[i + 1];
          if (0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n)) {
            //Número entero positivo
            self.$set(context, namespaces[i], []);
            self.$emit("change", [], self);
          } else {
            self.$set(context, namespaces[i], {});
            self.$emit("change", {}, self);
          }
        }
        context = context[namespaces[i]];
      }
    }
  },
  props: {
    name: {
      required: true,
      type: String
    },
    form: {
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    overrideReadonly: {
      type: Boolean,
      default: false
    },
    viewTooltip: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: ""
    },
    showVideoButton: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean
    }
  },
  created() {
    var self = this;
    self.$emit("created", self);
  },
  mounted() {
    var $ = this.$$;
    var $el = $(this.$el);
    var self = this;
    self.innerText = self.getValue();
    self.$emit("mounted", self);
  }
};
</script>