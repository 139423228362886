<template>
  <base-chart v-bind="$props" v-on="$listeners" >
    <h3 v-if="title && title!=''" slot="title">{{title}}</h3>
    <v-chart slot="chart" :forceFit="true" :height="height" :data="data">
      <v-tooltip />
      <v-axis /> 
      <v-legend />
      <v-bar :position="nombre + '*' + valor" color="key" :adjust="adjust"/>
    </v-chart>
  </base-chart>
</template>

<script>
  import baseChart from "./baseChart.vue";
  import DataSet from '@antv/data-set';

  export default {
    extends: baseChart,
    components: {
      baseChart,
    },
    data() {
      return {
        adjust: [{
          type: 'dodge',
          marginRatio: 1 / 32,
        }],
      };
    },
    props: {

    },
    methods: {
      getData(data) {
        const self = this;
        const dv = new DataSet.View().source(data);
        dv.transform({
          type: 'fold',
          fields: self.fields,
          key: 'key',
          value: self.valor,
        });
        return dv.rows;
      }
    }
  };
</script>