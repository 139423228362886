'use strict';

var numberFormat = function(input, decimals, decimalSeparator = '.', thousandsSeparator = ',') {
    if (input == '' || isNaN(input)) {
        return input;
    } else {
        input = parseFloat(input);
        var negative = (input < 0);
        var fixed = input;
        if (negative) {
            fixed = fixed * -1;
        }
        fixed = Math.floor(fixed);
        var value = (fixed == 0) ? "0" : "";
        while (fixed >= 1) {
            var thousands = "" + fixed % 1000;
            fixed = Math.floor(fixed / 1000);
            if (fixed >= 1) {
                thousands = thousands.padStart(3, "0");
            }
            value = (!value) ? thousands : thousands + thousandsSeparator + value;
        }
        if (decimals && decimals != 0) {
            var decimal = ("" + input.toFixed(decimals)).replace(/.*\.(\d+)/, "$1");
            value = value + decimalSeparator + decimal;
        }
        if (negative) {
            value = "-" + value;
        }

        return value;
    }
};

var main = {
    install: function(Vue) {
        Vue.filter('numberFormat', numberFormat);
    }
};

export default main;