import Gamas from './gamas.vue';
import GamaForm from './gama-form.vue';
import RoutesUtils from '../../js/RoutesUtils.js';

var routes = [{
        path: '/gamas/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('gamas', ['ADMIN', 'CHECK_MAN'], Gamas),
        keepAlive: true
    },
    {
        path: '/gamas/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('gamas/new', ['ADMIN', 'CHECK_MAN'], GamaForm)
    },
    {
        path: '/gamas/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('gamas/edit', ['ADMIN', 'CHECK_MAN'], GamaForm)
    },
    {
        path: '/gamas/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('gamas/view', ['ADMIN', 'CHECK_MAN'], GamaForm, { editable: false })
    },

];
export default routes;