<template>
  <r-page :page-content="true">
    <f7-navbar title="Página no encontrada" :back-link="$t('atras')"></f7-navbar>
    <f7-block strong>
      <p>Error: Página no encontrada</p>
    </f7-block>
  </r-page>
</template>
<script>
export default {};
</script>