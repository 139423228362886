import RoutesUtils from '../../js/RoutesUtils.js';
import Properties from './properties.vue';
import PropertiyForm from './property-form.vue';

var routes = [{
        path: '/properties/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('properties', ['ADMIN', 'CHECK_MAN'], Properties),
        keepAlive: true
    },
    {
        path: '/properties/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('properties/new', ['ADMIN', 'CHECK_MAN'], PropertiyForm)
    },
    {
        path: '/properties/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('properties/edit', ['ADMIN', 'CHECK_MAN'], PropertiyForm)
    },
    {
        path: '/properties/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('properties/view', ['ADMIN', 'CHECK_MAN'], PropertiyForm, { editable: false })
    },
];
export default routes;