<template>
  <r-page :page-content="true">
    <f7-navbar :back-link="$t('atras')">
      <!-- <f7-nav-title v-if="id && !readonly" sliding>{{$t('inspeccionescampa.editar')}}</f7-nav-title> -->
      <f7-nav-title v-if="id" sliding>{{$t('inspeccionescampa.detalle.title') + ' ' +  ($refs.rform && $refs.rform.formData && $refs.rform.formData.madre?$refs.rform.formData.madre.etiqueta:'')}}</f7-nav-title>
      <f7-nav-title v-else sliding>{{$t('inspeccionescampa.nuevo')}}</f7-nav-title>
      <f7-nav-right>
        <f7-button
          v-if="!readonly"
          fill
          icon-f7="floppy_disk"
          text="Guardar"
          class="display-flex"
          @click="$refs.rform.saveItem()"
        ></f7-button>
        <template slot="right" v-else>
          <f7-button v-roles="{route: $f7route.url, roles: ['ADMIN']}" @click="readonly=false">{{$t('common.editar')}}</f7-button>
          <f7-button v-roles="{route: $f7route.url, roles: ['ADMIN']}" @click="deleteItem">{{$t('common.eliminar')}}</f7-button>
        </template>
      </f7-nav-right>
    </f7-navbar>
    <r-form
      ref="rform"
      v-slot="{rform}"
      :itemId="id"
      :beforeSave="beforeSave"
      @save="onSave"
      @loadItem="loadItem"
      :readonly="readonly"
      :dbAdapter="InspeccionCampa"
      :defaultData="{valores: [], tipo: 'SIMPLE'}"
    >
      <f7-card class="padding-vertical-half padding-right">
        <f7-card-content>
          <f7-row v-if="!rform.formData.enVehiculo" no-gap class="list list-form no-hairlines"  style="z-index:9;">
            <f7-card outline class="col-100" style="background-color: #EEEEEE;">
              <f7-row no-gap class="list list-form no-hairlines">
                <r-input
                  class="col-10"
                  floating-label
                  :label="$t('inspeccionescampa.detalle.fecha')"
                  :form="rform"
                  type="datepicker"
                  :name="'fecha'"
                  :calendar-params="{
                      closeOnSelect: true,
                      routableModals: false,
                      dateFormat: {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      },
                    }"
                ></r-input>
                <r-input
                  class="col-10" style="z-index:9;"
                  floating-label
                  :label="$t('inspeccionescampa.detalle.turno')" 
                  :form="rform"
                  type="select"
                  :name="'turno'"
                >
                  <option selected value=""></option>
                  <option  value="MAÑANA">MAÑANA</option>
                  <option  value="TARDE">TARDE</option>
                  <option  value="NOCHE">NOCHE</option>
                </r-input>
              </f7-row>
              <f7-row>
                <f7-card outline class="col-100" style="background-color: #F0F4C3;">
                  <!-- poner botón de añadir línea de detalle de revisión de coche -->
                  <div v-if="!rform.readonly || (rform.formData && rform.formData.madre && rform.formData.madre.hijos && rform.formData.madre.hijos.length > 0)">
                    <div style="display: inline-flex;padding:10px 50px 5px 10px;">
                      <b>{{$t('inspeccionescampa.detalle.gamas')}}</b>
                    </div>
                    <f7-link
                      v-if="!rform.readonly"
                      slot="right"
                      @click="nuevaGama()"
                      icon-f7="plus_app_fill"
                    ></f7-link>
                  </div>
                  <div v-if="rform && rform.formData && rform.formData.gamas">
                    <f7-row 
                      no-gap 
                      class="list list-form no-hairlines"
                      v-for="(gama,index) in rform.formData.gamas" 
                      :key="gama.numero + '(' +index+')'" 
                    >
                      <f7-card :class="{'col-100 elevation-3 padding-vertical-half padding-right':true}">
                        <f7-row no-gap class="list list-form no-hairlines">
                          <r-autocomplete
                            class="col-15 medium-15 small-100"
                            :dbAdapter="Gamas"
                            floating-label 
                            :label="$t('inspeccionescampa.detalle.numeroGama')"
                            :form="rform"
                            :name="'gamas.'+index"
                            :shownFields="['denominacion','referencias']"
                            :idProp="null"
                            labelProp="numero"
                          ></r-autocomplete> 
                          <r-input
                            class="col-40 medium-40 small-100"
                            floating-label
                            :label="$t('inspeccionescampa.detalle.denominacion')"
                            :form="rform"
                            type="text"
                            :name="'gamas.'+index+'.denominacion'"
                            :readonly="true"
                            :overrideReadonly="true"
                          ></r-input>
                          <r-input
                            class="col-40 medium-40 small-100"
                            floating-label
                            :label="$t('gamas.referencias')"
                            :form="rform"
                            type="text"
                            :name="'gamas.'+index+'.referencias'"
                            :readonly="true"
                            :overrideReadonly="true"
                          ></r-input>
                          <div style="position: absolute; top: 0px; right: 0px;">
                            <f7-link v-if="!readonly" v-tooltip="$t('common.eliminar')" icon-f7="trash" color="red" @click="deleteLinea(rform.formData.gamas,index);"></f7-link>
                          </div>
                        </f7-row>
                      </f7-card>
                    </f7-row>
                  </div>
                </f7-card>
              </f7-row>
            </f7-card>
          </f7-row>
          <f7-row no-gap class="list list-form no-hairlines" style="z-index:8;">
            <f7-card outline class="col-100" style="background-color: #F0F4F3;">
              <!-- poner botón de añadir línea de detalle de revisión de coche -->
              <div v-if="!rform.readonly">
                <div style="display: inline-flex;padding:10px 50px 5px 10px;">
                  <b>{{$t('inspeccionescampa.detalle.coche')}}</b>
                </div>
                <f7-link
                  v-if="!rform.readonly"
                  slot="right"
                  @click="nuevoCoche()"
                  icon-f7="plus_app_fill"
                ></f7-link>
              </div>
              <div v-if="rform && rform.formData && rform.formData.inspeccionesCoches">
                <f7-row 
                  no-gap 
                  class="list list-form no-hairlines"
                  v-for="(coche,index) in rform.formData.inspeccionesCoches" 
                  :key="'inspeccionesCoches-'+index" 
                >
                  <f7-card :class="{'col-100 elevation-3 padding-vertical-half padding-right':true,'bg-color-yellow':coche.numeroChasis!='','bg-color-gray':coche.numeroChasis==''}">
                    <f7-row no-gap class="list list-form no-hairlines" style="z-index:9;">
                      <r-autocomplete
                        class="col-10 medium-10 small-100"
                        :dbAdapter="LineasReactividad"
                        floating-label 
                        :label="$t('inspeccionescampa.detalle.numero_chasis')"
                        :form="rform"
                        :name="'inspeccionesCoches.'+index"
                        :shownFields="['numeroChasis','numeroOrden','pji','calle', 'motor']"
                        :idProp="null"
                        labelProp="numeroChasis"
                        :showNavigationLink="false"
                        :minLength="10"
                        :async="true"
                      ></r-autocomplete> 
                      <r-input
                        class="col-10 medium-10 small-100"
                        floating-label
                        :label="$t('inspeccionescampa.detalle.pji')"
                        :form="rform"
                        type="text"
                        :name="'inspeccionesCoches.'+index+'.pji'"
                        :readonly="true"
                        :overrideReadonly="true"
                      ></r-input>
                      <r-input
                        class="col-10 medium-10 small-100"
                        floating-label
                        :label="$t('inspeccionescampa.detalle.numeroOrden')"
                        :form="rform"
                        type="number"
                        :name="'inspeccionesCoches.'+index+'.numeroOrden'"
                        :readonly="true"
                        :overrideReadonly="true"
                      ></r-input>
                      <!-- <r-input
                        class="col-10 medium-10 small-100"
                        floating-label
                        :label="$t('inspeccionescampa.detalle.referencia')"
                        :form="rform"
                        type="text"
                        :name="'inspeccionesCoches.'+index+'.referencia'"
                      ></r-input> -->
                      <r-input
                        class="col-10 medium-10 small-100"
                        floating-label
                        :label="$t('inspeccionescampa.detalle.calle')"
                        :form="rform"
                        type="text"
                        :name="'inspeccionesCoches.'+index+'.calle'"
                        :readonly="true"
                        :overrideReadonly="true"
                      ></r-input>
                      <r-input
                        class="col-10 medium-10 small-100"
                        floating-label
                        :label="$t('inspeccionescampa.detalle.motor')"
                        :form="rform"
                        type="text"
                        :name="'inspeccionesCoches.'+index+'.motor'"
                        :readonly="true"
                        :overrideReadonly="true"
                      ></r-input>
                      <r-input
                        class="col-10 medium-10 small-100"
                        floating-label
                        :label="$t('inspeccionescampa.detalle.resultado')"
                        :form="rform"
                        type="select"
                        :name="'inspeccionesCoches.'+index+'.resultado'"
                      >
                        <option selected value=""></option>
                        <option  value="OK">OK</option>
                        <option  value="NOK">NO OK</option>
                        <!-- <option  value="AUSENTE">AUSENTE</option>
                        <option  value="FUNDA">CON FUNDA</option>
                        <option  value="OTRO">OTRO</option> -->
                      </r-input>
                      <div style="position: absolute; top: 0px; right: 0px;">
                        <f7-link v-if="!readonly" v-tooltip="$t('common.eliminar')" icon-f7="trash" color="red" @click="deleteLinea(rform.formData.inspeccionesCoches,index);"></f7-link>
                      </div>
                    </f7-row>
                    <f7-row no-gap class="list list-form no-hairlines">
                      <r-autocomplete-m2m
                        class="col-50 medium-50 small-100"
                        floating-label
                        outline
                        :label="$t('inspeccionescampa.detalle.defectos')"
                        :dbItems="getDefectosItems(coche)"
                        :form="rform"
                        :name="'inspeccionesCoches.'+index+'.defectos'"
                        type="textarea"
                        :lineBreak="true"
                        :idProp="null"
                        :valueProperty="'numeroGama'"
                        labelProp="denominacion"
                      ></r-autocomplete-m2m>
                      <r-input
                        class="col-50 medium-50 small-100"
                        floating-label
                        outline
                        :label="$t('inspeccionescampa.detalle.observaciones')"
                        :form="rform"
                        type="textarea"
                        :name="'inspeccionesCoches.'+index+'.observaciones'"
                      ></r-input>
                    </f7-row>
                  </f7-card>
                  
                </f7-row>
              </div>
            </f7-card>
          </f7-row>

        </f7-card-content>
      </f7-card>

      <pre style="display:none;">{{rform.formData}}</pre>
    </r-form>
  </r-page>
</template>
<style scoped>
  .label-group {
    margin-top: 12px;
    margin-left: 20px;
    font-weight: bold;
  }
  .label-group-inspeccion {
    margin-top: 30px;
  }
  label.btn {
    padding: 1px;
    cursor: pointer;
    border: 1px solid;
  }
  .valor-row {
    position: relative;
  }
  .valor-codigo {
    padding-left: 3em;
  }
  .valor-controles {
    position:absolute;
    left: 0;
    top: 0;
    z-index: 2;
  }
  .valor-delete {
    position:absolute;
    left: 0;
    top: 7px;
  }
  .valor-reordenar {
    margin-top:2px;
    position:absolute;
    left: 12px;
    top: 11px;
  }

  .bg-color-purple {
    background-color: #edbdf7 !important;
  }
  .bg-color-orange {
    background-color: #FFE0B2 !important;
  }
  .bg-color-grey {
    background-color: #EDEEF7 !important;
  }

  .deep-purple {
    background-color: #5E35B1 !important;
  }

  .darken-1 {
    color: white !important;
  }

  .popup-alertas {
    overflow: auto;
  }

</style>
<style>
  #tab-expediente .list .bordeAzul .item-input-outline input,
  #tab-expediente .list .bordeAzul .item-input-outline textarea {
    color: blue;
    padding: 5px;
  }
  #tab-expediente .bordeAzul .item-input-outline .item-input-wrap:after {
    border-color: lightblue;

  }
  #tab-cliente .list .bordeAzul .item-input-outline input {
    color: blue;
  }
  #tab-cliente .bordeAzul .item-input-outline .item-input-wrap:after {
    border-color: lightblue;
  }

</style>
<script>
import rForm from "../../components/rForm.vue";
import rInput from "../../components/rInput.vue";
import Documento from "../../js/db/Documento.js";
import Elemento from "../../js/db/Elemento.js";
import Gamas from "../../js/db/Gamas.js";
import LineasReactividad from "../../js/db/LineasReactividad.js";
import rAutocomplete from "../../components/rAutocomplete.vue";
import rAutocompleteM2m from "../../components/rAutocompleteM2m.vue";
import rTimepicker from "../../components/rTimepicker.vue";
import rRichTextInput from "../../components/rRichTextInput.vue";
import { Container, Draggable } from "vue-smooth-dnd";
import InspeccionCampa from "../../js/db/InspeccionCampa.js";
import Flash from '../../js/FlashUtils';

export default {
  components: {
    rForm,
    rInput,
    rTimepicker,
    rRichTextInput,
    rAutocomplete,
    rAutocompleteM2m,
    Container,
    Draggable
  },
  data: function() {
    var self = this;
    return {
      InspeccionCampa,
      Documento,
      Elemento,
      Gamas,
      LineasReactividad,
      popupAlertasOpened: false,
      readonly: !this.editable
    };
  },
  props: {
    id: {
      type: String
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  computed: {},
  beforeDestroy() {},
  methods: {
    getDefectosItems(coche) {
      const self = this;
      let defs = self.$refs.rform.formData.gamas.map(gama => {
        return {
          numeroGama: gama.numero,
          denominacion: gama.numero + (gama.denominacion ? (' - ' + gama.denominacion) : '')
        }
      });
      defs.push({numeroGama: 'NOESTA', denominacion: 'NO ESTA'});
      defs.push({numeroGama: 'CONFUNDA', denominacion: 'CON FUNDA'});
      defs.push({numeroGama: 'NOARRANCA', denominacion: 'NO ARRANCA'});
      defs.push({numeroGama: 'OTROS', denominacion: 'OTROS'});
      return defs;
    },
    deleteElemento: function(lista, index) {
      const self = this;
      const app = self.$f7;
      let elemento = lista[index];
      self.$f7.dialog.confirm(
        self.$t("rlist.pregunta_eliminar_registro"),
        "Eliminar",
        function() {
          Elemento.delete({itemId: elemento.id}).then(function(res) {
            if (res) {
              lista.splice(index,1);
              app.toast
                .create({
                  icon: '<i class="f7-icons">checkmark</i>',
                  text: self.$t("rlist.registro_eliminado"),
                  position: "center",
                  destroyOnClose: true,
                  closeTimeout: 2000
                })
                .open();
              Flash.set("refreshList", true);
            }
          }).catch(function(error){
            if(error.message){
              if(error.message){
              app.dialog.alert(error.message);
            } else {
              app.dialog.alert(error);
            }
            } else {
              app.dialog.alert(error);
            }
          });
        }
      );
    },
    deleteLinea: function(lista, index) {
      var self = this;
      var app = self.$f7;
      self.$f7.dialog.confirm(
        self.$t("rlist.pregunta_eliminar_registro"),
        "Eliminar",
        function() {
          lista.splice(index,1);
        }
      );
    },
    nuevoCoche: function() {
      var self = this;
      if(!self.$refs.rform.formData.inspeccionesCoches) {
        self.$set(self.$refs.rform.formData, 'inspeccionesCoches', []);
      }
      self.$refs.rform.formData.inspeccionesCoches.push({
        numeroChasis: '',
        pji: '',
        numeroOrden: null,
        referencia: '',
        calle: '',
        motor: '',
        defectos: [],
        resultado: '',
        observaciones: ''
      });
    },
    nuevaGama: function() {
      var self = this;
      if(!self.$refs.rform.formData.gamas) {
        self.$set(self.$refs.rform.formData, 'gamas', []);
      }
      self.$refs.rform.formData.gamas.push({
        numero: '', 
        denominacion: '', 
        referencias: ''
      });
    },
    defectosChange: function(evento, index, indexDef) {
      var self = this;
      if(evento && index) {
        self.$refs.rform.formData.inspeccionesCoches[index].defectos;
      }
    },
    onChangeEstado: function(abierto,componente) {
      var self = this;
      // en el cambio manual de estado vamos a mirar si tenemos que poner fecha de cierre cuando sea necesario
      if(!abierto && !self.$refs.rform.formData.fechaCierre) {
        self.$set(self.$refs.rform.formData, 'fechaCierre', new Date());
      }
    },
    
    reabrir: function() {
      var self = this;
      // Ponemos la UM en estado abierta
      self.$refs.rform.formData.abierta = true;
    },
    getTextoBadgeCategoria: function(categoria) {
      if (!categoria) {
        return "Sin Categoría";
      }

      if (categoria == '1') {
        return "Categoría 1";
      }
      else if (categoria == '2') {
        return "Categoría 2";
      }
       else if (categoria == '3') {
        return "Categoría 3";
      }
       else if (categoria == '4') {
        return "Categoría 4";
      } else {
        return "Sin definir";
      }
      
    },
    getColorBadgeCategoria: function(categoria) {
      if (!categoria) {
        return "gray";
      }

      if (categoria == '1') {
        return "lime";
      }
      else if (categoria == '2') {
        return "green";
      }
      else if (categoria == '3') {
        return "orange";
      }
      else if (categoria == '4') {
        return "brown";
      } else {
        return "gray";
      }
    },
    deleteItem: function() {
      var self = this;
      var app = self.$f7;
      app.dialog.confirm(
        self.$t("rlist.pregunta_eliminar_registro"),
        "Eliminar",
        function() {
          InspeccionCampa.delete({itemId: self.id}).then(function(res) {
            if (res) {
              app.toast
                .create({
                  icon: '<i class="f7-icons">checkmark</i>',
                  text: self.$t("rlist.registro_eliminado"),
                  position: "center",
                  destroyOnClose: true,
                  closeTimeout: 2000
                })
                .open();
              Flash.set("refreshList", true);
              self.$f7router.back();
              // app.views.main.router.navigate("/inspeccioncampa/?" + 'refreshList');
            }
          }).catch(function(error){
            if(error.message){
              app.dialog.alert(error.message);
            } else {
              app.dialog.alert(error);
            }
          });
        }
      );
    },
    onDropValor: function(arr, dragResult){
      var self = this;
      const { removedIndex, addedIndex, payload } = dragResult;
      if (removedIndex === null && addedIndex === null) return arr;
      if (!arr) arr = [];
      var result = arr;
      let itemToAdd = payload;
      if (removedIndex !== null) {
        itemToAdd = Object.assign({},  result.splice(removedIndex, 1)[0]);
      }
      if (addedIndex !== null) {
        result.splice(addedIndex, 0, Object.assign({}, itemToAdd));
      }
    },
    deleteValor: function(gama, indexValor){
      gama.valores.splice(indexValor,1);
    },
    beforeSave: function(data, resolve, reject) {
      if(data && data.valores && Array.isArray(data.valores)) {
        data.valores.forEach(valor => {
          if(!valor.nombre){
            valor.nombre = valor.valor;
          }
        });
      }
      resolve(data);
    },
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.$refs.rform.formData = res;
      self.readonly = true;
      app.toast
        .create({
          text: self.$t("inspeccionescampa.detalle.guardado"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
    },
    loadItem: function(res) {
      var self = this;
      if(!res.tipo){
        res.tipo = "SIMPLE";
      }
    }
  },
  created: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;
  }
};
</script>