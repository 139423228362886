import RoutesUtils from '../../js/RoutesUtils.js';
import InspeccionCampaForm from './inspeccionCampa-form.vue';
import InspeccionesCampa from './inspeccionesCampa.vue';

var routes = [{
        path: '/inspeccioncampa/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('inspeccioncampa', ['ADMIN', 'CHECK_MAN'], InspeccionesCampa),
        keepAlive: true
    },
    {
        path: '/inspeccioncampa/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('inspeccioncampa/new', ['ADMIN', 'CHECK_MAN'], InspeccionCampaForm)
    },
    {
        path: '/inspeccioncampa/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('inspeccioncampa/edit', ['ADMIN', 'CHECK_MAN'], InspeccionCampaForm)
    },
    {
        path: '/inspeccioncampa/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('inspeccioncampa/view', ['ADMIN', 'CHECK_MAN'], InspeccionCampaForm, { editable: false })
    },
];
export default routes;