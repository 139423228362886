import Tarifas from './tarifas.vue';
import TarifaForm from './tarifa-form.vue';
import RoutesUtils from '../../js/RoutesUtils.js';

var routes = [{
        path: '/tarifas/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('tarifas', ['ADMIN', 'CHECK_MAN'], Tarifas),
        keepAlive: true
    },
    {
        path: '/tarifas/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('tarifas/new', ['ADMIN', 'CHECK_MAN'], TarifaForm)
    },
    {
        path: '/tarifas/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('tarifas/edit', ['ADMIN', 'CHECK_MAN'], TarifaForm)
    },
    {
        path: '/tarifas/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('tarifas/view', ['ADMIN', 'CHECK_MAN'], TarifaForm, { editable: false })
    },
];
export default routes;