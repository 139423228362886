import Framework7 from 'framework7/framework7-lite.esm.bundle.js';
import CacheService from './Cache.js';
import Flash from '../FlashUtils';

const DatabaseModel = {
    server: process.env.VUE_APP_SERVER,
    server_zipado: process.env.VUE_APP_SERVER_ZIPADO,
    prefix: 'zipado/',
    updateMany: 'updatemany',
    client_id: process.env.VUE_APP_CLIENT_ID,
    client_secret: process.env.VUE_APP_CLIENT_SECRET,
    cache_expiration_time: 60000,
    request: Framework7.request,
    getList: function(params, forceReload) {
        var self = this;
        params = params || {}
        var paramsDefault = {
            currentPage: (params.page || 1) - 1,
            perPage: params.limit,
            sortBy: params.order,
            sortDesc: params.orderDir == 'DESC' ? true : false,
            complete: params.complete,
            projection: params.projection,
            operation: params.operation
        };
        var params_ser = Framework7.utils.serializeObject(paramsDefault);
        var paramsRequest = {
            url: self.server_zipado + self.prefix + self.model + '/search/?' + params_ser,
            method: 'POST',
            data: params.filter || {}
        };
        return self.sendRequest(paramsRequest, !forceReload);
    },
    getCombo: function(params, shownFields = []) {
        var self = this;
        var comboParams = Object.assign({}, params);

        if (shownFields.length) {
            comboParams.projection = shownFields.join(",");
        }
        return self.getList(comboParams);
    },
    get: function(params, forceReload) {
        var self = this;
        var forceReload = typeof forceReload == "undefined" || forceReload ? true : false;
        params = params || {};
        if (!params.itemId) reject({ error: 'itemId not defined' });
        var params = {
            url: self.server_zipado + self.prefix + self.model + '/' + params.itemId + '/',
            method: 'GET',
            data: {}
        };
        return self.sendRequest(params, !forceReload);
    },
    save: function(params) {
        var self = this;
        if (params.itemId) {
            return self.put(params);
        } else {
            return self.post(params);
        }
    },
    put: function(params) {
        var self = this;
        params = params || {};
        if (!params.itemId) reject({ error: 'itemId not defined' });
        var params = {
            url: self.server_zipado + self.prefix + self.model + '/' + params.itemId,
            method: 'PUT',
            data: params.data
        };
        return self.sendRequest(params, false).then(function(res) {
            CacheService.clearCache(self.model);
            return res;
        });
    },
    putAll: function(params) {
        var self = this;
        params = params || {};
        if (!params.itemIds) reject({ error: 'itemIds not defined' });
        var params = {
            url: self.server_zipado + self.prefix + self.model + '/' + self.updateMany,
            method: 'PUT',
            data: {
                ids: params.itemIds,
                dto: params.data
            }
        };
        return self.sendRequest(params, false).then(function(res) {
            CacheService.clearCache(self.model);
            return res;
        });
    },
    post: function(params) {
        var self = this;
        params = params || {};
        var params = {
            url: self.server_zipado + self.prefix + self.model + '/',
            method: 'POST',
            data: params.data
        };
        return self.sendRequest(params, false).then(function(res) {
            CacheService.clearCache(self.model);
            return res;
        });
    },
    delete: function(params) {
        var self = this;
        params = params || {};
        if (!params.itemId) reject({ error: 'itemId not defined' });
        var params = {
            url: self.server_zipado + self.prefix + self.model + '/' + params.itemId,
            method: 'DELETE',
            data: params.data
        };
        return self.sendRequest(params, false).then(function(res) {
            CacheService.clearCache(self.model);
            return res;
        });
    },
    define: function(params, forceReload) {
        var self = this;
        var forceReload = typeof forceReload == "undefined" || forceReload ? true : false;
        params = params || {};
        var params = {
            url: self.server_zipado + self.prefix + self.model + '/define',
            method: 'GET',
            data: {}
        };
        return self.sendRequest(params, forceReload);
    },
    getDefinePrepared: function(params, forceReload) {
        var self = this;
        return self.define(params, forceReload).then(res => {
            return self._prepareDefinition(res);
        });
    },
    getDefineCombo: function(params, forceReload) {
        var self = this;
        return self.getDefinePrepared(params, forceReload).then(valores => {
            return self._tratarObjeto(valores).map(valor => {
                return { name: valor.value, content: valor.name };
            });
        });
    },
    _prepareDefinition(property, parent) {
        var self = this;
        var preparedProp = {};
        var propertyData = {};
        if (parent) {
            propertyData = Object.assign({}, property);
            propertyData.property = (parent.property) ? `${parent.property}.${propertyData.property}` : propertyData.property;
            propertyData.name = (parent.name) ? `${parent.name} - ${propertyData.name}` : propertyData.name;
        }
        if (property.children && property.children.length) {
            property.children.forEach(child => {
                preparedProp[child.property] = self._prepareDefinition(child, propertyData);
            });
        } else {
            preparedProp[propertyData.property] = propertyData.name;
        }
        return preparedProp;
    },
    _tratarObjeto: function(objeto, pwd) {
        var self = this;
        var valores = [];
        for (const i in objeto) {
            const prop = objeto[i];
            if (typeof prop == "object") {
                const nuevos = self._tratarObjeto(prop);
                valores = valores.concat(self._tratarObjeto(prop));
            } else {
                const position = pwd ? `${pwd}.${i}` : i;
                const nuevo = { name: position, value: prop };
                valores.push(nuevo);
            }
        }
        return valores;
    },
    download: function(url, name) {
        var self = this;
        params = params || {};
        var params = {
            url: url,
            method: 'GET',
            xhrFields: { 'responseType': 'arraybuffer' }
        };
        return new Promise((resolve, reject) => {
            self.request({
                url: url,
                method: 'GET',
                crossDomain: true,
                xhrFields: { 'responseType': 'arraybuffer' },
                success: function(data) {
                    var blob = new Blob([data]);
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob, name);
                    } else {
                        var link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = name;
                        link.target = '_blank';
                        link.click();
                        URL.revokeObjectURL(link.href);
                    }
                    resolve();
                },
                error: function(error) {
                    reject(error);
                }
            });
        });
    },
    exportExcel: function(params) {
        var self = this;
        params = params || {};
        var name = params.name || model + 's.xlsx';
        return new Promise((resolve, reject) => {
            self.request({
                url: self.server_zipado + self.prefix + self.model + '/excel',
                method: 'POST',
                contentType: 'application/json',
                crossDomain: true,
                xhrFields: { 'responseType': 'arraybuffer' },
                data: params.filter || {},
                success: function(data) {
                    var blob = new Blob([data]);
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob, name);
                    } else {
                        var link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = name;
                        link.target = '_blank';
                        link.click();
                        URL.revokeObjectURL(link.href);
                    }
                    resolve();
                },
                error: function(error) {
                    reject(error);
                }
            });
        });
    },
    exportDynamicExcel: function(params) {
        var self = this;
        params = params || {};
        var name = params.name || model + 's.xlsx';
        var pagination = {
            sortBy: params.order,
            sortDesc: params.orderDir == 'DESC' ? true : false,
            projection: params.projection,
            operation: params.operation
        };
        var data = {
            filter: params.filter || {},
            columns: params.columns || {},
            pagination: pagination
        }
        return new Promise((resolve, reject) => {
            self.request({
                url: self.server_zipado + self.prefix + self.model + '/dynamicexcel',
                method: 'POST',
                contentType: 'application/json',
                crossDomain: true,
                xhrFields: { 'responseType': 'arraybuffer' },
                data: data,
                success: function(data) {
                    var blob = new Blob([data]);
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob, name);
                    } else {
                        var link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = name;
                        link.target = '_blank';
                        link.click();
                        URL.revokeObjectURL(link.href);
                    }
                    resolve();
                },
                error: function(error) {
                    reject(error);
                }
            });
        });
    },
    sendRequest: function(params, cached, callback, errorCallback, completeCallback) {
        var self = this;
        var callback = callback;
        var errorCallback = errorCallback;
        var params = Object.assign({
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true,
            expiresIn: self.cache_expiration_time
        }, params);
        var cached = cached != undefined ? cached : true;
        var completeCallback = completeCallback || function() {};
        if (cached) {
            var cachedRequest = CacheService.getCachedRequest(self.model, params);
            if (cachedRequest) {
                return cachedRequest;
            }
        }
        var promise = new Promise(function(resolve, reject) {
            var callback = callback || function(res) {
                if (res.data.error) {
                    reject(res.data);
                } else if (res.data.dto) {
                    resolve(res.data.dto);
                } else {
                    resolve(res.data);
                }
            };
            var errorCallback = errorCallback || function({ xhr, status, message }) {
                var app = window.app;
                try {
                    const errorCode = xhr ? JSON.parse(xhr.response).error : undefined;
                    if (status == 401 || (errorCode && errorCode == "invalid_token")) {
                        app.methods.removeSession();
                        app.preloader.hide();
                        var currentURL = app.views.main.router.currentRoute.path;
                        if (currentURL != "/login/" && currentURL != "/logout/") {
                            Flash.set("redirectTo", currentURL + "?reloadPage");
                            app.views.main.router.navigate("/logout/");
                        }
                    } else {
                        reject(errorCode || message);
                    }
                } catch (e) {
                    reject(xhr.response);
                }
            }
            self.request.promise(params).then(callback).catch(errorCallback).then(completeCallback);
        });
        CacheService.addCachedRequest(self.model, params, promise);
        return promise;
    },
    resetCache: function() {
        var self = this;
        CacheService.clearCache(self.model);
    }
}

export default DatabaseModel;