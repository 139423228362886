import CacheService from './Cache.js';
import DatabaseModel from './DatabaseModel.js';

var Paises = Object.create(DatabaseModel);

Paises.model = 'pais';
Paises.modelFront = 'pais';

Paises.importar = function(documento) {
    var self = this;
    var params = {
        url: self.server_zipado + self.prefix + self.model + '/importar/',
        method: 'POST',
        data: documento
    };
    return self.sendRequest(params, false).then((res) => {
        CacheService.clearCache(self.model);
        return res;
    }).catch((error) => {
        var message = typeof error == "string" ? error : error.message;
        app.dialog.alert(message);
    });
}

export default Paises;