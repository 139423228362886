import RoutesUtils from '../../js/RoutesUtils.js';
import JornadaConfiguracionForm from './jornadaConfiguracion-form.vue';
import JornadaConfiguracion from './jornadaConfiguracion.vue';

var routes = [{
        path: '/jornadaConfiguracion/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('jornadaConfiguracion', ['ADMIN', 'CHECK_MAN'], JornadaConfiguracion),
        keepAlive: false
    },
    {
        path: '/jornadaConfiguracion/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('jornadaConfiguracion/new', ['ADMIN', 'CHECK_MAN'], JornadaConfiguracionForm)
    },
    {
        path: '/jornadaConfiguracion/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('jornadaConfiguracion/edit', ['ADMIN', 'CHECK_MAN'], JornadaConfiguracionForm)
    },
    {
        path: '/jornadaConfiguracion/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('jornadaConfiguracion/view', ['ADMIN', 'CHECK_MAN'], JornadaConfiguracionForm, { editable: false })
    },
];
export default routes;