import DatabaseModel from './DatabaseModel.js';

var JornadaTrabajo = Object.create(DatabaseModel); 

JornadaTrabajo.model = 'jornada/trabajo';
JornadaTrabajo.modelFront = 'jornadaTrabajo';

JornadaTrabajo.createAll = function(params) {
    var self = this;
    params = params || {};
    var params = {
        url: self.server_zipado + self.prefix + self.model + '/createAll/',
        method: 'GET',
        data: params.data
    };
    return self.sendRequest(params, false).then(function(res) {
        return res;
    });
};

JornadaTrabajo.calcular = function(params) {
    var self = this;
    params = params || {};
    var params = {
        url: self.server_zipado + self.prefix + self.model + '/calcular/' + params.referencia,
        method: 'GET',
        data: params.data
    };
    return self.sendRequest(params, false).then(function(res) {
        return res;
    });
};

export default JornadaTrabajo;
