<template>
  <r-page name="home" class="display-flex">
    <img src="static/img/logo_TRACER2021-1.png" style="margin:auto;"/>
  </r-page>
</template>
<style scoped>
  .dashboard {
    background: #525659;
  }
  .display-flex {
    display: flex;
  }
</style>
<style>
</style>
<script>
import Grafica from "./../js/db/Grafica.js";
import Chart from "./../components/charts/chart.vue"

export default {
  components: {
    Chart
  },
  data() {
    return {
      Grafica,
      graficas: [],
    };
  },
  mounted: function() {
    var self = this;
    var app = self.$f7;

    Grafica.getList({}, true).then(res => {
      console.log(res);
      let result = res.list;
      result.forEach(r => r.data = []);
      self.graficas = result;
    }).catch(e => self.catchError(e))

  },
  methods: {
    catchError: function(error) {
      console.log(error);
      const self = this;
      const app = self.$f7;
      let ms = error;
      if (error.message) {
        ms = error.message;
      }
      app.dialog.alert(ms);
    }
  }
};
</script>