import Notificaciones from './notificaciones.vue';
import RoutesUtils from './../../js/RoutesUtils.js';
import NotificacionesForm from './notificaciones-form.vue';

var routes = [{
        path: '/notificaciones/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('notificaciones', ['ADMIN', 'CHECK_MAN'], Notificaciones),
        keepAlive: true
    },
    {
        path: '/notificaciones/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('notificaciones/new', ['ADMIN', 'CHECK_MAN'], NotificacionesForm)
    },
];
export default routes;