<template>
  <r-page :page-content="true">
    <f7-navbar :back-link="$t('atras')">
      <f7-nav-title v-if="id && !readonly" sliding>{{$t('combos.editar')}}</f7-nav-title>
      <f7-nav-title v-else-if="id" sliding>{{$t('combos.combo')}}</f7-nav-title>
      <f7-nav-title v-else sliding>{{$t('combos.nuevo')}}</f7-nav-title>
      <f7-nav-right>
        <f7-button
          v-if="!readonly"
          fill
          icon-f7="floppy_disk"
          text="Guardar"
          class="display-flex"
          @click="$refs.rform.saveItem()"
        ></f7-button>
        <template slot="right" v-else>
          <f7-button color="blue" @click="readonly=false">Editar</f7-button>
          <f7-button color="red" @click="deleteItem">Eliminar</f7-button>
        </template>
      </f7-nav-right>
    </f7-navbar>
    <r-form
      ref="rform"
      v-slot="{rform}"
      :itemId="id"
      :beforeSave="beforeSave"
      @save="onSave"
      @loadItem="loadItem"
      :readonly="readonly"
      :dbAdapter="Combos"
      :defaultData="{valores: [], tipo: 'SIMPLE'}"
    >
      <f7-card class="padding-vertical-half padding-right" style="z-index: 3000">
        <f7-card-content>
          <f7-row no-gap class="list list-form no-hairlines">
            <f7-col width="100" medium="50">
              <ul class="row">
                <r-input
                  class="col-50"
                  floating-label
                  :label="$t('combos.nombre')"
                  :form="rform"
                  type="text"
                  name="nombre"
                ></r-input>
                <r-input
                  class="col-50"
                  floating-label
                  :label="$t('combos.tipo')"
                  :form="rform"
                  :readonly="!!rform.formData.valores.length"
                  type="select"
                  name="tipo"
                >
                  <option value="SIMPLE">{{$t('combos.tipos.simple')}}</option>
                  <option value="TEXTO">{{$t('combos.tipos.texto')}}</option>
                  <option value="HTML">{{$t('combos.tipos.html')}}</option>
                  <option value="NUMERO">{{$t('combos.tipos.numero')}}</option>
                </r-input><br/>
              </ul>
            </f7-col>
          </f7-row>
          <f7-block-title>
              {{$t('combos.opciones')}} <span v-if="!readonly"> - <f7-link @click="rform.formData.valores.push({})">{{$t('combos.nueva_opcion')}}</f7-link></span>
              <span
                v-if="!rform.formData.valores || !rform.formData.valores.length"
              >{{$t('combos.sin_opciones')}}</span>
              <f7-list media-list class="no-margin">
                <Container 
                  v-if="$refs.rform && $refs.rform.formData" 
                  @drop="onDropValor(rform.formData.valores, $event)"
                  drag-handle-selector=".column-drag-handle"
                  lock-axis="y"
                >
                  <Draggable
                    v-for="(opcion,index) in rform.formData.valores"
                    :key="'combo-'+id+'-opcion-'+index"
                  >
                    <f7-row class="valor-row">
                      <div class="valor-controles">
                        <f7-link
                          v-if="!rform.readonly"
                          @click="deleteValor(rform.formData, index)"
                          class="margin-half valor-delete"
                          color="red"
                          icon-f7="trash"
                        ></f7-link>
                        <span 
                          v-if="!rform.readonly"
                          class="column-drag-handle margin-left cursor-move col-5 valor-reordenar"
                          v-tooltip="$t('common.move')"
                        >&uarr;&darr;</span>
                      </div>
                      <r-input
                        class="col-25 valor-codigo"
                        floating-label
                        :label="$t('combos.codigo_opcion')"
                        :form="rform"
                        type="text"
                        :name="'valores.'+index+'.valor'"
                      ></r-input>
                      <r-input
                        v-if="rform.formData.tipo=='TEXTO' || rform.formData.tipo=='NUMERO'"
                        class="col-75"
                        floating-label
                        :label="$t('combos.nombre_opcion')"
                        :form="rform"
                        type="text"
                        :name="'valores.'+index+'.nombre'"
                      ></r-input>
                      <r-rich-text-input
                        v-if="rform.formData.tipo=='HTML'"
                        :disabled="rform.readonly"
                        class="col-100"
                        floating-label
                        :label="$t('combos.nombre_opcion')"
                        :form="rform"
                        :name="'valores.'+index+'.nombre'"
                      ></r-rich-text-input>
                      <br/>
                    </f7-row>
                  </Draggable>
                </Container>
              </f7-list>
            </f7-block-title>
        </f7-card-content>
      </f7-card>
      <pre style="display:none;">{{rform.formData}}</pre>
    </r-form>
  </r-page>
</template>
<style scoped>
  .label-group {
    margin-top: 12px;
    margin-left: 20px;
    font-weight: bold;
  }
  .label-group-inspeccion {
    margin-top: 30px;
  }
  label.btn {
    padding: 1px;
    cursor: pointer;
    border: 1px solid;
  }
  .valor-row {
    position: relative;
  }
  .valor-codigo {
    padding-left: 3em;
  }
  .valor-controles {
    position:absolute;
    left: 0;
    top: 0;
    z-index: 2;
  }
  .valor-delete {
    position:absolute;
    left: 0;
    top: 7px;
  }
  .valor-reordenar {
    margin-top:2px;
    position:absolute;
    left: 12px;
    top: 11px;
  }

</style>
<script>
import Vue from "vue";
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import rFiles from "./../../components/rFiles.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import rTimepicker from "./../../components/rTimepicker.vue";
import rRichTextInput from "./../../components/rRichTextInput.vue";
import { Container, Draggable } from "vue-smooth-dnd";
import Combos from "./../../js/db/Combos.js";

export default {
  components: {
    rForm,
    rInput,
    rTimepicker,
    rFiles,
    rRichTextInput,
    rAutocomplete,
    Container,
    Draggable
  },
  data: function() {
    var self = this;
    return {
      Combos,
      readonly: !this.editable
    };
  },
  props: {
    id: {
      type: String
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  computed: {},
  beforeDestroy() {},
  methods: {
    deleteItem: function() {
      var self = this;
      var app = self.$f7;
      app.dialog.confirm(
        self.$t("rlist.pregunta_eliminar_registro"),
        "Eliminar",
        function() {
          Combos.delete({itemId: self.id}).then(function(res) {
            if (res) {
              app.toast
                .create({
                  icon: '<i class="f7-icons">checkmark</i>',
                  text: self.$t("rlist.registro_eliminado"),
                  position: "center",
                  destroyOnClose: true,
                  closeTimeout: 2000
                })
                .open();
              self.$f7router.back()
            }
          }).catch(function(error){
            if(error.message){
              app.dialog.alert(error.message);
            } else {
              app.dialog.alert(error);
            }
          });
        }
      );
    },
    onDropValor: function(arr, dragResult){
      var self = this;
      const { removedIndex, addedIndex, payload } = dragResult;
      if (removedIndex === null && addedIndex === null) return arr;
      if (!arr) arr = [];
      var result = arr;
      let itemToAdd = payload;
      if (removedIndex !== null) {
        itemToAdd = Object.assign({},  result.splice(removedIndex, 1)[0]);
      }
      if (addedIndex !== null) {
        result.splice(addedIndex, 0, Object.assign({}, itemToAdd));
      }
    },
    deleteValor: function(combo, indexValor){
      combo.valores.splice(indexValor,1);
    },
    beforeSave: function(data, resolve, reject) {
      data.valores.forEach(valor => {
        if(!valor.nombre){
          valor.nombre = valor.valor;
        }
      });
      resolve(data);
    },
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.$refs.rform.formData = res;
      self.readonly = true;
      app.toast
        .create({
          text: self.$t("combos.combo_guardado"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
    },
    loadItem: function(res) {
      var self = this;
      if(!res.tipo){
        res.tipo = "SIMPLE";
      }
    }
  },
  created: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;
  }
};
</script>