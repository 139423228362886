<template>
  <div>
    <f7-list-input
      v-if="scrollPicker"
      :value="getValue()"
      v-bind="{...$attrs,...$props}"
      autocomplete="off"
      v-on="$listeners"
      :error-message-force="!!form.errors[name]"
      :error-message="form.errors[name]"
      :readonly="true"
      :class="{readonly:overrideReadonly ? readonly : readonly || form.readonly}"
      :clear-button="clearButton"
      @input:clear="setValue(null)"
    >
      <slot></slot>
      <slot name="media" slot="media"></slot>
    </f7-list-input>
    <li 
        v-else
        class="col-100 medium-50 has-tooltip">
      <div class="item-content item-input " :class="getClasses()">
        <div class="item-inner">
          <div class="item-title item-label item-floating-label">{{label}}</div>
          <div class="item-input-wrap">
            <input 
              :readonly="overrideReadonly ? readonly : readonly || form.readonly" 
              :class="{readonly: overrideReadonly ? readonly : (readonly || form.readonly)}" 
              name="campo" 
              type="text" 
              autocomplete="no" 
              v-mask="['##:##']"
              :placeholder="e_placeholder" 
              @change="onChange($event.target.value ? $event.target.value.split(':') : null)" :value="getValue()" v-bind="{...$attrs,...$props}">
            <span v-if="clearButton" class="input-clear-button" @click="setValue(null)"></span>
          </div>
        </div>
      </div>
    </li>
  </div>
</template>
<style>
.readonly input,
.readonly select {
  pointer-events: none;
}
</style>
<script>
export default {
  data() {
    return {
      self: this,
      picker: null,
      // invalidValue: false
    };
  },
  methods: {
    getClasses: function() {
      const self = this;
      return {
        'item-input-with-value': !!self.getValue(),
        'item-input-outline': self.$attrs["outline"] != undefined
      };
    },
    getValue: function() {
      const self = this;
      var namespaces = self.name.split(".");
      var context = self.form.formData;
      for (var i = 0; i < namespaces.length; i++) {
        if (typeof context == "undefined") {
          let n = namespaces[i];
          if (0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n)) {
            //Número entero positivo
            context = [];
          } else {
            context = {};
          }
        }
        context = context[namespaces[i]];
      }
      return context;
    },
    renderValue: function() {
      var self = this;
      var context = self.getValue();
      if (context) {
        if (typeof context == "string") context = new Date(context);
        let value = [
          ("0" + context.getHours()).slice(-2),
          ("0" + context.getMinutes()).slice(-2)
        ];
        if (
          self.picker &&
          JSON.stringify(self.picker.getValue()) != JSON.stringify(value)
        ) {
          self.picker.setValue(value);
        }
        return value.join(":");
      } else {
        return null;
      }
    },
    setValue: function(val, a) {
      var self = this;
      var namespaces = self.name.split(".");
      var context = self.form.formData;
      for (var i = 0; i < namespaces.length; i++) {
        if (i == namespaces.length - 1) {
          self.$set(context, namespaces[i], val);
        } else if (typeof context[namespaces[i]] == "undefined") {
          let n = namespaces[i + 1];
          if (0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n)) {
            //Número entero positivo
            self.$set(context, namespaces[i], []);
          } else {
            self.$set(context, namespaces[i], {});
          }
        }
        context = context[namespaces[i]];
      }
    },
    onChange: function(value) {
      var self = this;
      let v = self.getValue();
      if (!!!value) {
        self.setValue(null);
        if (!self.scrollPicker) {
          self.$emit('change', value);
        }
        return;
      }
      if (v) {
        if (typeof v == "string") v = new Date(v);
      } else {
        v = new Date();
      }
      v.setHours(value[0]);
      v.setMinutes(value[1]);
      v = new Date(v);
      self.setValue(v, "calendar");
      if (!self.scrollPicker) {
        self.$emit('change', value.join(":"));
      }
    }
  },
  props: {
    name: {
      required: true,
      type: String
    },
    form: {
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    overrideReadonly: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "text"
    },
    label: {
      type: String,
      default: ""
    },
    e_mask: {
      type: String,
      default: "['##:##']"
    },
    e_placeholder: {
      type: String,
      default: ""
    },
    clearButton: {
      type: Boolean,
      default: false
    },
    scrollPicker: {
      type: Boolean,
      default: false
    },
    possibleHours: {
      type: String,
      default: function() {
        return Array.from({ length: 24 }, (_, i) => ("0" + i).slice(-2)).join(" ");
      }
    },
    possibleMinutes: {
      type: String,
      default: function() {
        return Array.from({ length: 60 }, (_, i) => ("0" + i).slice(-2)).join(" ");
      }
    }
  },
  mounted() {
    var $ = this.$$;
    var $el = $(this.$el);
    var self = this;
    var app = self.$f7;

    if(self.scrollPicker){
      self.picker = app.picker.create({
        inputEl: self.$$(self.$el).find("input")[0],
        rotateEffect: true,
        routableModals: false,
        toolbarCloseText: "Cerrar",
        cols: [
          {
            values: self.possibleHours.split(" ")
          },
          {
            divider: true,
            content: ":"
          },
          {
            values: self.possibleMinutes.split(" ")
          }
        ],
        on: {
          change: function(picker, value, displayValue) {
            self.onChange(value);
          }
        }
      });
    }
  }
};
</script>