<template>
  <r-page>
    <!-- Top Navbar -->
    <f7-navbar :title="$t('llegada.title')" :back-link="$t('atras')"></f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height">
      <!-- rList Table CARD-->
      <r-dynamic-list
        ref="rlist"
        :dbAdapter="PrevisionLlegadas"
        :tableCode="'LLEGADA'"
        :tableMode="true"
        :virtualListParams="{height:32, rowsBefore:60, rowsAfter:60}"
        class="card full-height data-table"
        classContent="card-content full-height"
        :shownFields="['numero']"
        :massiveActions="true"
        :funcShowCheck="showCheckInList"
        :controlsWidth="90"
        :searchOnInit="true"
      >
        <template v-slot:tableLinks>
          <!-- <a class="link" href="/previsionLlegadas/new/">{{$t('previsionLlegadas.importar')}}</a> -->
          <!-- <f7-link v-tooltip="$t('previsionLlegadas.importar')" color="green" @click="importar()">{{$t('previsionLlegadas.importar')}}</f7-link> -->
          <r-form
            cass="form-inline"
            ref="rformImport"
            v-slot="{rform}"
            :readonly="false"
            :dbAdapter="null"
            :documentoAdapter="Documento"
            :beforeSave="beforeSaveImport"
            @save="onImport"
            @error="onImportError"
          >
            <r-files path="/previsionLlegadas/" :form="rform" name="documento" :maxFiles='1' label="Importar Documento" @loadFile="loadFile" :preview="false"></r-files>
          </r-form>
        </template>
        <template v-slot:optionsColumn="{item,rlist}">
          <f7-link v-tooltip="$t('common.ver')" icon-f7="eye" color="green" :href="`/previsionLlegadas/view/${item.id}/`"></f7-link>
          <!-- <f7-link v-tooltip="$t('common.editar')" icon-f7="square_pencil" color="blue" :href="`/previsionLlegadas/edit/${item.id}/`"></f7-link> -->
          <f7-link v-tooltip="$t('common.delete')" icon-f7="trash" color="red" @click="rlist.deleteItem(item.id);"></f7-link>
        </template>
      </r-dynamic-list>
    </f7-page-content>

  </r-page>
</template>
<script>
import rForm from "../../components/rForm.vue";
import rDynamicList from "../../components/rDynamicList.vue";
import PrevisionLlegadas from "../../js/db/PrevisionLlegadas.js";
import Utils from "../../js/Utils.js";
import rFiles from "../../components/rFiles.vue";
import Documento from "../../js/db/Documento.js";
export default {
  components: {
    rDynamicList,
    rFiles,
    rForm
  },
  data: function() {
    var self = this;
    return {
      PrevisionLlegadas,
      Utils,
      Documento
    };
  },
  computed: {},
  methods: {
    showCheckInList: function(item) {
      return false; 
    },
    importar(){
      var self = this;
      var app = self.$f7;
      app.popup.open(".popupimportar");
    },
    onImport: function (res) {
      var self = this;
      var app = self.$f7;
      self.PrevisionLlegadas.importar(res.documento).then((res) => {
        self.$set(self.$refs.rformImport.formData,'documento',[]);
        self.$refs.rlist.submitFilterForm();
        app.preloader.hide();
      }).finall;
    },
    onImportError: function (e) {
      var self = this;
      var app = self.$f7;
      app.preloader.hide(); 
      // TODO mensaje error;
    },
    beforeSaveImport: function(data, resolve, reject) {
      var self = this;
      var app = self.$f7;
      var o = Object.assign({}, data);
      o.documento = Array.isArray(o.documento) ? o.documento[0] : o.documento;
      app.preloader.hide();
      resolve(o);
    },
    loadFile: function () {
      var self = this;
      var app = self.$f7;
      self.$refs.rformImport.saveItem();
      app.preloader.show();
    },
  },
  mounted: function() {
    var self = this;
    var app = self.$f7;
  }
};
</script>