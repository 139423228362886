import PrevisionLlegada from './previsionLlegada.vue';
import PrevisionLlegadaForm from './previsionLlegada-form.vue';
import RoutesUtils from '../../js/RoutesUtils.js';

var routes = [{
        path: '/previsionLlegadas/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('previsionLlegada', ['ADMIN', 'CHECK_MAN'], PrevisionLlegada),
        keepAlive: true
    },
    {
        path: '/previsionLlegadas/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('previsionLlegada/new', ['ADMIN', 'CHECK_MAN'], PrevisionLlegadaForm)
    },
    {
        path: '/previsionLlegadas/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('previsionLlegada/edit', ['ADMIN', 'CHECK_MAN'], PrevisionLlegadaForm)
    },
    {
        path: '/previsionLlegadas/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('previsionLlegada/view', ['ADMIN', 'CHECK_MAN'], PrevisionLlegadaForm, { editable: false })
    },
];
export default routes;