import Ubicaciones from './ubicaciones.vue';
import UbicacionForm from './ubicacion-form.vue';
import RoutesUtils from '../../js/RoutesUtils.js';

var routes = [{
        path: '/ubicaciones/',
        beforeEnter: RoutesUtils.checkAuthPage,
    async: RoutesUtils.checkPermission('ubicaciones', ['ADMIN', 'CHECK_MAN'], Ubicaciones),
        keepAlive: true
    },
    {
        path: '/ubicaciones/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('ubicaciones/new', ['ADMIN', 'CHECK_MAN'], UbicacionForm)
    },
    {
        path: '/ubicaciones/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('ubicaciones/edit', ['ADMIN', 'CHECK_MAN'], UbicacionForm)
    },
    {
        path: '/ubicaciones/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('ubicaciones/view', ['ADMIN', 'CHECK_MAN'], UbicacionForm, { editable: false })
    },
];
export default routes;