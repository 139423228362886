<template>
  <r-page>
    <!-- Top Navbar -->
    <f7-navbar :title="$t('properties.title')" :back-link="$t('atras')"></f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height">
      <!-- rList Table CARD-->
      <r-dynamic-list
        ref="rlist"
        :dbAdapter="Properties"
        :tableCode="'PROPERTIES'"
        :tableMode="true"
        :virtualListParams="{height:32, rowsBefore:60, rowsAfter:60}"
        class="card full-height data-table"
        classContent="card-content full-height"
        :shownFields="['nombre','activo']"
        :massiveActions="true"
        :funcShowCheck="showCheckInList"
        :controlsWidth="110"
        :searchOnInit="true"
        :mandatoryFilter ="{editable: true}"
      >
        <template v-slot:tableLinks>
          <a class="link" href="/properties/new/">{{$t('properties.nuevo')}}</a>
          <!-- <f7-link v-tooltip="$t('properties.importar')" color="green" @click="importar()">{{$t('properties.importar')}}</f7-link> -->
          <r-form
            v-if="false"
            class="form-inline"
            ref="rformImport"
            v-slot="{rform}"
            :readonly="false"
            :dbAdapter="null"
            :documentoAdapter="Documento"
            :beforeSave="beforeSaveImport"
            @save="onImport"
            @error="onImportError"
          >
            <r-files path="/properties/" :form="rform" name="documento" :maxFiles='1' label="Importar Documento" @loadFile="loadFile" :preview="false"></r-files>
          </r-form>
        </template>
        <template v-slot:optionsColumn="{item,rlist}">
          <f7-link v-tooltip="$t('common.ver')" icon-f7="eye" color="green" :href="`/properties/view/${item.nombre}/`"></f7-link>
          <f7-link v-if="item.editable" v-tooltip="$t('common.editar')" icon-f7="square_pencil" color="blue" :href="`/properties/edit/${item.nombre}/`"></f7-link>
          <f7-link v-if="item.editable" v-tooltip="$t('common.eliminar')" icon-f7="trash" color="red" @click="rlist.deleteItem(item.nombre);"></f7-link>
        </template>
      </r-dynamic-list>
    </f7-page-content>

  </r-page>
</template>
<script>
import rForm from "../../components/rForm.vue";
import rDynamicList from "../../components/rDynamicList.vue";
import Properties from "../../js/db/Properties.js";
import Utils from "../../js/Utils.js";
import rFiles from "../../components/rFiles.vue";
import Documento from "../../js/db/Documento.js";
export default {
  components: {
    rDynamicList,
    rFiles,
    rForm
  },
  data: function() {
    return {
      Properties,
      Utils,
      Documento
    };
  },
  computed: {},
  methods: {
    showCheckInList: function(item) {
      return false; 
    },
    importar(){
      var self = this;
      var app = self.$f7;
      app.popup.open(".popupimportar");
    },
    onImport: function (res) {
      var self = this;
      var app = self.$f7;
      self.Properties.importar(res.documento).then((res) => {
        self.$set(self.$refs.rformImport.formData,'documento',[]);
        self.$refs.rlist.submitFilterForm();
        app.preloader.hide();
      }).finall;
    },
    onImportError: function (e) {
      var self = this;
      var app = self.$f7;
      app.preloader.hide(); 
      // TODO mensaje error;
    },
    beforeSaveImport: function(data, resolve, reject) {
      var self = this;
      var app = self.$f7;
      var o = Object.assign({}, data);
      o.documento = Array.isArray(o.documento) ? o.documento[0] : o.documento;
      app.preloader.hide();
      resolve(o);
    },
    loadFile: function () {
      var self = this;
      var app = self.$f7;
      self.$refs.rformImport.saveItem();
      app.preloader.show();
    }    

  },
  mounted: function() {
    var self = this;
    var app = self.$f7;
    /*app.dataTable.create({
      el: self.$refs.datatable
    });*/
  }
};
</script>