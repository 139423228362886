import DatabaseModel from './DatabaseModel.js';
import CacheService from './Cache.js';

var Dispositivo = Object.create(DatabaseModel);

Dispositivo.model = 'dispositivo';
Dispositivo.modelFront = 'dispositivos';

Dispositivo.activar = function(id) {
    var self = this;
    var params = {
        url: self.server_zipado + self.prefix + self.model + '/activar/' + id,
        method: 'PUT',
        data: {}
    };
    return self.sendRequest(params, false).then((res) => {
        CacheService.clearCache(self.model);
        return res;
    }).catch((error) => {
        var message = typeof error == "string" ? error : error.message;
        app.dialog.alert(message);
    });
}

Dispositivo.desactivar = function(id) {
    var self = this;
    var params = {
        url: self.server_zipado + self.prefix + self.model + '/desactivar/' + id,
        method: 'PUT',
        data: {}
    };
    return self.sendRequest(params, false).then((res) => {
        CacheService.clearCache(self.model);
        return res;
    }).catch((error) => {
        var message = typeof error == "string" ? error : error.message;
        app.dialog.alert(message);
    });
}

export default Dispositivo;