<template>
  <div>
    <div class="title">
      <slot name ="title"></slot>
    </div>
    <slot name="chart"/>
    <div class="filter" v-if="filtros && filtros.length">
      <r-form 
        v-on="$listeners"
        class="full-height table-content"
        classContent="full-height"
      >
        <template v-slot="{rform}">
          <f7-list no-hairlines-md class="display-flex flex-direction-row justify-content-center flex-wrap-wrap">
            <div class="display-flex"
              v-for="(filtro, idx) in filtros"
              :key="'filtro' + idx"
              :style="filtro.opciones && filtro.opciones.style ? filtro.opciones.style : null"
            >
              <simple-filter-item
                v-if="filtro.activo"
                :filtro="filtro"
                :form="rform"
                :viewTooltip="false"
                @keyup.enter.native="rform.submit()"
              >
              </simple-filter-item>
            </div>
            <div class="display-flex padding-top">
              <f7-button button fill type="submit" class="display-flex" icon-f7="search">Filtrar</f7-button>
            </div>
          </f7-list>
        </template>
      </r-form>
    </div>
  </div>
</template>

<script>
  import RForm from "../rForm.vue"
  import SimpleFilterItem from "./../simpleFilterItem.vue"

  export default {
    components: {
      RForm,
      SimpleFilterItem
    },
    data() {
      const self = this;
      return {
        data: self.sourceData
      };
    }, 
    methods: {
      getData(data) {
        return data;
      },
      reloadData(data) {
        const self = this;
        self.data = self.getData(data);
      }
    },
    props: {
      sourceData: {
        type: Array,
        default: function() {
          return [];
        }
      },
      nombre: {
        type: String,
        default: 'nombre'
      },
      valor: {
        type: String,
        default: 'valor'
      },
      title: {
        type: String,
        default: ''
      },
      height: {
        type: Number,
        default: 300
      },
      withFilter: {
        type: Boolean,
        default: false
      },
      fields: {
        type: Array,
        default: function() {
          return [];
        }
      },
      filtros: {
        type: Array,
        default: function() {
          return [];
        }
      }
    },
    mounted: function() {
      const self = this;
      self.data = self.getData(self.data);
    }
  };
</script>
<style scoped>
  .title {
    text-align: center;
  }
  .flex-wrap-wrap {
    flex-wrap: wrap;
  }
  .flex-wrap-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .flex-wrap-nowrap {
    flex-wrap: nowrap;
  }
  .flex-wrap-unset {
    flex-wrap: unset;
  }
</style>
