<template>
  <r-page :page-content="true">
    <f7-navbar :back-link="$t('atras')">
      <!-- <f7-nav-title v-if="id && !readonly" sliding>{{$t('jornadaTrabajo.editar')}}</f7-nav-title> -->
      <f7-nav-title v-if="id" sliding>{{$t('jornada.trabajo.title')}}</f7-nav-title>
      <f7-nav-title v-else sliding>{{$t('jornada.trabajo.nueva')}}</f7-nav-title>
      <f7-nav-right>
        <template slot="right" v-if="!readonly">
          <f7-button
            fill
            icon-f7="floppy_disk"
            text="Guardar"
            class="display-flex"
            @click="$refs.rform.saveItem()"
          ></f7-button>
        </template>
        <template slot="right" v-else>
          <f7-button color="blue" @click="readonly=false">Editar</f7-button>
          <f7-button color="red" @click="deleteItem">Eliminar</f7-button>
        </template>
      </f7-nav-right>
    </f7-navbar>
    <r-form
      ref="rform"
      v-slot="{rform}"
      :itemId="id"
      :beforeSave="beforeSave"
      @save="onSave"
      @loadItem="loadItem"
      :readonly="readonly"
      :dbAdapter="JornadaTrabajo"
      :defaultData="{valores: [], tipo: 'SIMPLE'}"
    >
      <f7-card class="padding-vertical-half padding-right">
        <f7-card-content
          :class="rform.formData.trabajoRealizado && rform.formData.trabajoAsignado && rform.formData.trabajoAsignado + (rform.formData.llevada?rform.formData.llevada:0) <= rform.formData.trabajoRealizado? 'trabajo-completo':rform.formData.trabajoRealizado?'trabajo-incompleto':''">
          <f7-row no-gap class="list list-form no-hairlines">
            <r-input
              class="col-10 "
              floating-label
              :label="$t('jornada.trabajo.referencia')"
              :form="rform"
              type="text"
              :allMayus="true"
              name="referencia"
              @change="calcular"
            ></r-input>
            <r-input
              class="col-10"
              floating-label
              :label="$t('jornada.trabajo.CentroTrabajo')"
              :form="rform"
              type="text"
              :allMayus="true"
              name="codigoCentroTrabajo"
            ></r-input>
            <r-input
              class="col-10"
              floating-label
              :label="$t('jornada.trabajo.fecha')"
              :form="rform"
              type="datepicker"
              :name="'fecha'"
              :calendar-params="{
                  closeOnSelect: true,
                  routableModals: false,
                  dateFormat: {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  },
                }"
            ></r-input>
            <r-input
              class="col-10 medium-10"
              floating-label
              :label="$t('jornada.trabajo.horaInicio')"
              :form="rform"
              name="horaInicio"
              v-mask="['##:##']"
              placeholder="HH:MM"
              :clearButton="false"
            ></r-input>
            <r-input
              class="col-10 medium-10"
              floating-label
              :label="$t('jornada.trabajo.trabajoAsignadoPropuesto')"
              :form="rform"
              type="number"
              name="trabajoAsignadoPropuesto"
              :clearButton="false"
              :readonly="true"
              :overrideReadonly="true"
            ></r-input>
            <r-input
              class="col-10 medium-10"
              :class="{'colorTexto-verde':rform.formData.llevada > 0,'colorTexto-negro':rform.formData.llevada = 0,'colorTexto-rojo':rform.formData.llevada < 0}"
              floating-label
              :label="$t('jornada.trabajo.llevada')"
              :form="rform"
              type="number"
              name="llevada"
              :clearButton="false"
              :readonly="true"
              :overrideReadonly="true"
            ></r-input>
            <r-input
              class="col-10 medium-10"
              floating-label
              :label="$t('jornada.trabajo.trabajoAsignado')"
              :form="rform"
              type="number"
              name="trabajoAsignado"
              @change="repartirTrabajo"
              :clearButton="false"
            ></r-input>
            <r-input
              class="col-10 medium-10"
              floating-label
              :label="$t('jornada.trabajo.trabajoRealizado')"
              :form="rform"
              type="number"
              name="trabajoRealizado"
              :clearButton="false"
            ></r-input>
          </f7-row>
        </f7-card-content>
      </f7-card>
      <!-- poner botón de añadir turno -->
      <div v-if="!rform.readonly">
        <div style="display: inline-flex;padding:10px 50px 5px 10px;">
          <b>{{$t('jornada.turno.nuevo')}}</b>
        </div>
        <f7-link
          v-if="!rform.readonly"
          slot="right"
          @click="nuevoTurno()"
          icon-f7="plus_app_fill"
        ></f7-link>
      </div>
      <f7-card class="padding-vertical-half padding-right" 
        v-for="(turno,index) in rform.formData.turnos" 
        :key="'turnos.' +  index" 
      >
        <f7-card-content 
              :class="turno.trabajoRealizado && turno.trabajoAsignado && turno.trabajoAsignado + (turno.llevada?turno.llevada:0) <= turno.trabajoRealizado? 'trabajo-completo':turno.trabajoRealizado?'trabajo-incompleto':''">
          <f7-row no-gap class="list list-form no-hairlines">
            <r-input
              class="col-10"
              floating-label
              :label="$t('jornada.turno.nombre')"
              :form="rform"
              :allMayus="true"
              type="text"
              :name="'turnos.'+index+'.nombre'"
              :key="'turnos.'+index+'.nombre'"
            ></r-input>
            <r-input
              class="col-10 medium-10"
              floating-label
              :label="$t('jornada.turno.horaInicio')"
              :type="'text'"
              :form="rform"
              :name="'turnos.'+index+'.horaInicio'"
              v-mask="['##:##']"
              placeholder="HH:MM"
              :clearButton="false"
            ></r-input>
            <r-input
              class="col-10 medium-10"
              floating-label
              :label="$t('jornada.turno.horaFin')"
              :form="rform"
              :name="'turnos.'+index+'.horaFin'"
              v-mask="['##:##']"
              placeholder="HH:MM"
              :clearButton="false"
            ></r-input>
            <r-input
              class="col-10 medium-10"
              floating-label
              :label="$t('jornada.trabajo.trabajoAsignado')"
              :form="rform"
              type="number"
              :name="'turnos.'+index+'.trabajoAsignado'"
              :clearButton="false"
            ></r-input>
            <r-input
              class="col-10 medium-10"
              :class="{'colorTexto-rojo':turno.llevada > 0,'colorTexto-negro':turno.llevada = 0,'colorTexto-verde':turno.llevada < 0}"
              floating-label
              :label="$t('jornada.trabajo.llevada')"
              :form="rform"
              type="number"
              :name="'turnos.'+index+'.llevada'"
              :clearButton="false"
              :readonly="true"
              :overrideReadonly="true"
            ></r-input>
            <r-input
              class="col-10 medium-10"
              floating-label
              :label="$t('jornada.trabajo.trabajoRealizado')"
              :form="rform"
              type="number"
              :name="'turnos.'+index+'.trabajoRealizado'"
              :clearButton="false"
            ></r-input>
            <r-input
              class="col-10 medium-10"
              floating-label
              :label="$t('jornada.turno.cargaTrabajo')"
              :form="rform"
              type="number"
              :name="'turnos.'+index+'.cargaTrabajo'"
            ></r-input>
            <r-input
              class="col-10"
              floating-label
              :label="$t('jornada.turno.tramos')"
              :form="rform"
              type="number"
              :name="'turnos.'+index+'.tramos'"
            ></r-input>
          </f7-row>
          <f7-row class="list list-form no-hairlines">
            <f7-card 
              v-for="(tramo,index2) in turno.tramosControlProgreso" 
              :key="'tramosControlProgreso.' +  index2" 
              outline 
              :class="{'col-100':true,'trabajo-completo':tramo.trabajoRealizado && tramo.trabajoAsignado && tramo.trabajoAsignado +  (tramo.llevada?tramo.llevada:0) <= (tramo.trabajoRealizado?tramo.trabajoRealizado:0),'trabajo-incompleto':tramo.trabajoRealizado && tramo.trabajoAsignado && tramo.trabajoAsignado +  (tramo.llevada?tramo.llevada:0) > (tramo.trabajoRealizado?tramo.trabajoRealizado:0)}"
            >
              <f7-card-content class=" display-flex">
                  <r-input
                    class="col-25 display-flex"
                    floating-label
                    :label="$t('jornada.tramo.horaInicio')"
                    :type="'text'"
                    :form="rform"
                    :name="'turnos.'+index+'.tramosControlProgreso.'+index2+'.horaInicio'"
                    v-mask="['##:##']"
                    placeholder="HH:MM"
                    :clearButton="false"
                  ></r-input>
                  <r-input
                    class="col-25 display-flex"
                    floating-label
                    :label="$t('jornada.tramo.horaFin')"
                    :type="'text'"
                    :form="rform"
                    :name="'turnos.'+index+'.tramosControlProgreso.'+index2+'.horaFin'"
                    v-mask="['##:##']"
                    placeholder="HH:MM"
                    :clearButton="false"
                  ></r-input>
                  <r-input
                    class="col-25 display-flex"
                    floating-label
                    :label="$t('jornada.trabajo.trabajoAsignado')"
                    :form="rform"
                    type="number"
                    :name="'turnos.'+index+'.tramosControlProgreso.'+index2+'.trabajoAsignado'"
                    :clearButton="false"
                  ></r-input>
                  <r-input
                    class="col-10 medium-10"
                     :class="{'colorTexto-verde':tramo.llevada > 0,'colorTexto-negro':tramo.llevada = 0,'colorTexto-rojo':tramo.llevada < 0}"
                    floating-label
                    :label="$t('jornada.trabajo.llevada')"
                    :form="rform"
                    type="number"
                    :name="'turnos.'+index+'.tramosControlProgreso.'+index2+'.llevada'"
                    :clearButton="false"
                    :readonly="true"
                    :overrideReadonly="true"
                  ></r-input>
                  <r-input
                    class="col-25 display-flex"
                    :color="'red'"
                    floating-label
                    :label="$t('jornada.trabajo.trabajoRealizado')"
                    :form="rform"
                    type="number"
                    :name="'turnos.'+index+'.tramosControlProgreso.'+index2+'.trabajoRealizado'"
                    @change="recalcularTrabajo()"
                    :clearButton="false"
                  ></r-input>
              </f7-card-content>
            </f7-card>
          </f7-row>
          <div style="position: absolute; top: 3px; right: 3px;">
            <f7-link v-if="!readonly" v-tooltip="$t('common.eliminar')" icon-f7="trash" color="red" @click="deleteturno(index);"></f7-link>
          </div>
        </f7-card-content>
      </f7-card>
      <f7-row no-gap class="list list-form no-hairlines">
        <f7-col width="100" medium="90">
        </f7-col>
      </f7-row>
      <pre style="display:none;">{{rform.formData}}</pre>
    </r-form>
  </r-page>
</template>
<style scoped>
  .label-group {
    margin-top: 12px;
    margin-left: 20px;
    font-weight: bold;
  }
  .label-group-inspeccion {
    margin-top: 30px;
  }
  label.btn {
    padding: 1px;
    cursor: pointer;
    border: 1px solid;
  }
  .valor-row {
    position: relative;
  }
  .valor-codigo {
    padding-left: 3em;
  }
  .valor-controles {
    position:absolute;
    left: 0;
    top: 0;
    z-index: 2;
  }
  .valor-delete {
    position:absolute;
    left: 0;
    top: 7px;
  }
  .valor-reordenar {
    margin-top:2px;
    position:absolute;
    left: 12px;
    top: 11px;
  }
  .trabajo-completo {
    background-color: rgba(0, 128, 0, 0.342);
  }
  .trabajo-incompleto {
    background-color: rgba(255, 0, 0, 0.342);
  }
  input.colorTexto-rojo {
    color: red;
  }
  input.colorTexto-verde {
    color: green;
  }
  input.colorTexto-negro {
    color: black;
  }
</style>
<style>
  input.colorTexto-rojo {
    color: red;
  }
  input.colorTexto-verde {
    color: green;
  }
  input.colorTexto-negro {
    color: black;
  }
</style>
<script>
import Vue from "vue";
import rForm from "../../components/rForm.vue";
import rInput from "../../components/rInput.vue";
import rFiles from "../../components/rFiles.vue";
import Documento from "../../js/db/Documento.js";
import rAutocomplete from "../../components/rAutocomplete.vue";
import rTextFormat from "../../components/rTextFormat.vue";
import rTimepicker from "../../components/rTimepicker.vue";
import rRichTextInput from "../../components/rRichTextInput.vue";
import { Container, Draggable } from "vue-smooth-dnd";
import JornadaTrabajo from "../../js/db/jornadaTrabajo.js";

export default {
  components: {
    rForm,
    rInput,
    rTextFormat,
    rTimepicker,
    rFiles,
    rRichTextInput,
    rAutocomplete,
    Container,
    Draggable
  },
  data: function() {
    var self = this;
    return {
      JornadaTrabajo,
      Documento,
      readonly: !this.editable
    };
  },
  props: {
    id: {
      type: String
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  computed: {},
  beforeDestroy() {},
  methods: {
    getHora: function() {
      var date = new Date();
      var hour = date.getHours();
      hour = hour < 10 ? "0" + hour : hour;
      var minutes = date.getMinutes();
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hour + ":" + minutes;
      return strTime;
    },
    recalcularTrabajo: function() {
      var self = this;
      var trabajoRealizado = 0;
      var trabajoAsignado = 0;
      self.$refs.rform.formData.turnos.forEach(function(turno) {
        turno.trabajoRealizado = 0;
        turno.trabajoRealizado = 0;
        turno.tramosControlProgreso.forEach(function(tramo) {
          if(tramo.trabajoRealizado) {
            turno.trabajoRealizado += tramo.trabajoRealizado;
            turno.trabajoAsignado += tramo.trabajoAsignado;
          }
        });
        trabajoRealizado += turno.trabajoRealizado;
        trabajoAsignado += turno.trabajoAsignado;
      });
      self.$set(self.$refs.rform.formData, "turnos", self.$refs.rform.formData.turnos);
      self.$refs.rform.formData.trabajoRealizado = trabajoRealizado;
      self.$refs.rform.formData.trabajoAsignado = trabajoAsignado;
    },
    repartirTrabajo: function() { 
      var self = this;
      let jornada = self.$refs.rform.formData;
      // Vamos a repartir el trabajoAsignado de la jornada entre los turnos, según la carga de trabajo de cada uno
      self.$refs.rform.formData.turnos.forEach(function(turno) {
        turno.trabajoAsignado = Math.ceil(jornada.trabajoAsignado * turno.cargaTrabajo / 100);
        let trabajoTramo = Math.ceil(turno.trabajoAsignado / turno.tramosControlProgreso.length);
        turno.tramosControlProgreso.forEach(function(tramo) {
          tramo.trabajoAsignado = trabajoTramo;
        });
      });
      self.$set(self.$refs.rform.formData, "turnos", self.$refs.rform.formData.turnos);
    },
    nuevoTurno: function() {
      var self = this;
      if(!self.$refs.rform.formData.turnos) {
        self.$set(self.$refs.rform.formData, 'turnos', []);
      }
      self.$refs.rform.formData.turnos.push({nombre: '', horaInicio: '', horaFin: '', cargaTrabajo: '', tramos: ''});
    },
    deleteturno: function(index) {
      var self = this;
      var app = self.$f7;
      self.$f7.dialog.confirm(
        self.$t("rlist.pregunta_eliminar_registro"),
        "Eliminar",
        function() {
          self.$refs.rform.formData.turnos.splice(index,1);
        }
      );
    },
    deleteItem: function() {
      var self = this;
      var app = self.$f7;
      app.dialog.confirm(
        self.$t("rlist.pregunta_eliminar_registro"),
        "Eliminar",
        function() {
          JornadaTrabajo.delete({itemId: self.id}).then(function(res) {
            if (res) {
              app.toast
                .create({
                  icon: '<i class="f7-icons">checkmark</i>',
                  text: self.$t("rlist.registro_eliminado"),
                  position: "center",
                  destroyOnClose: true,
                  closeTimeout: 2000
                })
                .open();
              self.$f7router.back()
            }
          }).catch(function(error){
            if(error.message){
              app.dialog.alert(error.message);
            } else {
              app.dialog.alert(error);
            }
          });
        }
      );
    },
    onDropValor: function(arr, dragResult){
      var self = this;
      const { removedIndex, addedIndex, payload } = dragResult;
      if (removedIndex === null && addedIndex === null) return arr;
      if (!arr) arr = [];
      var result = arr;
      let itemToAdd = payload;
      if (removedIndex !== null) {
        itemToAdd = Object.assign({},  result.splice(removedIndex, 1)[0]);
      }
      if (addedIndex !== null) {
        result.splice(addedIndex, 0, Object.assign({}, itemToAdd));
      }
    },
    deleteValor: function(JornadaTrabajo, indexValor){
      lineasPrevisionJornada.trabajo.valores.splice(indexValor,1);
    },
    beforeSave: function(data, resolve, reject) {
      if(data.valores) {
        data.valores.forEach(valor => {
          if(!valor.nombre){
            valor.nombre = valor.valor;
          }
        });
      }
     

      resolve(data);
    },
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.$refs.rform.formData = res;
      self.readonly = true;
      app.toast
        .create({
          text: self.$t("jornada.trabajo.trabajo_guardado"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
    },    
    calcular: function() {
      var self = this;
      var app = self.$f7;
      let params = {
        referencia: self.$refs.rform.formData.referencia
      };
      app.dialog.confirm(self.$t('jornada.trabajo.calcular.message'), self.$t('jornada.trabajo.calcular.title'), () => {
        self.JornadaTrabajo.calcular(params).then((res) => {
          app.preloader.hide();
          self.$refs.rform.formData = res;
        });
      });
    },
    loadItem: function(res) {
      var self = this;
      if(!res.tipo){
        res.tipo = "SIMPLE";
      }
    }
  },
  created: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;
  },
  mounted: function() {
    var self = this;
    let data = self.$refs.rform.formData;
  }
};
</script>