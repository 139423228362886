import RoutesUtils from '../../js/RoutesUtils.js';
import JornadaTrabajoForm from './jornadaTrabajo-form.vue';
import JornadaTrabajo from './jornadaTrabajo.vue';

var routes = [{
        path: '/jornadaTrabajo/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('jornadaTrabajo', ['ADMIN', 'CHECK_MAN'], JornadaTrabajo),
        keepAlive: false
    },
    {
        path: '/jornadaTrabajo/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('jornadaTrabajo/new', ['ADMIN', 'CHECK_MAN'], JornadaTrabajoForm)
    },
    {
        path: '/jornadaTrabajo/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('jornadaTrabajo/edit', ['ADMIN', 'CHECK_MAN'], JornadaTrabajoForm)
    },
    {
        path: '/jornadaTrabajo/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('jornadaTrabajo/view', ['ADMIN', 'CHECK_MAN'], JornadaTrabajoForm, { editable: false })
    },
];
export default routes;