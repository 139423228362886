<template>
  <div class ="rfiles">
    <!-- <div class="rfiles-container margin-horizontal"> -->
    <div v-if="preview" @drop.prevent="onDropOut" :dropZoneActive="activeDrag && !readonly && !form.readonly" :data-active="activeDrag" @dragenter.prevent="activeDrag = true" @dragover.prevent="activeDrag = true" @dragleave.prevent="activeDrag = false">
      <Container
        group-name="group-files"
        orientation="horizontal"
        :contenteditable="!readonly && !form.readonly"
        class="rfiles-container"
        :class="{'dashed': !readonly&&!form.readonly}"
        :animation-duration="1000"
        @drop="onDrop(getValue(), $event)"
        :get-child-payload="getPayload(getValue())"
        :should-accept-drop="(src, payload) => getShouldAcceptDrop(getValue(), src, payload)"
        drag-handle-selector=".column-drag-handle"
      >
        <a v-if="!getValue() || !getValue().length" :contenteditable="false" @paste.prevent="paste" @input.prevent="false" @mousedown.prevent="_ => {return false}">
        </a>
        <Draggable class="rfiles-item" v-for="(item,iaf) in getValue()" :key="'file-'+prefixKey+'-'+iaf">
          <span class="rfiles-close" v-if="!readonly&&!form.readonly" @click="deleteItem(iaf)">
            <f7-icon v-tooltip="'Eliminar'" f7="multiply_circle_fill" />
          </span>
          <a
            @paste.prevent="paste"
            :contenteditable="false"
            @input.prevent="false"
            @mousedown.prevent="_ => {return false}"
            v-if="target === 'cordova' && !ignorarOffline"
            :class="{base64: !!item._base64}"
            :data-name="item.nombre + '.' + item.extension"
            @click.stop="see(item)"
          >
              <img :class="{'column-drag-handle': !readonly&&!form.readonly}" :alt="item.nombre" :style="'height:' + itemHeight + 'px;'" :data-name="item.nombre + '.' + item.extension" v-tooltip="item.nombre" :src="getSrc(item)"/>
          </a>
            <a :contenteditable="false" @paste.prevent="paste" @input.prevent="false" @mousedown.prevent="_ => {return false}" v-else>
            <img
                :alt="item.nombre" 
                :class="{'column-drag-handle': !readonly&&!form.readonly}"
                :style="'height:' + itemHeight + 'px;'"
                :data-name="item.nombre + '.' + item.extension"
                v-tooltip="item.nombre" :src="getSrc(item)"
              @click="download(item)" />
          </a>
        </Draggable>
      </Container>
    </div>
    <div>
    <slot name="add" v-if="!readonly&&!form.readonly&&(!maxFiles||!getValue()||getValue().length<maxFiles)">
      <input :multiple="!maxFiles || maxFiles > 1" type="file" ref="input" style="display:none;" @change="loadFile" />
      <f7-block> 
        <f7-button @click="$refs.input.click()">{{label}}</f7-button>
      </f7-block>
    </slot>
  </div>
  </div>
</template>
<script>

import FileUtils from './../js/FileUtils';
import { Container, Draggable } from "vue-smooth-dnd";

const applyDrag = (arr, dragResult) => {
  const { removedIndex, addedIndex, payload } = dragResult;
  if (removedIndex === null && addedIndex === null) return arr;
  //const result = [...arr];
  if (!arr) arr = [];
  var result = arr;

  let itemToAdd = payload;

  if (removedIndex !== null) {
    itemToAdd = Object.assign({},  result.splice(removedIndex, 1)[0]);
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, Object.assign({}, itemToAdd));
  }

};

export default {
  components: {
    Container,
    Draggable
  },
  data() {
    return {
      self: this,
      target: process.env.TARGET,
      activeDrag: true
    };
  },
  props: {
    name: {
      required: true,
      type: String
    },
    form: {
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: Boolean,
      default: true
    },
    prefixKey: {
      default: ''
    },
    label: {
      default: 'Añadir documento'
    }, 
    maxFiles: {
      type: Number,
      default: undefined
    },
    documentoAdapter: {
      type: Object
    },
    path: {
      type: String,
      required: false
    },
    maxHeight: {
      type: Number,
      default: 1080
    },
    maxWidth: {
      type: Number,
      default: 1920
    },
    ignorarOffline: {
      type: Boolean,
      default: false
    },
    preview: {
      type: Boolean,
      default: true
    },
    itemHeight: {
      type: Number,
      default: 100
    }
  },
  methods: {
    paste(event) {
      const self = this;
      if (event.clipboardData.files && event.clipboardData.files.length) {
        if (!self.readonly && !self.form.readonly) {
          self.loadFile(event.clipboardData.files);
        }
      }
    },
    getPayload(arr) { 
      console.log("getPayload");
      console.log(arr);
      return index => Object.assign({}, arr[index]);
    },
    getShouldAcceptDrop (arr, src, payload) {
      console.log("getShouldAcceptDrop");
      const self = this;
      // return src.groupName == 'group-count' || src.groupName == 'group-panel' || src.groupName == 'group-cuando' || src.groupName == 'group-entonces';
      return true;
    },
    onDrop(arr, dropResult) {
      const self = this;
      console.log("ONDROPPP");
      applyDrag(arr, dropResult);
      self.$forceUpdate();
    },
    onDropOut: function(e) {
      const self = this;
      console.log("DROPPPPP");
      console.log(e);
      if (!self.readonly && !self.form.readonly) {
        self.loadFile(e);
      }
    },
    getValue: function() {
      const self = this;
      var namespaces = self.name.split(".");
      var context = self.form.formData;
      for (var i = 0; i < namespaces.length; i++) {
        if (typeof context == "undefined") {
          let n = namespaces[i];
          if (0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n)) {
            //Número entero positivo
            context = [];
          } else {
            context = {};
          }
        }
        context = context[namespaces[i]];
      }
      if (context && !Array.isArray(context)) {
        context = [context];
      }
      return context;
    },
    append: function(val) {
      var self = this;
      var namespaces = self.name.split(".");
      var context = self.form.formData;
      for (var i = 0; i < namespaces.length; i++) {
        if (i == namespaces.length - 1) {
          if (
            typeof context[namespaces[i]] == "undefined" ||
            !context[namespaces[i]]
          )
            self.$set(context, namespaces[i], []);
          context[namespaces[i]].push(val);
        } else if (typeof context[namespaces[i]] == "undefined") {
          let n = namespaces[i + 1];
          if (0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n)) {
            //Número entero positivo
            self.$set(context, namespaces[i], []);
          } else {
            self.$set(context, namespaces[i], {});
          }
        }
        context = context[namespaces[i]];
      }
    },
    deleteItem: function(index) {
      var self = this;
      var namespaces = self.name.split(".");
      var context = self.form.formData;
      var doc = null;
 
      for (var i = 0; i < namespaces.length; i++) {
        if (i == namespaces.length - 1) {
          if (
            typeof context[namespaces[i]] == "undefined" ||
            !context[namespaces[i]]
          ) {
            self.$set(context, namespaces[i], []);
          }
          if (context[namespaces[i]] && !Array.isArray(context[namespaces[i]])) {
            self.$delete(context, namespaces[i]);
          }  else {
              if (self.target == 'cordova') {
                if (context[namespaces]) {
                  doc = context[namespaces][index];
                } else if (context[namespaces[i]]) {
                  doc = context[namespaces[i]][index];
                }
              }
            self.$delete(context[namespaces[i]], index);
          }
        } else if (typeof context[namespaces[i]] == "undefined") {
          let n = namespaces[i + 1];
          if (0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n)) {
            //Número entero positivo
            self.$set(context, namespaces[i], []);
          } else {
            self.$set(context, namespaces[i], {});
          }
        }
        context = context[namespaces[i]];
      }
      if (doc != null && !!doc.alreadySaved) {
        FileUtils.deleteFile("documentos/"+ doc.nombre + "." + doc.extension,
          (a) => {
            console.log(a);
            },
          (e) => {
            console.log(e);
            }
          );
      }
    },
    loadFile: function(event) {
      var self = this;
      var app = self.$f7;
      let files = [];
      if (event instanceof FileList || Array.isArray(event)) {
        files = event;
      } else {
        files = event.target && event.target.files ? event.target.files : (event.dataTransfer && event.dataTransfer.files ? event.dataTransfer.files : [])
      }
      var value = self.getValue();
      if (self.maxFiles && (files.length + (value && value != null ? self.getValue().length : 0)) > self.maxFiles) {
        app.dialog.alert("El número máximo de ficheros a añadir es " + self.maxFiles);
        self.$refs.input.value = "";
        return false;
      }
      var filesLoaded = [];
      var inputFilesLength = files.length;
      Array.from(files).forEach(file => {
        var reader = new FileReader();
        reader.onload = function(e) {
          var fileLoaded = {
            nombre: file.name
              .split(".")
              .slice(0, -1)
              .join("."),
            extension: /(?:\.([^.]+))?$/.exec(file.name)[1], //file.type.split("/")[1],
            maxHeight: self.maxHeight,
            maxWidth: self.maxWidth,
            _base64: e.target.result,
            _path: self.path,
          };
          self.append(fileLoaded);
          filesLoaded.push(fileLoaded);
          app.preloader.hide();
          self.$emit("loadFile", fileLoaded);
          if (filesLoaded.length == inputFilesLength) {
            self.$emit("loadFiles", filesLoaded);
          }
        };
        reader.readAsDataURL(file);
        app.preloader.show();
      });
      self.$refs.input.value = "";
    },
    getSrc(item) {
      var self = this;
      var app = self.$f7;
      switch (item.extension.toLowerCase()) {
        case "pdf":
          return "static/rfiles/pdf.png";
          break;
        case "doc":
        case "docx":
          return "static/rfiles/doc.png";
          break;
        case "xls":
        case "xlsx":
          return "static/rfiles/xls.png";
          break;
        case "png":
        case "jpg":
        case "jpeg":
        case "gif":
          if (!item._base64 && self.target != 'cordova' && !item.cargando) {
            item.cargando = true;
            self.documentoAdapter.getDocumento(item).then(function(data){
              console.log("RECIBIDO DOC");
              var blob = new Blob([data]);
              var reader = new FileReader();
              reader.readAsDataURL(blob); 
              reader.onloadend = function() {
                console.log("DOC END");
                var base64data = reader.result;                
                self.$set(item, '_base64', base64data);
                self.$forceUpdate();
              }
            });
          }
          if (self.target == 'cordova') {
            return item._base64
              ? item._base64
              : app.data.appDataPath +
                  "/documentos/" +
                  item.nombre +
                  "." +
                  item.extension;
          } else {
            return item._base64
              ? item._base64
              : "static/rfiles/attachment-icon.png" ;
          }
          break;
        case "mp4":
        case "mkv":
        case "vob":
        case "avi":
        case "mov":
          return "static/rfiles/video.png";
          break;
        case "m4a":
          return "static/rfiles/audio-icon.png";
          break;
        default:
          return "static/rfiles/attachment-icon.png";
      }
    },
    download(item) {
      var self = this;
      if (self.target == 'web' || self.ignorarOffline) {
        self.documentoAdapter.download(item);
      }
    },
    see(item) {
      var self = this;
      if (!item._path && item.path) {
        item._path = self.$f7.data.appDataPath + '/documentos/' + item.nombre + '.' + item.extension;
      }
      if (item._path) {
        if (!item._path.startsWith('cdvfile:')) {
          if (['jpg', 'jpeg', 'png', 'gif'].includes(item.extension)) {
            PhotoViewer.show(item._path ? item._path : self.$f7.data.appDataPath + '/documentos/' + item.nombre + '.' + item.extension);
          } else if (['mp4', 'mkv', 'vob', 'avi', 'mov'].includes(item.extension)) {
            VideoPlayer.play(item._path ? item._path : self.$f7.data.appDataPath + '/documentos/' + item.nombre + '.' + item.extension);
          } else if (['m4a', 'wma', 'mp3'].includes(item.extension)) {
            var media = new Media(item._path ? item._path : self.$f7.data.appDataPath + '/documentos/' + item.nombre + '.' + item.extension);
            media.play();
          } else {
            cordova.plugins.fileOpener2.open(item._path, item._type, {onError, success : function(){ console.log("Abierto") }});
          }
        } else {
          cordova.plugins.fileOpener2.open(item._path, item._type, {onError, success : function(){ console.log("Abierto") }});
        }
        // window.open(item._path, "_blank");
      // } else if (item._base64) {
      } else {

        var onError = function (e) {
          console.log("ERROR");
          console.log(e);
        }
        var blob = FileUtils.b64toBlob(item._base64.split(",")[1]);
        FileUtils.saveBlobFile(blob, item.nombre, item.extension, '/documentos/', res => {
          res.fileEntry.file(function (file) {
            item._path = file.localURL || 'MALO';
            item._type = file.type;
            item.nombre = res.nombre;
            item.alreadySaved = res.alreadySaved;
            item.extension = res.extension;
            delete item._base64;
            self.see(item);
            // window.open(item._path, "_blank");
          }, onError);
        }, onError);

        // var baseDir = typeof eapp != "undefined" ? eapp.getPath("userData") : "";
        // var dir = baseDir + '/documentos/';
        // var filePath = item.nombre + '.' + item.extension;
        // var onError = function (e) {
        //   console.log("ERROR");
        //   console.log(e);
        // }
        // window.requestFileSystem(LocalFileSystem.PERSISTENT, 0, function (fs) {
        //   fs.root.getDirectory(dir, {
        //     create: false
        //   }, function (dirEntry) {
        //     dirEntry.getFile(filePath, { create: false }, function (fileEntry) {
        //       fileEntry.file(function (file) {
        //         item._path = file.localURL;
        //         item._type = file.type;
        //         cordova.plugins.fileOpener2.open(item._path, item._type, {onError, success : function(){ console.log("Abierto") }});
        //         // window.open(item._path, "_blank");
        //       }, onError);
        //     }, onError);
        //   }, onError);
        // }, onError);

        // var data = FileUtils.b64toData(item._base64.split(',')[1]);
        // FileUtils.openFile(data, item.nombre + '.' + item.extension, function() {}, function() {});
      } 
      // else {
      //   // cordova.plugins.fileOpener2.open(item._path, item._type, {onError, success : function(){ resolve(); }});
      //   // window.open(item._path, "_blank");
      // }
    }
  },
  mounted: function() {
    var self = this;
    var app = self.$f7;
  }
};
</script>
<style>
.rfiles .rfiles-container {
  min-height: 50px;
  display: flex !important;
  width: 99%;
}
.rfiles .rfiles-container.dashed {
  border: 2px dashed rgb(175, 175, 175);
  border-radius: 5px;
}
.rfiles-item {
  cursor: pointer;
  position: relative;
  margin: 3px;
}
.rfiles-item a {
  display: block;
}
.rfiles-close {
  position: absolute;
  right: -5px;
  top: 0;
  background: white;
  border-radius: 50%;
}
</style>
