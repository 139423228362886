import DatabaseModel from './DatabaseModel.js';
import CacheService from './Cache.js';

var Gamas = Object.create(DatabaseModel);

Gamas.model = 'gama';
Gamas.modelFront = 'gama';

Gamas.importar = function(documento) {
    var self = this;
    var params = {
        url: self.server_zipado + self.prefix + self.model + '/importar/',
        method: 'POST',
        data: documento
    };
    return self.sendRequest(params, false).then((res) => {
        CacheService.clearCache(self.model);
        return res;
    }).catch((error) => {
        var message = typeof error == "string" ? error : error.message;
        app.dialog.alert(message);
    });
}

Gamas.cerrar = function(params) {
    var self = this;
    params = params || {};
    if (!params.itemId) reject({ error: 'itemId not defined' });
    var params = {
        url: self.server_zipado + self.prefix + self.model + '/cerrar/' + params.itemId + '?comprobarbl=' + params.comprobarbl,
        method: 'GET',
        data: params.data
    };
    return self.sendRequest(params, false).then(function(res) {
        CacheService.clearCache(self.model);
        return res;
    });
}

Gamas.abrir = function(params) {
    var self = this;
    params = params || {};
    if (!params.itemId) reject({ error: 'itemId not defined' });
    var params = {
        url: self.server_zipado + self.prefix + self.model + '/abrir/' + params.itemId,
        method: 'GET',
        data: params.data
    };
    return self.sendRequest(params, false).then(function(res) {
        CacheService.clearCache(self.model);
        return res;
    });
}

export default Gamas;