<template>
  <r-page :page-content="true">
    <f7-navbar :back-link="$t('atras')">
      <!-- <f7-nav-title v-if="id && !readonly" sliding>{{$t('selecciones.editar')}}</f7-nav-title> -->
      <f7-nav-title v-if="id" sliding>{{$t('selecciones.detalle.title') + ' ' +  ($refs.rform && $refs.rform.formData && $refs.rform.formData.madre?$refs.rform.formData.madre.etiqueta:'')}}</f7-nav-title>
      <f7-nav-title v-else sliding>{{$t('selecciones.nuevo')}}</f7-nav-title>
      <f7-nav-right>
        <f7-button
          v-if="!readonly"
          fill
          icon-f7="floppy_disk"
          text="Guardar"
          class="display-flex"
          @click="$refs.rform.saveItem()"
        ></f7-button>
        <template slot="right" v-else>
          <f7-button v-roles="{route: $f7route.url, roles: ['ADMIN']}" @click="readonly=false">{{$t('common.editar')}}</f7-button>
          <f7-button v-roles="{route: $f7route.url, roles: ['ADMIN']}" @click="deleteItem">{{$t('common.eliminar')}}</f7-button>
        </template>
      </f7-nav-right>
    </f7-navbar>
    <r-form
      ref="rform"
      v-slot="{rform}"
      :itemId="id"
      :beforeSave="beforeSave"
      @save="onSave"
      @loadItem="loadItem"
      :readonly="readonly"
      :dbAdapter="Selecciones"
      :defaultData="{valores: [], tipo: 'SIMPLE', enVehiculo: false, abierta: true, madre: {hijos:[]}, galia: {}}"
    >
      <f7-card class="padding-vertical-half padding-right">
        <f7-card-content>
          <f7-row no-gap class="list list-form no-hairlines" style="z-index:10;">
            <!-- Datos de la gama -->
            <f7-card outline class="col-50 medium-50 small-100" fill style="background-color: #FFECB3;z-index:10;">
              <f7-row no-gap class="list list-form no-hairlines">
                <r-autocomplete
                  class="col-100 medium-15 small-100"
                  :dbAdapter="Gamas"
                  floating-label 
                  :label="$t('selecciones.numeroGama')"
                  :form="rform"
                  name="gama"
                  :shownFields="['categoria','denominacion','referencias','listadoDefectos','zonasPosibles']"
                  :idProp="null"
                  labelProp="numero"
                  @change="changeGama"
                ></r-autocomplete> 
                <r-input
                    floating-label
                    :label="$t('selecciones.categoria')"
                    :form="rform"
                    type="badge"
                    name="gama.categoria"
                    :readonly="true"
                    :overrideReadonly="true"
                    :text="getTextoBadgeCategoria(rform && rform.formData && rform.formData.gama && rform.formData.gama.categoria?rform.formData.gama.categoria:'')"
                    :color="getColorBadgeCategoria(rform && rform.formData && rform.formData.gama && rform.formData.gama.categoria?rform.formData.gama.categoria:'')"
                ></r-input>
                <r-input
                  floating-label
                  :label="$t('selecciones.detalle.denominacion')"
                  :form="rform"
                  type="text"
                  name="gama.denominacion"
                  :readonly="true"
                  :overrideReadonly="true"
                ></r-input>
                <r-input
                  class="col-100 medium-30 small-100"
                  floating-label
                  :label="$t('gamas.referencias')"
                  :form="rform"
                  type="text"
                  name="gama.referencias"
                  :readonly="true"
                  :overrideReadonly="true"
                ></r-input>
                <r-input
                  class="col-100 medium-100 small-100"
                  floating-label
                  :label="$t('Zonas posibles') "
                  :form="rform"
                  type="text"
                  name="gama.zonasPosibles"
                  :readonly="true"
                  :overrideReadonly="true"
                ></r-input>
              </f7-row>
            </f7-card>
            <!-- datos generales de la UM -->
            <!-- <r-input
              class="col-10 medium-10 small-100"
              :label="$t('selecciones.referencia')"
              :form="rform"
              name="referencia"
              type="select"
              :clear-button="false"
              @change="cambiarReferencia"
            >
              <option
                v-for="referencia in referenciasGama"
                :key="'referencia.opcion.' + referencia"
                :value="referencia.trim()"
              >{{referencia.trim()}}</option>
            </r-input> -->


            <r-input
              v-if="referenciasGama && readonly"
              class="col-10"
              floating-label
              :label="$t('selecciones.referencia')"
              :form="rform"
              name="referencia"
              type="text"
            />
            <r-input
              v-else-if="rform && rform.formData && rform.formData.gama && rform.formData.gama.numero"
              floating-label
              :label="$t('selecciones.referencia')"
              :form="rform"
              name="referencia"
              type="select"
              :clear-button="false"
              @change="cambiarReferencia"
            >
              <option selected></option>
              <option 
                v-for="referencia in referenciasGama"
                :key="'referencia.opcion.' + referencia"
                :value="referencia.trim()"
              >{{referencia.trim()}}</option>
            </r-input>



            <r-input
              v-if="!rform.formData.enVehiculo"
              class="col-10 medium-10 small-100"
              floating-label
              :label="$t('selecciones.bl')"
              :form="rform"
              type="text"
              name="bl"
            ></r-input>
            <!-- <r-input
              v-else
              class="col-10 medium-10 small-100"
              floating-label
              :label="$t('selecciones.nOrden')"
              :form="rform"
              type="text"
              name="bl"
            ></r-input> -->
            <r-input
              class="col-10 medium-10 small-100"
              floating-label
              :label="$t('selecciones.fecha_apertura')"
              :form="rform"
              name="fechaApertura"
              type="datetime-local"
              :readonly="readonly"
              :overrideReadonly="true"
              :calendar-params="{timePickerPlaceholder:'Seleccionar hora', toolbarCloseText:'Hecho',closeOnSelect:true,timePicker: true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit', hour:'2-digit',minute:'2-digit' } }"
              :ignorePreviousVal="true"
              :formatDate="'DD/MM/YYYY HH:mm'"
            ></r-input>
            <!-- <r-input
              class="col-10 medium-10 small-100"
              floating-label
              :label="$t('selecciones.fecha_cierre')"
              :form="rform"
              type="datepicker"
              name="fechaCierre"
              :readonly="readonly"
              :overrideReadonly="true"
              :calendar-params="{timePickerPlaceholder:'Seleccionar hora', toolbarCloseText:'Hecho',closeOnSelect:true,timePicker: true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit', hour:'2-digit',minute:'2-digit' } }"
              :ignorePreviousVal="true"
            ></r-input> -->
            <r-input
              class="col-10 medium-10 small-100"
              floating-label
              :label="$t('selecciones.fecha_cierre')"
              :form="rform"
              type="datetime-local"
              name="fechaCierre"
              :readonly="readonly"
              :overrideReadonly="true"
              :calendar-params="{timePickerPlaceholder:'Seleccionar hora', toolbarCloseText:'Hecho',closeOnSelect:true,timePicker: true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit', hour:'2-digit',minute:'2-digit' } }"
              :ignorePreviousVal="true"
              :formatDate="'DD/MM/YYYY HH:mm'"
            ></r-input>
              <!-- :calendar-params="{closeOnSelect: true,routableModals: false,dateFormat: {year:'numeric', month: '2-digit', day: '2-digit'}}" -->
            <r-input
              class="col-5 medium-5 small-50"
              floating-label
              :label="rform.formData.abierta?$t('selecciones.abierta'):$t('selecciones.cerrada')"
              :form="rform"
              type="check"
              name="abierta"
              @change="onChangeEstado"
            ></r-input>
          </f7-row>
          <f7-row no-gap class="list list-form ">
            <r-input
              class="col-10 medium-10 small-100"
              floating-label
              :label="$t('selecciones.enVehiculo') + (rform.formData.enVehiculo?' - SI':' - NO')"
              :form="rform"
              type="check"
              name="enVehiculo"
              :readonly="readonly || (rform.formData.madre && rform.formData.madre.hijos && rform.formData.madre.hijos.length > 0)"
              :overrideReadonly="true"
            ></r-input>
            <r-input
              class="col-20 medium-20 small-100"
              floating-label
              :label="$t('selecciones.fase')"
              :form="rform"
              type="select"
              :clear-button="false"
              name="fase"
            >
              <option selected></option>
              <option :key="'INICIO'" :value="'INICIO'">INICIO</option>
              <option :key="'ENTRADA Z. TRANSFERENCIA'" :value="'ENTRADA Z. TRANSFERENCIA'">ENTRADA Z. TRANSFERENCIA</option>
              <option :key="'SALIDA Z. TRANSFERENCIA'" :value="'SALIDA Z. TRANSFERENCIA'">SALIDA Z. TRANSFERENCIA</option>
              <option :key="'FIN'" :value="'FIN'">FIN</option>
            </r-input>
            <f7-link 
            ></f7-link>
            <f7-button
              v-if="!readonly && !rform.formData.abierta"
              fill
              v-tooltip="$t('selecciones.reabrir')" 
              text="Reabrir"
              icon-f7="" color="green" 
              class="display-flex"
              style="vertical-align: top;"
              @click.prevent="reabrir"
            ></f7-button>
            <f7-button
              v-if="rform.formData.alertas"
              fill
              v-tooltip="$t('selecciones.alertas')" 
              text="Mostrar alertas"
              icon-f7="bell" color="red" 
              class="display-flex"
              @click.prevent="popupAlertasOpened = true"
            ></f7-button>
            <f7-popup v-if="popupAlertasOpened" ref="popupAlertas" class="popup-alertas"  :opened="true" @popup:closed="popupAlertasOpened = false" swipe-to-close>
              <div>
                <f7-card outline class="col-100" style="background-color: #F0F4C3;" v-for="(alerta,index) in $refs.rform.formData.alertas" :key="'alertas.' + index">
                  <div no-gap class="list list-form no-hairlines">
                    <r-input
                      class="col-10 medium-10 small-100"
                      floating-label
                      :label="$t('alertas.fecha')"
                      :form="rform"
                      type="datepicker"
                      :name="'alertas.'+index+'.fecha'"
                      :calendar-params="{
                        closeOnSelect: true,
                        routableModals: false,
                        dateFormat: {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                        },
                      }"
                      :readonly="true"
                      :overrideReadonly="true"
                    />
                    <r-input
                      class="col-40 medium-40 small-100"
                      floating-label
                      :label="$t('alertas.mensaje')"
                      :form="rform"
                      type="text"
                      :name="'alertas.'+index+'.mensaje'"
                      :readonly="true"
                      :overrideReadonly="true"
                    ></r-input>
                    <r-input
                      class="col-40 medium-40 small-100"
                      floating-label
                      :label="$t('alertas.motivo')"
                      :form="rform"
                      type="text"
                      :name="'alertas.'+index+'.motivo'"
                      :readonly="true"
                      :overrideReadonly="true"
                    ></r-input>
                  </div>
                </f7-card>
              </div>
            </f7-popup>
          </f7-row>
          <br/>
          <f7-row v-if="!rform.formData.enVehiculo" no-gap class="list list-form no-hairlines"  style="z-index:9;">
            <f7-card outline class="col-100" style="background-color: #EEEEEE;">
              <f7-row no-gap class="list list-form no-hairlines">
                <r-input
                  style="width:9em;"
                  floating-label
                  :label="$t('selecciones.etiquetaGalia')"
                  :form="rform"
                  type="text"
                  name="galia.etiqueta"
                  @change="cambiarReferencia(rform.formData.referencia)"
                ></r-input>
                <r-input
                  v-if="rform.formData && rform.formData.galia && rform.formData.galia.etiqueta && referenciasGama && !readonly && rform && rform.formData && rform.formData.gama && rform.formData.gama.numero"
                  floating-label
                  :label="$t('selecciones.referencia')"
                  :form="rform"
                  name="galia.referencia"
                  type="select"
                  :clear-button="false"
                  @change="mostrarAviso('Si cambias esta referencia, el cambio NO se propagará al resto de referencias de la UM.')"
                >
                  <option selected></option>
                  <option 
                    v-for="referencia in referenciasGama"
                    :key="'galia.referencia.opcion.' + referencia"
                    :value="referencia.trim()"
                  >{{referencia.trim()}}</option>
                </r-input>
                <r-input
                  v-else-if="rform.formData && rform.formData.galia && rform.formData.galia.etiqueta"
                  class="col-10"
                  floating-label
                  :label="$t('selecciones.referencia')"
                  :form="rform"
                  name="galia.referencia"
                  type="text"
                />
                <r-input
                  v-if="rform.formData && rform.formData.galia && rform.formData.galia.etiqueta"
                  class="col-10"
                  floating-label
                  :label="$t('selecciones.poe')"
                  :form="rform"
                  type="text"
                  name="galia.poe"
                ></r-input>
                <div class="col-60"/>
              </f7-row>
            </f7-card>
          </f7-row>
          <f7-row v-if="!rform.formData.enVehiculo" no-gap class="list list-form no-hairlines" style="z-index:8;">
            <f7-card outline class="col-100" style="background-color: #F0F4C3;">
              <f7-row no-gap class="list list-form no-hairlines">
                <r-input
                  style="width:9em;"
                  floating-label
                  :label="$t('selecciones.etiquetaMadre')"
                  :form="rform"
                  type="text"
                  name="madre.etiqueta"
                ></r-input>
                <r-input
                  v-if="referenciasGama && !readonly && rform && rform.formData && rform.formData.gama && rform.formData.gama.numero"
                  floating-label
                  :label=" $t('selecciones.referencia') "
                  :form=" rform "
                  name="madre.referencia"
                  type="select"
                  :clear-button=" false "
                  @change="mostrarAviso('Si cambias esta referencia, el cambio NO se propagará al resto de referencias de la UM.')"
                >
                  <option selected></option>
                  <option 
                    v-for="referencia in referenciasGama"
                    :key="'madre.referencia.opcion.' + referencia"
                    :value="referencia.trim()"
                  >{{referencia.trim()}}</option>
                </r-input>
                <r-input
                  v-else
                  class="col-10"
                  floating-label
                  :label="$t('selecciones.referencia')"
                  :form="rform"
                  name="madre.referencia"
                  type="text"
                />

                <r-input
                  style="width:9em;"
                  floating-label
                  :label="$t('selecciones.dateLabel')"
                  :form="rform"
                  type="text"
                  name="madre.dateLabel"
                ></r-input>
                <!-- <r-input
                  class="col-10"
                  floating-label
                  :label="$t('selecciones.bl')"
                  :form="rform"
                  type="text"
                  name="madre.bl"
                ></r-input> -->
                <r-input
                  v-if="rform && rform.formData && rform.formData.gama && rform.formData.gama.numero"
                  class="col-10"
                  floating-label
                  :label="$t('selecciones.ubicacion')"
                  :form="rform"
                  type="select"
                  name="madre.ubicacion"
                  :clear-button="false"
                >
                  <option></option>
                  <option 
                    v-for="ubicacion in rform.formData && rform.formData.gama && rform.formData.gama.zonasPosibles ? rform.formData.gama.zonasPosibles.map(z => z.trim()) : []"
                    :key="'madre.ubicacion.opcion.' + ubicacion"
                    :value="ubicacion.trim()"
                  >{{ ubicacion.trim() }}</option>
                </r-input>
                <r-input
                  style="width:7em;"
                  floating-label
                  :label="$t('selecciones.detalle.codOperario')"
                  :form="rform"
                  type="text"
                  :name="'madre.codOperario'"
                ></r-input>
                <r-input
                  style="width:7em;"
                  floating-label
                  :label="$t('selecciones.detalle.materiales')"
                  :form="rform"
                  type="text"
                  name="madre.material"
                ></r-input>
                <r-input
                  style="width:7em;"
                  floating-label
                  :label="$t('selecciones.detalle.envios')"
                  :form="rform"
                  type="text"
                  name="madre.envios"
                ></r-input>
                <r-input
                  style="width:7em;"
                  floating-label
                  :label="$t('selecciones.detalle.numPiezas')"
                  :form="rform"
                  type="text"
                  name="madre.numPiezas"
                  @change="recalcular();"
                ></r-input>
                <r-input
                  style="width:9em;"
                  floating-label
                  :label="$t('selecciones.detalle.numPiezasTotal')"
                  :form="rform"
                  type="text"
                  name="madre.numPiezasTotal"
                  @change="recalcular();"
                ></r-input>
                <r-input
                  style="width:7em;"
                  floating-label
                  :label="$t('selecciones.detalle.numOK')"
                  :form="rform"
                  type="text"
                  name="madre.numOK"
                  @change="recalcular();"
                ></r-input>
                <r-input
                  style="width:7em;"
                  floating-label
                  :label="$t('selecciones.detalle.numNOK')"
                  :form="rform"
                  type="text"
                  name="madre.numNOK"
                  @change="recalcular();"
                ></r-input>
                <r-input
                  style="width:9em;"
                  floating-label
                  :label="$t('selecciones.detalle.recuperadas')"
                  :form="rform"
                  type="text"
                  name="madre.recuperadas"
                  @change="recalcular();"
                ></r-input>
              </f7-row>
              <!-- poner botón de añadir línea de detalle de revisión de operario -->
              <div v-if="!rform.readonly || (rform.formData && rform.formData.madre && rform.formData.madre.hijos && rform.formData.madre.hijos.length > 0)">
                <div style="display: inline-flex;padding:10px 50px 5px 10px;">
                  <b>{{$t('selecciones.detalle.trabajos')}}</b>
                </div>
                <f7-link
                  v-if="!rform.readonly"
                  slot="right"
                  @click="nuevaLinea(null)"
                  icon-f7="plus_app_fill"
                ></f7-link>
              </div>
              <div v-if="rform && rform.formData && rform.formData.madre && rform.formData.madre.hijos">
                <f7-row 
                  no-gap 
                  class="list list-form no-hairlines"
                  v-for="(item,index) in rform.formData.madre.hijos" 
                  :key="'madre.hijos.' + index" 
                >
                  <f7-card :class="{'col-100 elevation-3 padding-vertical-half padding-right':true,'bg-color-yellow':item.tipo=='PIEZA','bg-color-blue':item.tipo=='BAC'}">
                    <f7-row no-gap class="list list-form no-hairlines">
                      <r-input
                        class="col-10"
                        floating-label
                        :label="$t('selecciones.detalle.tipo')" 
                        :form="rform"
                        type="select"
                        :clear-button="false"
                        :name="'madre.hijos.' + index + '.tipo'"
                      >
                        <option selected value=""></option>
                        <option selected value="BAC">BAC</option>
                        <option selected value="PIEZA">PIEZA</option>
                      </r-input>
                      <r-input
                        class="col-10 medium-10 small-100"
                        floating-label
                        :label="$t('selecciones.detalle.fechajornada')"
                        :form="rform"
                        type="datepicker"
                        :name="'madre.hijos.' + index + '.fechaJornada'"
                        :calendar-params="{
                            closeOnSelect: true,
                            routableModals: false,
                            dateFormat: {
                              year: 'numeric',
                              month: '2-digit',
                              day: '2-digit',
                            },
                          }"
                      ></r-input>
                      <r-input
                        class="col-10"
                        floating-label
                        :label="$t('selecciones.etiqueta')"
                        :form="rform"
                        type="text"
                        :name="'madre.hijos.' + index + '.etiqueta'"
                      ></r-input>
                      <r-input
                        v-if="item.dateLabel"
                        class="col-10"
                        floating-label
                        :label="$t('selecciones.dateLabel')"
                        :form="rform"
                        type="text"
                        :name="'madre.hijos.' + index + '.dateLabel'"
                      ></r-input>
                      <r-input
                        v-if="item.ubicacion"
                        class="col-10"
                        floating-label
                        :label="$t('selecciones.ubicacion')"
                        :form="rform"
                        type="text"
                        :name="'madre.hijos.' + index + '.ubicacion'"
                      ></r-input>
                      <r-input
                        class="col-10"
                        floating-label
                        :label="$t('selecciones.detalle.codOperario')"
                        :form="rform"
                        type="text"
                        :name="'madre.hijos.' + index + '.codOperario'"
                      ></r-input>
                      <r-input
                        v-if="item.tipo != 'PIEZA'"
                        class="col-10"
                        floating-label
                        :label="$t('selecciones.detalle.numPiezas')"
                        :form="rform"
                        type="text"
                        :name="'madre.hijos.' + index + '.numPiezas'"
                        @change="recalcular();"
                      ></r-input>
                      <r-input
                        v-if="item.tipo != 'PIEZA'"
                        class="col-10"
                        floating-label
                        :label="$t('selecciones.detalle.numPiezasTotal')"
                        :form="rform"
                        type="text"
                        :name="'madre.hijos.' + index + '.numPiezasTotal'"
                        @change="recalcular();"
                      ></r-input>
                      <r-input
                        v-if="item.tipo != 'PIEZA'"
                        class="col-10"
                        floating-label
                        :label="$t('selecciones.detalle.numOK')"
                        :form="rform"
                        type="text"
                        :name="'madre.hijos.' + index + '.numOK'"
                        @change="recalcular();"
                      ></r-input>
                      <r-input
                        v-if="item.tipo == 'BAC'"
                        class="col-10"
                        floating-label
                        :label="$t('selecciones.detalle.numNOK')"
                        :form="rform"
                        type="text"
                        :name="'madre.hijos.' + index + '.numNOK'"
                        @click="sumaEnPadre(rform.formData.madre,'numNOK')"
                        :value="item.hijos?sumaEnPadre(rform.formData.madre,'numNOK'):item.numNOK"
                        @change="recalcular();"
                      ></r-input>
                      <r-input
                        v-if="item.tipo == 'PIEZA'"
                        class="col-10"
                        floating-label
                        :label="$t('selecciones.detalle.estado')"
                        :form="rform"
                        type="badge"
                        :name="'madre.hijos.' + index + '.numNOK'"
                        :readonly="true"
                        :overrideReadonly="true"
                        :text="item.numNOK == 1?'DEFECTUOSA':item.recuperadas == 1?'RECUPERADA':(item.numOK == 1?'CORRECTA':'SIN MARCAR')"
                        :color="item.numNOK == 1?'red':item.numOK == 1?'green':(item.recuperadas == 1?'teal':'gray')"
                        :decorationConfig="readonly?{}:{
                          icon: item.numNOK == 1?'hand_thumbsup':(item.recuperadas == 1?'hand_thumbsup':'hand_thumbsdown'),
                          color: item.numNOK == 1? 'teal':(item.numOK == 1? 'red':'green'),
                          tooltip: item.numOK == 1? 'Marcar como Defectuosa':(item.numNOK == 1? 'Marcar como recuperada':'Marcar como Correcta'),
                          action: function() {
                            if(item.numOK == 1) {item.numOK = 0;item.numNOK = 1;item.recuperadas = 0;}
                            else if(item.numNOK == 1) {item.numOK = 0;item.numNOK = 0;item.recuperadas = 1;}
                            else if(item.recuperadas == 1) {item.numOK = 1;item.numNOK = 0;item.recuperadas = 0;}
                            else {item.numOK = 1;item.numNOK = 0;item.recuperadas = 0;}
                            recalcular();}
                        }"
                      ></r-input>
                      <r-input
                        v-if="item.tipo == 'PIEZA' && item.numNOK && rform.formData.gama.listadoDefectos"
                        class="col-10"
                        floating-label
                        :label="$t('selecciones.detalle.defecto')"
                        :form="rform"
                        :name="'madre.hijos.' + index + '.defecto'"
                        type="select"
                        :clear-button="false"
                      >
                        <option selected value=""></option>
                        <option 
                          v-for="defecto in rform.formData.gama.listadoDefectos.split(';')"
                          :key="'madre.hijos.' + index + '.defecto.opcion.' + defecto"
                          :value="defecto.trim()"
                        >{{defecto.trim()}}</option>
                        <option value="OTROS">OTROS</option>
                      </r-input>
                      <!-- <r-input
                        v-if="item.tipo == 'PIEZA' && item.numNOK && !rform.formData.gama.listadoDefectos.includes(item.defecto) && item.defecto"
                        class="col-10"
                        floating-label
                        :label="$t('selecciones.detalle.defecto')"
                        :form="rform"
                        :name="'madre.hijos.' + index + '.defecto'"
                        type="text"
                      ></r-input> -->
                      <r-input
                        v-if="item.tipo == 'PIEZA' && item.numNOK && !rform.formData.gama.listadoDefectos"
                        class="col-10"
                        floating-label
                        :label="$t('selecciones.detalle.defecto')"
                        :form="rform"
                        :name="'madre.hijos.' + index + '.defecto'"
                        type="text"
                      ></r-input>
                      <r-input
                        v-if="item.tipo != 'PIEZA'"
                        class="col-10"
                        floating-label
                        :label="$t('selecciones.detalle.recuperadas')"
                        :form="rform"
                        type="text"
                        :name="'madre.hijos.' + index + '.recuperadas'"
                        @change="recalcular();"
                      ></r-input>
                      <div style="position: absolute; top: 0px; right: 0px;">
                        <f7-link v-if="item.hijos" v-tooltip="$t(!item.mostrarHijos?'common.mostrar':'common.ocultar')" :icon-f7="!item.mostrarHijos?'chevron_down':'chevron_up'" color="yellow" @click="$set(item, 'mostrarHijos', !item.mostrarHijos)"></f7-link>
                        <f7-link v-if="!readonly" v-tooltip="$t('common.eliminar')" icon-f7="trash" color="red" @click="deleteLinea(rform.formData.madre.hijos,index);"></f7-link>
                        <f7-link 
                          v-if="!rform.readonly && item.tipo == 'BAC'" 
                          v-tooltip="$t('common.nueva')+'algo'" 
                          @click="nuevaPieza(item,'PIEZA');" 
                          icon-f7="plus_app_fill"
                        ></f7-link>
                        <f7-link
                          v-if="!rform.readonly"
                          v-tooltip="$t('common.duplicar')" 
                          slot="right"
                          @click="duplicaLinea(item,rform.formData.madre)"
                          icon-f7="doc_on_doc_fill"
                        ></f7-link>
                      </div>
                    </f7-row>
                  </f7-card>
                  <div v-if="item.hijos && item.mostrarHijos" class="col-100" style="padding-left:10px;" >
                    <f7-row 
                      no-gap 
                      class="list list-form no-hairlines"
                      v-for="(item2,index2) in item.hijos" 
                      :key="'hijos2-' + index2" 
                    >
                      <f7-card :class="{'col-100 elevation-3 padding-vertical-half padding-right':true,'bg-color-yellow':item2.tipo=='PIEZA','bg-color-blue':item2.tipo=='BAC'}">
                        <f7-row no-gap class="list list-form no-hairlines">
                          <r-input
                            class="col-10"
                            floating-label
                            :label="$t('selecciones.detalle.tipo')" 
                            :form="rform"
                            type="text"
                            :name="'madre.hijos.' + index + '.hijos.' + index2 + '.tipo'"
                          ></r-input>
                          <r-input
                            class="col-10 medium-10 small-100"
                            floating-label
                            :label="$t('selecciones.detalle.fechajornada')"
                            :form="rform"
                            type="datepicker"
                            :name="'madre.hijos.' + index + '.hijos.' + index2 + '.fechaJornada'"
                            :calendar-params="{
                              closeOnSelect: true,
                              routableModals: false,
                              dateFormat: {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                              },
                            }"
                          ></r-input>
                          <r-input
                            :class="{'col-20':item2.tipo=='PIEZA','col-10':item2.tipo=='BAC'}"
                            floating-label
                            :label="$t('selecciones.etiqueta') + 'PEPE'"
                            :form="rform"
                            type="text"
                            :name="'madre.hijos.' + index + '.hijos.' + index2 + '.etiqueta'"
                          ></r-input>
                          <r-input
                            v-if="item2.ubicacion"
                            class="col-10"
                            floating-label
                            :label="$t('selecciones.ubicacion')"
                            :form="rform"
                            type="text"
                            :name="'madre.hijos.' + index + '.hijos.' + index2 + '.ubicacion'"
                          ></r-input>
                          <r-input
                            class="col-10"
                            floating-label
                            :label="$t('selecciones.detalle.codOperario')"
                            :form="rform"
                            type="number"
                            :name="'madre.hijos.' + index + '.hijos.' + index2 + '.codOperario'"
                          ></r-input>
                          <r-input
                            v-if="item2.tipo == 'BAC'"
                            class="col-10"
                            floating-label
                            :label="$t('selecciones.detalle.numNOK')"
                            :form="rform"
                            type="number"
                            :name="'madre.hijos.' + index + '.hijos.' + index2 + '.numNOK'"
                          ></r-input>







                          <r-input
                            v-if="item2.tipo == 'PIEZA'"
                            class="col-10"
                            floating-label
                            :label="$t('selecciones.detalle.estado')"
                            :form="rform"
                            type="badge"
                            :name="'madre.hijos.' + index + '.hijos.' + index2 + '.numNOK'"
                            :readonly="true"
                            :overrideReadonly="true"
                            :text="item2.numNOK == 1?'DEFECTUOSA':item2.recuperadas == 1?'RECUPERADA':(item2.numOK == 1?'CORRECTA':'SIN MARCAR')"
                            :color="item2.numNOK == 1?'red':item2.numOK == 1?'green':(item2.recuperadas == 1?'teal':'gray')"
                            :decorationConfig="readonly?{}:{
                              icon: item2.numNOK == 1?'hand_thumbsup':(item2.recuperadas == 1?'hand_thumbsup':'hand_thumbsdown'),
                              color: item2.numNOK == 1? 'teal':(item2.numOK == 1? 'red':'green'),
                              tooltip: item2.numOK == 1? 'Marcar como Defectuosa':(item2.numNOK == 1? 'Marcar como recuperada':'Marcar como Correcta'),
                              action: function() {
                                if(item2.numOK == 1) {item2.numOK = 0;item2.numNOK = 1;item2.recuperadas = 0;}
                                else if(item2.numNOK == 1) {item2.numOK = 0;item2.numNOK = 0;item2.recuperadas = 1;}
                                else if(item2.recuperadas == 1) {item2.numOK = 1;item2.numNOK = 0;item2.recuperadas = 0;}
                                else {item2.numOK = 1;item2.numNOK = 0;item2.recuperadas = 0;}
                                recalcular();}
                            }"
                          ></r-input>
                          <r-input
                            v-if="item2.tipo == 'PIEZA' && item2.numNOK && rform.formData.gama.listadoDefectos"
                            class="col-10"
                            floating-label
                            :label="$t('selecciones.detalle.defecto')"
                            :form="rform"
                            :name="'madre.hijos.' + index + '.hijos.' + index2 + '.defecto'"
                            type="select"
                            :clear-button="false"
                          >
                            <option selected value=""></option>
                            <option 
                              v-for="defecto in rform.formData.gama.listadoDefectos.split(';')"
                              :key="'madre.hijos.' + index + '.hijos.' + index2 + '.defecto.opcion.' + defecto"
                              :value="defecto.trim()"
                            >{{defecto.trim()}}</option>
                            <option value="OTROS">OTROS</option>
                          </r-input>
                          <r-input
                            v-if="item2.tipo == 'PIEZA' && item2.numNOK && !rform.formData.gama.listadoDefectos"
                            class="col-10"
                            floating-label
                            :label="$t('selecciones.detalle.defecto')"
                            :form="rform"
                            :name="'madre.hijos.' + index + '.hijos.' + index2 + '.defecto'"
                            type="text"
                          ></r-input>





















                          <r-input
                            v-if="item2.tipo != 'PIEZA'"
                            class="col-10"
                            floating-label
                            :label="$t('selecciones.detalle.recuperadas')"
                            :form="rform"
                            type="number"
                            :name="'madre.hijos.' + index + '.hijos.' + index2 + '.recuperadas'"
                          ></r-input>
                          <div style="position: absolute; top: 0px; right: 0px;">
                            <f7-link v-if="!readonly" v-tooltip="$t('common.eliminar')" icon-f7="trash" color="red" @click="deleteLinea(item.hijos,index2);"></f7-link>
                            <f7-link
                              v-if="!rform.readonly"
                              v-tooltip="$t('common.duplicar')" 
                              slot="right"
                              @click="duplicaLinea(item2,item)"
                              icon-f7="doc_on_doc_fill"
                            ></f7-link>
                          </div>
                        </f7-row>
                      </f7-card>
                    </f7-row>
                  </div>
                </f7-row>
              </div>
            </f7-card>
          </f7-row>




          <!-- Para vehículos: cambiamos la categoría para que sea TRABAJO - LINEAs - VEHICULOs (en lugar de MADRE - BACs - PIEZAs) -->
          <f7-row v-if="rform.formData.enVehiculo" no-gap class="list list-form no-hairlines">
            <f7-card outline class="col-100 bg-color-orange">
              <f7-row no-gap class="list list-form no-hairlines">
                <r-input
                  v-if="rform.formData.madre && rform.formData.madre"
                  class="col-10"
                  floating-label
                  :label="$t('selecciones.detalle.codigo')"
                  :form="rform"
                  type="text"
                  name="madre.etiqueta"
                ></r-input>
                <r-input
                  class="col-10"
                  floating-label
                  :label="$t('selecciones.dateLabel')"
                  :form="rform"
                  type="text"
                  name="madre.dateLabel"
                ></r-input>
                <!-- <r-input
                  class="col-10"
                  floating-label
                  :label="$t('selecciones.bl')"
                  :form="rform"
                  type="text"
                  name="madre.bl"
                ></r-input> -->
                <r-input
                  class="col-10"
                  floating-label
                  :label="$t('selecciones.ubicacion')"
                  :form="rform"
                  type="text"
                  name="madre.ubicacion"
                ></r-input>
                <r-input
                  class="col-10"
                  floating-label
                  :label="$t('selecciones.detalle.numPiezasTotal')"
                  :form="rform"
                  type="text"
                  name="madre.numPiezasTotal"
                ></r-input>
                <r-input
                  class="col-10"
                  floating-label
                  :label="$t('selecciones.detalle.numOK')"
                  :form="rform"
                  type="text"
                  name="madre.numOK"
                ></r-input>
                <r-input
                  class="col-10"
                  floating-label
                  :label="$t('selecciones.detalle.numNOK')"
                  :form="rform"
                  type="text"
                  name="madre.numNOK"
                ></r-input>
                <r-input
                  class="col-10"
                  floating-label
                  :label="$t('selecciones.detalle.recuperadas')"
                  :form="rform"
                  type="text"
                  name="madre.recuperadas"
                ></r-input>
                
              </f7-row>
              <!-- poner botón de añadir línea de detalle de revisión de operario -->
              <div>
                <div style="display: inline-flex;padding:10px 50px 5px 10px;">
                  <b>{{$t('selecciones.detalle.trabajos')}}</b>
                </div>
                <f7-link
                  v-if="!rform.readonly"
                  slot="right"
                  @click="nuevaLinea('LINEA')"
                  icon-f7="plus_app_fill"
                ></f7-link>
              </div>
              <div v-if="rform && rform.formData && rform.formData.madre && rform.formData.madre.hijos">
                <f7-row 
                  no-gap 
                  class="list list-form no-hairlines"
                  v-for="(item,index) in rform.formData.madre.hijos" 
                  :key="item.tipo + '-' + item.etiqueta + '(' +index+')'" 
                >
                  <f7-card :class="{'col-100 elevation-3 padding-vertical-half padding-right':true,'bg-color-purple':item.tipo=='LINEA','bg-color-yellow':item.tipo=='PIEZA','bg-color-blue':item.tipo=='BAC'}">
                    <f7-row no-gap class="list list-form no-hairlines">
                      <r-input
                        class="col-10"
                        floating-label
                        :label="$t('selecciones.detalle.tipo')" 
                        :form="rform"
                        type="text"
                        :name="'madre.hijos.' + index + '.tipo'"
                        :readonly="true"
                        :overrideReadonly="true"
                      ></r-input>
                      <r-input
                        class="col-10"
                        floating-label
                        :label="$t('selecciones.detalle.fecha')"
                        :form="rform"
                        type="datepicker"
                        :name="'madre.hijos.' + index + '.fecha'"
                        :calendar-params="{
                            closeOnSelect: true,
                            routableModals: false,
                            dateFormat: {
                              year: 'numeric',
                              month: '2-digit',
                              day: '2-digit',
                            },
                          }"
                      ></r-input>
                      <r-input
                        class="col-10"
                        floating-label
                        :label="$t('selecciones.detalle.turno')" 
                        :form="rform"
                        type="select"
                        :clearButton="false"
                        :name="'madre.hijos.' + index + '.turno'"
                      >
                        <option selected value="MAÑANA">MAÑANA</option>
                        <option selected value="TARDE">TARDE</option>
                        <option selected value="NOCHE">NOCHE</option>
                      </r-input>
                      <r-input
                        class="col-10"
                        floating-label
                        :label="$t('selecciones.detalle.pji_inicio')"
                        :form="rform"
                        type="text"
                        :name="'madre.hijos.' + index + '.pjiInicio'"
                      ></r-input>
                      <r-input
                        class="col-10"
                        floating-label
                        :label="$t('selecciones.detalle.pji_fin')"
                        :form="rform"
                        type="text"
                        :name="'madre.hijos.' + index + '.pjiFin'"
                      ></r-input>
                      <r-input
                        class="col-10"
                        floating-label
                        :label="$t('selecciones.detalle.num_orden_ini')"
                        :form="rform"
                        type="number"
                        :name="'madre.hijos.' + index + '.numOrden'"
                      ></r-input>
                      <r-input
                        class="col-10"
                        floating-label
                        :label="$t('selecciones.detalle.num_orden_fin')"
                        :form="rform"
                        type="number"
                        :name="'madre.hijos.' + index + '.numOrdenFin'"
                      ></r-input>
                      <!-- <r-input
                        class="col-10"
                        floating-label
                        :label="$t('selecciones.ubicacion')"
                        :form="rform"
                        type="text"
                        :name="'madre.hijos.' + index + '.ubicacion'"
                      ></r-input> -->
                      <r-input
                        class="col-10"
                        floating-label
                        :label="$t('selecciones.detalle.codOperario')"
                        :form="rform"
                        type="text"
                        :name="'madre.hijos.' + index + '.codOperario'"
                      ></r-input>
                    </f7-row>
                    <f7-row no-gap class="list list-form no-hairlines">
                      
                      <r-input
                        class="col-10"
                        floating-label
                        :label="$t('selecciones.detalle.numPiezasTotal')"
                        :form="rform"
                        type="number"
                        :name="'madre.hijos.' + index + '.numPiezasTotal'"
                      ></r-input>
                      <r-input
                        class="col-10"
                        floating-label
                        :label="$t('selecciones.detalle.numOK')"
                        :form="rform"
                        type="number"
                        :name="'madre.hijos.' + index + '.numOK'"
                      ></r-input>
                      <r-input
                        class="col-10"
                        floating-label
                        :label="$t('selecciones.detalle.numNOK')"
                        :form="rform"
                        type="number"
                        :name="'madre.hijos.' + index + '.numNOK'"
                        :color="item.numNOK > 0?'red':'green'"
                      ></r-input>
                      <r-input
                        class="col-10"
                        floating-label
                        :label="$t('selecciones.detalle.recuperadas')"
                        :form="rform"
                        type="number"
                        :name="'madre.hijos.' + index + '.recuperadas'"
                      ></r-input>
                    </f7-row>
                    <div style="position: absolute; top: 3px; right: 3px;">
                      <f7-link 
                        v-if="!rform.readonly && item.tipo == 'LINEA'" 
                        v-tooltip="$t('common.nueva')" 
                        @click="nuevaPieza(item,'VEHICULO');" 
                        icon-f7="plus_app_fill"
                      ></f7-link>
                      <f7-link v-if="!readonly" v-tooltip="$t('common.eliminar')" icon-f7="trash" color="red" @click="deleteLinea(rform.formData.madre.hijos,index);recalcular()"></f7-link>
                    </div>
                  </f7-card>
                  <div v-if="item.hijos" class="col-100" style="padding-left:10px;">
                    <f7-row 
                      no-gap 
                      class="list list-form no-hairlines"
                      v-for="(item2,index2) in item.hijos" 
                      :key="'linea-vehiculo-' + index2" 
                    >
                      <f7-card :class="{'col-100 elevation-3 padding-vertical-half padding-right':true,'bg-color-grey':item2.tipo=='VEHICULO','bg-color-yellow':item2.tipo=='PIEZA','bg-color-blue':item2.tipo=='BAC'}">
                        <f7-row no-gap class="list list-form no-hairlines">
                          <r-input
                            style="width:7em"
                            floating-label
                            :label="$t('selecciones.detalle.tipo')" 
                            :form="rform"
                            type="text"
                            :readonly="true"
                            :overrideReadonly="true"
                            :clearButton="false"
                            :name="'madre.hijos.' + index + '.hijos.' + index2 + '.tipo'"
                          ></r-input>
                          <r-input
                            style="width:7%"
                            floating-label
                            :label="$t('selecciones.PJI')"
                            :form="rform"
                            type="text"
                            :name="'madre.hijos.' + index + '.hijos.' + index2 + '.etiqueta'"
                          ></r-input>
                          <r-input
                            style="width:7%"
                            floating-label
                            :label="$t('selecciones.detalle.num_orden')"
                            :form="rform"
                            type="number"
                            :name="'madre.hijos.' + index + '.hijos.' + index2 + '.numOrden'"
                          ></r-input>
                          <!-- <r-input
                            v-if="item2.ubicacion"
                            class="col-10"
                            floating-label
                            :label="$t('selecciones.ubicacion')"
                            :form="rform"
                            type="text"
                            :name="'madre.hijos.' + index + '.hijos.' + index2 + '.ubicacion'"
                          ></r-input> -->
                          <!-- <r-input
                            class="col-10"
                            floating-label
                            :label="$t('selecciones.detalle.codOperario')"
                            :form="rform"
                            type="number"
                            :name="'madre.hijos.' + index + '.hijos.' + index2 + '.codOperario'"
                          ></r-input> -->
                          <r-input
                            style="width:10%"
                            floating-label
                            :label="$t('selecciones.detalle.defecto')"
                            :form="rform"
                            type="badge"
                            :name="'madre.hijos.' + index + '.hijos.' + index2 + '.numNOK'"
                            :text="item2.numNOK == 1?'DEFECTUOSA':item2.recuperadas == 1?'RECUPERADA':'CORRECTA'"
                            :color="item2.numNOK == 1?'red':'green'"
                            :decorationConfig="readonly?{}:{
                              icon: item2.numNOK == '0'?'hand_thumbsdown':'hand_thumbsup',
                              color: item2.numNOK == '0'? 'red':'green',
                              tooltip: item2.numNOK == '0'? 'Marcar como Incorrecta':'Marcar como Correcta',
                              action: function() {
                                item2.numNOK = (item2.numNOK != 0? 0:1);
                                item2.numOK = 1 - item2.numNOK;
                              }
                            }"
                          ></r-input>
                          <r-input
                            style="width:8em"
                            floating-label
                            :label="$t('selecciones.detalle.recuperada')"
                            :form="rform"
                            type="badge"
                            :name="'madre.hijos.' + index + '.hijos.' + index2 + '.recuperadas'"
                            :text="item2.recuperadas == 1?'SI':'NO'"
                            :color="item2.recuperadas == 1?'lime':'grey'"
                            :decorationConfig="readonly?{}:{
                              icon: item2.recuperadas != '1'?'hand_thumbsup':'hand_thumbsdown',
                              color: item2.recuperadas != '1'? 'lime':'grey',
                              tooltip: item2.recuperadas != '1'? 'Marcar como recuperada':'Marcar como No Recuperada',
                              action: function() {
                                item2.recuperadas = (item2.recuperadas != 0? 0:1);
                                item2.numNOK = (item2.recuperadas != 0? 0:1);
                                item2.numOK = 1 - item2.numNOK;
                              }
                            }"
                          ></r-input>
                          <!-- Campos abiertos para datos extra que no sabemos cuales son -->
                          <r-input
                            class="col-10"
                            floating-label
                            :label="$t('selecciones.detalle.materiales')"
                            :form="rform"
                            type="number"
                            :name="'madre.hijos.' + index + '.hijos.' + index2 + '.materiales'"
                          ></r-input>
                          <r-input
                            class="col-10"
                            floating-label
                            :label="$t('selecciones.detalle.envios')"
                            :form="rform"
                            type="number"
                            :name="'madre.hijos.' + index + '.hijos.' + index2 + '.envios'"
                          ></r-input>
                          <r-input
                            class="col-10"
                            floating-label
                            :label="$t('selecciones.detalle.campo1')"
                            :form="rform"
                            type="text"
                            :name="'madre.hijos.' + index + '.hijos.' + index2 + '.campo1'"
                          ></r-input>
                          <r-input
                            class="col-10"
                            floating-label
                            :label="$t('selecciones.detalle.campo2')"
                            :form="rform"
                            type="text"
                            :name="'madre.hijos.' + index + '.hijos.' + index2 + '.campo2'"
                          ></r-input>
                          <r-input
                            class="col-10"
                            floating-label
                            :label="$t('selecciones.detalle.campo3')"
                            :form="rform"
                            type="text"
                            :name="'madre.hijos.' + index + '.hijos.' + index2 + '.campo3'"
                          ></r-input>
                          <r-input
                            class="col-10"
                            floating-label
                            :label="$t('selecciones.detalle.campo4')"
                            :form="rform"
                            type="text"
                            :name="'madre.hijos.' + index + '.hijos.' + index2 + '.campo4'"
                          ></r-input>
                          <!-- Fin de campos abiertos -->
                        </f7-row>
                        <div style="position: absolute; top: 3px; right: 3px;">
                          <f7-link v-if="!readonly" v-tooltip="$t('common.eliminar')" icon-f7="trash" color="red" @click="deleteLinea(item.hijos,index2);"></f7-link>
                        </div>
                      </f7-card>
                    </f7-row>
                  </div>
                </f7-row>
              </div>
            </f7-card>
          </f7-row>

        </f7-card-content>
      </f7-card>

      <f7-card v-if="rform && rform.formData && rform.formData.elementos && rform.formData.elementos.length > 0">
        <f7-card-header>
          <f7-card-header-title>Listado de elementos vinculados incompletos</f7-card-header-title>
        </f7-card-header>
        <f7-card-content no-gap class="">
          <div 
            no-gap 
            class="list list-form no-hairlines col-100"
          >
            <f7-card 
              no-gap 
              class="col-25 deep-purple darken-1"
              v-for="(elemento,indice) in rform.formData.elementos"
              :key="'elemento-'+ indice + '-' + elemento.etiqueta"
            >
              <f7-row no-gap class="list list-form no-hairlines">
                <r-input
                  class="col-10 medium-20 small-100 darken-1"
                  floating-label
                  :label="$t('selecciones.detalle.elementos.tipo')"
                  :form="rform"
                  type="text"
                  :name="'elementos.' + indice + '.tipo'"
                  :readonly="true"
                  :overrideReadonly="true"
                ></r-input>
                <r-input
                  v-if="elemento.etiquetaPadre"
                  class="col-10 medium-20 small-100"
                  floating-label
                  :label="$t('selecciones.detalle.elementos.padre')"
                  :form="rform"
                  type="text"
                  :name="'elementos.' + indice + '.etiquetaPadre'"
                  :readonly="true"
                  :overrideReadonly="true"
                ></r-input>
                <r-input
                  class="col-10 medium-50 small-100"
                  floating-label
                  :label="$t('selecciones.detalle.elementos.etiqueta')"
                  :form="rform"
                  type="text"
                  :name="'elementos.' + indice + '.etiqueta'"
                  :readonly="true"
                  :overrideReadonly="true"
                ></r-input>
                <div style="position: absolute; top: 0px; right: 0px;">
                  <f7-link v-if="elemento.hijos" v-tooltip="$t(!elemento.mostrarHijos?'common.mostrar':'common.ocultar')" :icon-f7="!elemento.mostrarHijos?'chevron_down':'chevron_up'" color="yellow" @click="$set(elemento, 'mostrarHijos', !elemento.mostrarHijos)"></f7-link>
                  <f7-link v-tooltip="$t('common.eliminar')" icon-f7="trash" color="red" @click="deleteElemento(rform.formData.elementos,indice);"></f7-link>
                </div>
              </f7-row>


                  <div v-if="elemento.hijos && elemento.mostrarHijos" class="col-100" style="padding-left:10px;">
                    <f7-row 
                      no-gap 
                      class="list list-form no-hairlines"
                      v-for="(hijo,index2) in elemento.hijos" 
                      :key="hijo.tipo + '-' + hijo.etiqueta + '(' +index2+')'" 
                    >
                      <f7-card :class="{'col-100 elevation-3 padding-vertical-half padding-right':true,'bg-color-grey':hijo.tipo=='VEHICULO','bg-color-yellow':hijo.tipo=='PIEZA','bg-color-blue':hijo.tipo=='BAC'}">
                        <f7-row no-gap class="list list-form no-hairlines">
                          <r-input
                            style="width:7em"
                            floating-label
                            :label="$t('selecciones.detalle.tipo')" 
                            :form="rform"
                            type="text"
                            :readonly="true"
                            :overrideReadonly="true"
                            :clearButton="false"
                            :name="'elementos.' + indice + '.hijos.' + index2 + '.tipo'"
                          ></r-input>
                          <r-input
                            style="width:21%"
                            floating-label
                            :label="$t('selecciones.detalle.elementos.etiqueta')"
                            :form="rform"
                            type="text"
                            :name="'elementos.' + indice + '.hijos.' + index2 + '.etiqueta'"
                          ></r-input>
                          <r-input
                            style="width:10%"
                            floating-label
                            :label="$t('selecciones.detalle.defecto')"
                            :form="rform"
                            type="badge"
                            :name="'elementos.' + indice + '.hijos.' + index2 + '.numNOK'"
                            :text="hijo.numNOK == 1?'DEFECTUOSA':hijo.recuperadas == 1?'RECUPERADA':'CORRECTA'"
                            :color="hijo.numNOK == 1?'red':'green'"
                            :decorationConfig="readonly?{}:{
                              icon: hijo.numNOK == '0'?'hand_thumbsdown':'hand_thumbsup',
                              color: hijo.numNOK == '0'? 'red':'green',
                              tooltip: hijo.numNOK == '0'? 'Marcar como Incorrecta':'Marcar como Correcta',
                              action: function() {
                                hijo.numNOK = (hijo.numNOK != 0? 0:1);
                                hijo.numOK = 1 - hijo.numNOK;
                                recalcular();
                              }
                            }"
                          ></r-input>
                          <r-input
                            style="width:8em"
                            floating-label
                            :label="$t('selecciones.detalle.recuperada')"
                            :form="rform"
                            type="badge"
                            :name="'elementos.' + indice + '.hijos.' + index2 + '.recuperadas'"
                            :text="hijo.recuperadas == 1?'SI':'NO'"
                            :color="hijo.recuperadas == 1?'lime':'grey'"
                            :decorationConfig="readonly?{}:{
                              icon: hijo.recuperadas != '1'?'hand_thumbsup':'hand_thumbsdown',
                              color: hijo.recuperadas != '1'? 'lime':'grey',
                              tooltip: hijo.recuperadas != '1'? 'Marcar como recuperada':'Marcar como No Recuperada',
                              action: function() {
                                hijo.recuperadas = (hijo.recuperadas != 0? 0:1);
                                hijo.numNOK = (hijo.recuperadas != 0? 0:1);
                                hijo.numOK = 1 - hijo.numNOK;
                                recalcular();
                              }
                            }"
                          ></r-input>
                        </f7-row>
                        <div style="position: absolute; top: 3px; right: 3px;">
                          <f7-link v-if="!readonly" v-tooltip="$t('common.eliminar')" icon-f7="trash" color="red" @click="deleteLinea(elemento.hijos,index2);"></f7-link>
                        </div>
                      </f7-card>
                    </f7-row>
                  </div>


            </f7-card>
          </div>
        </f7-card-content>
      </f7-card>
      <pre style="display:none;">{{rform.formData}}</pre>
    </r-form>
  </r-page>
</template>
<style scoped>
  .label-group {
    margin-top: 12px;
    margin-left: 20px;
    font-weight: bold;
  }
  .label-group-inspeccion {
    margin-top: 30px;
  }
  label.btn {
    padding: 1px;
    cursor: pointer;
    border: 1px solid;
  }
  .valor-row {
    position: relative;
  }
  .valor-codigo {
    padding-left: 3em;
  }
  .valor-controles {
    position:absolute;
    left: 0;
    top: 0;
    z-index: 2;
  }
  .valor-delete {
    position:absolute;
    left: 0;
    top: 7px;
  }
  .valor-reordenar {
    margin-top:2px;
    position:absolute;
    left: 12px;
    top: 11px;
  }

  .bg-color-purple {
    background-color: #edbdf7 !important;
  }
  .bg-color-orange {
    background-color: #FFE0B2 !important;
  }
  .bg-color-grey {
    background-color: #EDEEF7 !important;
  }

  .deep-purple {
    background-color: #5E35B1 !important;
  }

  .darken-1 {
    color: white !important;
  }

  .popup-alertas {
    overflow: auto;
  }

</style>
<style>
  #tab-expediente .list .bordeAzul .item-input-outline input,
  #tab-expediente .list .bordeAzul .item-input-outline textarea {
    color: blue;
    padding: 5px;
  }
  #tab-expediente .bordeAzul .item-input-outline .item-input-wrap:after {
    border-color: lightblue;

  }
  #tab-cliente .list .bordeAzul .item-input-outline input {
    color: blue;
  }
  #tab-cliente .bordeAzul .item-input-outline .item-input-wrap:after {
    border-color: lightblue;
  }

</style>
<script>
import Vue from "vue";
import rForm from "../../components/rForm.vue";
import rInput from "../../components/rInput.vue";
import Documento from "../../js/db/Documento.js";
import Elemento from "../../js/db/Elemento.js";
import Gamas from "../../js/db/Gamas.js";
import rAutocomplete from "../../components/rAutocomplete.vue";
import rTimepicker from "../../components/rTimepicker.vue";
import rRichTextInput from "../../components/rRichTextInput.vue";
import { Container, Draggable } from "vue-smooth-dnd";
import Selecciones from "../../js/db/Selecciones.js";
import Flash from '../../js/FlashUtils';

export default {
  components: {
    rForm,
    rInput,
    rTimepicker,
    rRichTextInput,
    rAutocomplete,
    Container,
    Draggable
  },
  data: function() {
    var self = this;
    return {
      Selecciones,
      Documento,
      Elemento,
      Gamas,
      popupAlertasOpened: false,
      readonly: !this.editable,
      referenciasGama: [],
    };
  },
  props: {
    id: {
      type: String
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    profundidadRecalculo: function() {
      var self = this;
      var profundidad = 0;
      // Vamos a calcular la profundidad de recalculo según la categoría, siendo que cuando en la gama, la categoría es 1 la profundidad es 0, cuando es categoría 2, la profundiad es 1, cuando la categoría es 3 la profundidad es 2 y para categoría 4 la profundidad es 1
      if (self.$refs.rform && self.$refs.rform.formData && self.$refs.rform.formData.gama && self.$refs.rform.formData.gama.categoria) {
        switch (self.$refs.rform.formData.gama.categoria) {
          case '1':
            profundidad = 0;
            break;
          case '2':
            profundidad = 1;
            break;
          case '3':
            profundidad = 2;
            break;
          case '4':
            profundidad = 1;
            break;
        }
      }
      return profundidad;
    },
  },
  beforeDestroy() {},
  methods: {
    getUbicacionesDisponibles: function (rform) {
      const self = this;
      const res = rform && rform.formData && rform.formData.gama && rform.formData.gama.zonasPosibles ? rform.formData.gama.zonasPosibles.map(z => { return { id: z.trim(), nombre: z.trim(), valor: z.trim() } }) : [];
      console.log("getUbicacionesDisponibles", res);
      return res;
    },
    mostrarAviso: function(aviso) {
      var self = this;
      var app = self.$f7;
      app.dialog.alert(aviso);
    },
    deleteElemento: function(lista, index) {
      var self = this;
      var app = self.$f7;
      let elemento = lista[index];
      self.$f7.dialog.confirm(
        self.$t("rlist.pregunta_eliminar_registro"),
        "Eliminar",
        function() {
          Elemento.delete({itemId: elemento.id}).then(function(res) {
            if (res) {
              lista.splice(index,1);
              app.toast
                .create({
                  icon: '<i class="f7-icons">checkmark</i>',
                  text: self.$t("rlist.registro_eliminado"),
                  position: "center",
                  destroyOnClose: true,
                  closeTimeout: 2000
                })
                .open();
              Flash.set("refreshList", true);
            }
          }).catch(function(error){
            if(error.message){
              if(error.message){
              app.dialog.alert(error.message);
            } else {
              app.dialog.alert(error);
            }
            } else {
              app.dialog.alert(error);
            }
          });
        }
      );
    },
    deleteLinea: function(lista, index) {
      var self = this;
      var app = self.$f7;
      self.$f7.dialog.confirm(
        self.$t("rlist.pregunta_eliminar_registro"),
        "Eliminar",
        function() {
          lista.splice(index,1);
          self.recalcular();
        }
      );
    },
    nuevaLinea: function(tipo) {
      var self = this;
      if(!self.$refs.rform.formData.madre) {
        self.$set(self.$refs.rform.formData, 'madre', {tipo: 'MADRE', hijos: []});
      }
      if(!self.$refs.rform.formData.madre.hijos) {
        self.$set(self.$refs.rform.formData.madre, 'hijos', []);
      }
      self.$refs.rform.formData.madre.hijos.push({
        tipo: tipo,
        referencia: self.$refs.rform.formData.referencia,
        numPiezas: tipo=='PIEZA'?1:0,
        numPiezasTotal: tipo=='PIEZA'?1:0,
        numOK: tipo=='PIEZA'?1:0,
        numNOK: 0,
        recuperadas: 0,
        manual: true,
        fechaJornada: self.$refs.rform.formData.fechaCierre
      });
    },
    nuevaPieza: function(item,tipo) {
      var self = this;
      if(!self.$refs.rform.formData.madre) {
        self.$set(self.$refs.rform.formData, 'madre', {tipo: 'MADRE', hijos: []});
      }
      if(!item.hijos) {
        self.$set(item, 'hijos', []);
      }
      item.hijos.push({
        tipo: tipo,
        referencia: self.$refs.rform.formData.referencia,
        numPiezas: 1,
        numPiezasTotal: 1,
        numOK: 1,
        numNOK: 0,
        recuperadas: 0,
        manual: true,
        fechaJornada: item.fechaJornada
      });
      self.$set(item, 'mostrarHijos', true);
      // item.mostrarHijos = true;
      self.recalcular();
    },
    duplicaLinea: function(item,padre) {
      var self = this;
      let copia = JSON.parse(JSON.stringify(item));
      // Si copia tiene hijos, vamos a borrarles el campo etiqueta, descripción del defecto, numOK y numNOK
      if(copia.hijos) {
        copia.hijos.forEach(function(hijo) {
          hijo.etiqueta = undefined;
          hijo.numOK = 0;
          hijo.numNOK = 0;
          hijo.recuperadas = 0;
        });
      }
      copia.etiqueta = undefined;
      copia.numOK = 0;
      copia.numNOK = 0;
      copia.recuperadas = 0;
      padre.hijos.push(copia);
      self.recalcular();
    },
    sumaEnMadre: function(campo) {
      var self = this;
      var suma = 0;
      self.$refs.rform.formData.madre.hijos.forEach(function(item) {
        suma += parseInt(item[campo]?item[campo]:0);
      });
      self.$set(self.$refs.rform.formData.madre, campo, suma);
    },
    sumaEnPadre2: function(padre,campos) {
      var self = this;
      var suma = 0;
      padre.hijos.forEach(function(item) {
        if(campos && Array.isArray(campos)) {
          campos.forEach(function(campo) {
            suma += parseInt(item[campo]?item[campo]:0);
          });
        } else {
          suma += parseInt(item[campos]?item[campos]:0);
        }
      });
      self.$set(padre, campo, suma);
    },
    sumaEnPadre: function(padre,campo) {
      var self = this;
      var suma = 0;
      padre.hijos.forEach(function(item) {
        suma += parseInt(item[campo]?item[campo]:0);
      });
      self.$set(padre, campo, suma);
    },
    onChangeEstado: function(abierto,componente) {
      var self = this;
      // en el cambio manual de estado vamos a mirar si tenemos que poner fecha de cierre cuando sea necesario
      if(!abierto && !self.$refs.rform.formData.fechaCierre) {
        self.$set(self.$refs.rform.formData, 'fechaCierre', new Date());
      }
    },
    /**
     * 
     * @param {*} campos : Listado de campos a recalcular, por defecto le ponemos todos
     * @param {*} item   : Item a recalcular, por defecto la madre
     * @param {*} nivel  : Nivel de profundidad para limitar el recálculo, por defecto 0
     */
    recalcular: function(campos = ['numNOK','numOK','recuperadas','numPiezas','numPiezasTotal','materiales'] ,item = this.$refs.rform.formData.madre, nivel = 0) {
    // recalcular: function(campos = ['numNOK','recuperadas'] ,item = this.$refs.rform.formData.madre) {
      var self = this;
      // Si el item es de tipo PIEZA, le pondremos numPiezas a 1
      if(item.tipo=='PIEZA') {
        item.numPiezas = 1;
        item.numPiezasTotal = 1;
      }
      // Si es vehículo, no hacemos nada, pero si no lo es y el nivel es menor o igual a la profundidad de cálculo de la categoría, recalculamos
      if(!self.$refs.rform.formData.enVehiculo && self.profundidadRecalculo > nivel) {
        if(item.hijos && item.hijos.length>0) {
          if(campos && Array.isArray(campos)) {
            campos.forEach(function(campo) {
                item[campo] = 0;
              });
          } else {
            item[campos] = 0;
          }
          
          item.hijos.forEach(function(hijo) {
            hijo = self.recalcular(campos, hijo, nivel+1);
            if(campos && Array.isArray(campos)) {
              campos.forEach(function(campo) {
                console.log(item.tipo + '-' + campo + ': ' + item[campo] + '<-- antes'); 
                item[campo] = parseInt(item[campo] ? item[campo] : 0) + parseInt(hijo[campo]?hijo[campo]:0);
                console.log(item.tipo + '-' + campo + ': ' + item[campo]); 
                item[campo] = isNaN(item[campo]) ? 0 : item[campo];
              });
            } else {
              item[campos] = parseInt(item[campos] ? item[campos] : 0) + parseInt(hijo[campo]?hijo[campo]:0);
              console.log(item.tipo + '-' + campos + ': ' + item[campos]); 
              item[campos] = isNaN(item[campos]) ? 0 : item[campos];
            }
          });
        } else {
          if(campos && !Array.isArray(campos)) {
            console.log(item.tipo + '-' + campos + ': ' + item[campos]); 
            return parseInt(item[campos] ? item[campos] : 0);
          } else {
            campos.forEach(function(campo) {
              console.log(item.tipo + '-' + campo + ': ' + item[campo]); 
              return parseInt(item[campo] ? item[campo] : 0);
            });
          }
        }
      }
      return item;
    },
    reabrir: function() {
      var self = this;
      // Ponemos la UM en estado abierta
      self.$refs.rform.formData.abierta = true;
    },
    getTextoBadgeCategoria: function(categoria) {
      if (!categoria) {
        return "Sin Categoría";
      }

      if (categoria == '1') {
        return "Categoría 1";
      }
      else if (categoria == '2') {
        return "Categoría 2";
      }
       else if (categoria == '3') {
        return "Categoría 3";
      }
       else if (categoria == '4') {
        return "Categoría 4";
      } else {
        return "Sin definir";
      }
      
    },
    getColorBadgeCategoria: function(categoria) {
      if (!categoria) {
        return "gray";
      }

      if (categoria == '1') {
        return "lime";
      }
      else if (categoria == '2') {
        return "green";
      }
      else if (categoria == '3') {
        return "orange";
      }
      else if (categoria == '4') {
        return "brown";
      } else {
        return "gray";
      }
    },
    deleteItem: function() {
      var self = this;
      var app = self.$f7;
      app.dialog.confirm(
        self.$t("rlist.pregunta_eliminar_registro"),
        "Eliminar",
        function() {
          Selecciones.delete({itemId: self.id}).then(function(res) {
            if (res) {
              app.toast
                .create({
                  icon: '<i class="f7-icons">checkmark</i>',
                  text: self.$t("rlist.registro_eliminado"),
                  position: "center",
                  destroyOnClose: true,
                  closeTimeout: 2000
                })
                .open();
              Flash.set("refreshList", true);
              self.$f7router.back();
              // app.views.main.router.navigate("/selecciones/?" + 'refreshList');
            }
          }).catch(function(error){
            if(error.message){
              app.dialog.alert(error.message);
            } else {
              app.dialog.alert(error);
            }
          });
        }
      );
    },
    onDropValor: function(arr, dragResult){
      var self = this;
      const { removedIndex, addedIndex, payload } = dragResult;
      if (removedIndex === null && addedIndex === null) return arr;
      if (!arr) arr = [];
      var result = arr;
      let itemToAdd = payload;
      if (removedIndex !== null) {
        itemToAdd = Object.assign({},  result.splice(removedIndex, 1)[0]);
      }
      if (addedIndex !== null) {
        result.splice(addedIndex, 0, Object.assign({}, itemToAdd));
      }
    },
    deleteValor: function(gama, indexValor){
      gama.valores.splice(indexValor,1);
    },
    changeGama: function() {
      const self = this;
      console.log("changeGama");
      if (self.$refs.rform && self.$refs.rform.formData && self.$refs.rform.formData.gama && self.$refs.rform.formData.gama.zonasPosibles) {
        var zonas = self.$refs.rform.formData.gama.zonasPosibles;
        if (zonas.length == 1) {
          self.$set(self.$refs.rform.formData.madre, 'ubicacion', zonas[0]);
        }
      }
      if(self.$refs.rform && self.$refs.rform.formData && self.$refs.rform.formData.gama && self.$refs.rform.formData.gama.referencias) {
        var referencias = self.$refs.rform.formData.gama.referencias?self.$refs.rform.formData.gama.referencias.split(' '):[];
        self.referenciasGama = referencias;
        if(referencias.length >= 1) {
          this.cambiarReferencia(referencias[0]);
        }
        return referencias;
      }
      else {
        self.referenciasGama = [];
        self.$set(self.$refs.rform.formData, 'referencia', "");
        this.cambiarReferencia("");
      }
    },
    getReferenciasGama: function() {
      const self = this;
      if(self.$refs.rform && self.$refs.rform.formData && self.$refs.rform.formData.gama && self.$refs.rform.formData.gama.referencias) {
        var referencias = self.$refs.rform.formData.gama.referencias?self.$refs.rform.formData.gama.referencias.split(' '):[];
        if(referencias.length >= 1) {
          this.cambiarReferencia(referencias[0]);
        }
        return referencias;
      }
      return [];
    },
    cambiarReferencia: function(e) {
      const self = this;
      var referencia = e?e:self.$refs.rform.formData.referencia;
      self.$set(self.$refs.rform.formData, 'referencia', referencia);
      if(self.$refs.rform.formData.galia && self.$refs.rform.formData.galia.etiqueta) {
        self.$set(self.$refs.rform.formData.galia, 'referencia', referencia);
      }
      if(self.$refs.rform.formData.madre) {
        self.$set(self.$refs.rform.formData.madre, 'referencia', referencia);
      }
      // dentro de la madre, vamos a iterar por todos sus hijos de forma recursiva y vamos a ponerle la referencia
      if(self.$refs.rform.formData.madre && self.$refs.rform.formData.madre.hijos) {
        self.$refs.rform.formData.madre.hijos.forEach(function(hijo) {
          self.$set(hijo, 'referencia', referencia);
          if(hijo.hijos) {
            hijo.hijos.forEach(function(nieto) {
              self.$set(nieto, 'referencia', referencia);
            });
          }
        });
      }

    },
    beforeSave: function(data, resolve, reject) {
      if(data && data.valores && Array.isArray(data.valores)) {
        data.valores.forEach(valor => {
          if(!valor.nombre){
            valor.nombre = valor.valor;
          }
        });
      }
      resolve(data);
    },
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.$refs.rform.formData = res;
      self.readonly = true;
      app.toast
        .create({
          text: self.$t("selecciones.detalle.guardado"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
    },
    loadItem: function(res) {
      var self = this;
      if(!res.tipo){
        res.tipo = "SIMPLE";
      }
      var referencias = res.gama.referencias?res.gama.referencias.split(' '):[];
      self.referenciasGama = referencias;
    }
  },
  created: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;
  }
};
</script>