const messages = {
    es: {
        'Zonas posibles': 'Zonas de selección',
        atras: 'Atrás',
        cerrar: 'Cerrar',
        aceptar: 'Aceptar',
        searchBarBuscar: 'Buscar...',
        searchBarSinResultados: 'Sin Resultados...',
        Enero: 'Enero',
        Febrero: 'Febrero',
        Marzo: 'Marzo',
        Abril: 'Abril',
        Mayo: 'Mayo',
        Junio: 'Junio',
        Julio: 'Julio',
        Agosto: 'Agosto',
        Septiembre: 'Septiembre',
        Octubre: 'Octubre',
        Noviembre: 'Noviembre',
        Diciembre: 'Diciembre',

        modificationDate: 'F. modificación',

        common: {
            cambiarFiltros: 'Cambiar Filtros',
            cerrar: 'Cerrar',
            fechaVacia: 'Fecha Vacía',
            fechaRellena: 'Fecha Rellena',
            mesActual: 'Mes Actual',
            dia: 'Día',
            yes: 'Sí',
            no: 'No',
            desde: 'Desde',
            hasta: 'Hasta',
            buscar: 'Buscar',
            anadir: 'Añadir',
            cargar: 'Cargar',
            ver: 'Ver',
            editar: 'Editar',
            duplicar: 'Duplicar',
            guardar: 'Guardar',
            aceptar: 'Aceptar',
            guardarComo: 'Guardar Como',
            eliminar: 'Eliminar',
            cancelar: 'Cancelar',
            compartir: 'Compartir',
            filtro: 'Filtro',
            nombre: 'Nombre',
            bloqueado: 'Bloqueado',
            desbloqueado: 'Desbloqueado',
            move: 'Mover',
            copy: 'Copiar',
            paste: 'Pegar',
            any: 'Cualquiera',
            actualizarTabla: 'Actualizar datos de tabla',
            exportarExcel: 'Exportar a Excel',
            consultasFavoritas: 'Consultas Favoritas',
            firstSearch: 'Realice una búsqueda para obtener resultados',
            firstSearchTipoEstatico: 'Elija una consulta para obtener resultados',
            noResults: 'La búsqueda no ha dado resultados',
            configurarColumnas: 'Configurar columnas',
            consultaGuardada: 'Consulta Guardada correctamente',
            consultaBorrada: 'Consulta Borrada correctamente',
            consultaCompartida: 'Consulta Compartida correctamente',
            filtrar: 'Filtrar',
            cleanFilter: 'Limpiar Filtro',
            configurarFiltros: 'Configurar Filtros',
            guardarConsulta: 'Guardar Consulta',
            sharedConsulta: 'Compartir Consulta',
            sobreescribir: 'Sobreescribir',
            cambiosmasivos: 'Cambios Masivos',
            actualizarMasivamente: 'Actualizar masivamente',
            desmarcarSeleccionados: 'Desmarcar Seleccionados',
            configurarAgregaciones: 'Configurar Agregaciones',
            ver: 'Ver',
            sin: 'Sin',
            borrarFiltro: "Borrar Filtro",
            today: "Hoy",
            consultasComandos: "Elija una consulta",
            mostrar: "Mostrar",
            ocultar: "Ocultar",
            nueva: "Nueva",
            sendEmail: "Enviar email"
        },
        main_menu: {
            inicio: 'Inicio',
            alertas: 'Alertas',
            selecciones: 'UMs',
            lineas_selecciones: 'Etiquetas UMs',
            inspecciones_campa: 'Inspecciones Campa',
            reactividad: 'Informes de Reactividad',
            cargas_reactividad: 'Cargas',
            lineas_reactividad: 'Lineas',
            gamas: 'Gamas',
            tarifas: 'Tarifas',
            cts: 'Centros de Trabajo',
            llegadas: 'Previsión de llegadas',
            cargas_llegadas: 'Cargas',
            lineas_llegadas: 'Líneas',
            demandas: 'J+36',
            cargas_demandas: 'Cargas',
            lineas_demandas: 'Líneas',
            ajustes: 'Ajustes',
            plantillasImportacion: 'Plantillas de importación',
            empleados: 'Usuarios',
            dispositivos: 'Dispositivos',
            combos: 'Combos',
            cerrar_sesion: 'Cerrar sesión',
            notificaciones: 'Notificaciones',
            informes: 'Informes',
            jornada: {
                configuracion: 'Configuración de jornada',
                trabajo: 'Jornadas de trabajo',
            },
            properties: 'Propiedades',
            paises: 'Paises',
        },
        notificaciones: {
            notificaciones: 'Notificaciones',
            nuevo: 'Nueva notificación',
            usuario: 'Usuario',
            titulo: 'Título',
            mensaje: 'Mensaje',
            urlInterna: 'URL Interna',
            urlExterna: 'URL Externa',
            enviarATodos: 'Enviar a todos'
        },
        dispositivo: {
            dispositivos: 'Dispositivos',
            uuid: 'UUID',
            activo: 'Activo',
            manufacturer: 'Marca',
            model: 'Modelo',
            platform: 'Plataforma',
            serial: 'Num. Serie',
            version: 'Versión',
            appversion: 'Versión App',
            creationDate: 'F. Creación'
        },
        usuarios: {
            login: {
                usuario: 'Usuario',
                contrasena: 'Contraseña',
                entrar: 'Entrar',
                olvide_mi_contrasena: 'Olvidé mi contraseña',
                title: 'Inicio de sesión',
            },
            usuarios: {
                usuarios: 'Usuarios'
            },
            perfil: {
                perfil: 'Perfil',
                actual_pass: 'Contraseña actual',
                new_pass: 'Nueva Contraseña',
                repeat_new_pass: 'Repetir nueva Contraseña',
                codigoacceso: 'Código de acceso',
                cambiarpass: 'Cambiar contraseña'
            },
            usuario_form: {
                username: 'Username',
                nombre: 'Nombre',
                apellidos: 'Apellidos',
                telefono: 'Teléfono',
                email: 'Email',
                iniciales: 'Iniciales',
                roles: 'Roles',
                usuario: 'Usuario',
                editar_usuario: 'Editar usuario',
                nuevo_usuario: 'Nuevo usuario',
                usuario_guardado: 'Usuario guardado',
                rol: 'Rol',
                clientes_asociados: 'Clientes asociados',
                cliente: 'Cliente',
                add: 'Añadir',
                CHECK_MAN_data: 'Datos de CHECK_MAN',
                foto: 'Seleccionar Foto',
                firma: 'Firma',
                select_firma: 'Seleccionar Firma'
            }
        },
        evaluadorjs: {
            evaluadoresjs: 'Evaluadores JS',
            evaluadorjs: 'Evaluador JS',
            add: 'Añadir',
            editar: 'Editar',
            nuevo: 'Nuevo',
            nombre: 'Nombre',
            js: 'Código JS',
            ambito: 'Ámbito',
            guardado_ok: 'Evaluador JS guardado correctamente'
        },
        empleados: {
            title: 'Usuarios',
            nuevo: 'Nuevo',
            username: 'Usuario',
            codOperario: 'Cod. Operario',
            nombre: 'Nombre',
            dni: 'D.N.I.',
            email: 'e-Mail',
            telefono: 'Teléfono',
            rol: 'Rol',
            centro_trabajo: 'Centro de Trabajo',
            centros_trabajo: 'Centros de Trabajo',
            activo: 'Activo',
            crearuser: 'Crear usuario',
            fecha_alta: 'F. Alta',
            fecha_baja: 'F. Baja',
            guardado_ok: 'Guardado con éxito',
            generatedPassword: 'Password generado',
            editar: 'Edición de usuario',
            detalle: {
                title: 'Usuario '
            }
        },
        alertas: {
            title: 'Alertas',
            numeroGama: 'Misión',
            centrotrabajo: 'Centro de Trabajo',
            etiquetaMadre: 'Etiqueta Madre',
            fecha: 'Fecha',
            mensaje: 'Mensaje',
            motivo: 'Motivo',
            editar: 'Edición de la alerta',
            detalle: {
                title: 'Alerta '
            }
        },
        combos: {
            combos: 'Opciones personalizadas',
            combo: 'Grupo de opciones',
            editar: 'Editar',
            nuevo: 'Nuevo grupo',
            nombre: 'Nombre de grupo',
            tipo: 'Tipo',
            tipos: {
                simple: 'Simple',
                texto: 'Texto',
                html: 'HTML',
                numero: 'Número'
            },
            cantidad_opciones: 'Cantidad de opciones',
            opciones: 'Opciones',
            nombre_opcion: 'Texto',
            codigo_opcion: 'Código',
            sin_opciones: '** No hay ninguna opción definida en el grupo',
            nueva_opcion: 'Nueva opción',
            combo_guardado: 'Grupo de opciones guardado correctamente'
        },
        documentos: {
            documentos: 'Documentos',
            documento: 'Documento',
            editar: 'Editar',
            nuevo: 'Nuevo documento',
            tiposDocumento: 'Tipos de documento',
            tipoDocumento: 'Tipo de documento',
            path: 'Ruta',
            nombre: 'Nombre',
            archivo: 'Archivo',
            plantilla: 'Plantilla',
            importarPlantilla: 'Importar plantilla',
            descargarPDFReferencias: 'Descargar PDF de referencias',
            expedientePrueba: 'Expediente de prueba',
            probarPlantilla: 'Probar plantilla',
            fichero_existe_confirmacion: 'Guardar el fichero sobreescribirá el fichero que existe actualmente en la ruta indicada. ¿Desea continuar?',
            alerta_sin_fichero: 'No se puede guardar sin haber incluido un archivo.',
            nueva_opcion: 'Nueva opción',
            documento_guardado: 'Documento guardado correctamente',
            camposPdf: {
                tipo: 'Tipo',
                nombre: 'Campo',
                valor: 'Valor'
            }
        },
        gamas: {
            title: 'Gamas',
            editar: 'Editar',
            cerrar: 'Cerrar',
            abrir: 'Abrir',
            nombre: 'Nombre excel',
            referencias: 'Referencias',
            equipamiento_estandar: 'Eq. estandar',
            equipamiento_especifico: 'Eq. específico',
            zona_seleccion: 'Zona de Selección',
            listado_defectos: 'Listado de defectos',
            descripcion_defecto: 'Descripción defecto',
            descripcion_trabajo: 'Descripción del trabajo',
            fecha_carga: 'F. Carga',
            fecha_inicio: 'F. Inicio',
            fecha_fin: 'F. Fin',
            fecha_fin_prevista: 'F. Prevista Fin',
            numero: 'Núm. Misión',
            tipoSeleccion: 'Tipo Selección',
            denominacion: 'Denominación',
            destino: 'Destino',
            modelo: 'Modelo',
            cg: 'CG',
            eSign: 'eSign',
            categoria: 'Categoría',
            proveedor: 'Proveedor',
            tqf: 'TQF',
            nombre_peticionario: 'Peticionario',
            telefono: 'Teléfono',
            nuevo: 'Nueva',
            importar: 'Importar',
            codigo: 'Código',
            estado: 'Estado',
            gama_guardada: 'Gama guardada correctamente',
            mensaje_gama_cerrada: 'Gama cerrada correctamente',
            mensaje_gama_reabierta: 'Gama abierta correctamente',
            mensaje_cerrar_gama: 'Al cerrar una gama, el sistema no dejará hacer ningún trabajo en UMs con ella.',
            mensaje_abrir_gama: 'Abrir una gama nos permitirá volver a realizar trabajos en UMs con ella y activará la opción de editar.',
            tarifa: 'Tarifa',
            centrotrabajo: 'Centro de Trabajo',
            wo: 'Orden de Trabajo',
            nombreCliente: 'Cliente',
            operativos: 'Operativos',
            porcOperativos: '%Operativos',
            detalle: {
                title: 'Detalles de la Gama',
                PJI_arranque: 'PJI (Arranque)',
                PJI_corte: 'PJI (corte)',
                pais: 'Pais',
                departamento: 'Departamento',
                centrogasto: 'Centro de Gasto',
                justificacion: 'Justificación / Observaciones',
                fabricante: 'Fabricante',
                deroutage: 'Deroutage',
                auditadaSQF: 'Auditada SQF',
                addhoras: 'Añadir horas',
                addenvio: 'Añadir envío',
                addvale: 'Añadir vale Phoenix',
                addesign: 'Añadir e-Sign',
                aIntervenir: "Intervenir",
                noMostrarPortalCliente: "No mostrar en portal cliente",
                horas: {
                    title: 'Horas',
                    numeroGama: 'Nº Misión',
                    zona: 'Zona',
                    fecha: 'Fecha',
                    horasDia: 'Horas Día',
                    horasNoche: 'Horas Noche',
                    horasFestivoDia: 'H. Fest. Día',
                    horasFestivoNoche: 'H. Fest. Noche',
                    horasDomingoDia: 'H. Dom. Día',
                    horasDomingoNoche: 'H. Dom. Noche',
                    horasCarretilleroDia: 'H. Carr. Día',
                    horasCarretilleroNoche: 'H. Carr. Noche',
                    horasCarretilleroFestivoDia: 'H. Carr. Fest. Día',
                    horasCarretilleroFestivoNoche: 'H. Carr. F. Noche',
                    horasCarretilleroDomingoDia: 'H. Carr. Dom. Día',
                    horasCarretilleroDomingoNoche: 'H. Carr. Dom. Noche',
                },
                movimientos: {
                    title: 'Movimientos',
                    fecha: 'Fecha',
                    movimientos: 'Movimientos',
                    observaciones: 'Observaciones'
                },
                envios: {
                    title: 'Envíos',
                    numeroGama: 'Nº Misión',
                    planta: 'Planta',
                    lugarRecogida: 'Lugar de Recogida',
                    responsableEnvio: 'Responsable Envío',
                    fechaEnvio: 'Fecha de Envío',
                    horaEnvio: 'Hora de Envío',
                    tracking: 'Tracking',
                    fechaLlegadaPrevista: 'Fecha Llegada Prevista',
                    fechaLlegadaReal: 'Fecha Llegada Real',
                    tiempoEnvio: 'Tiempo Envío',
                    estado: 'Estado',
                    observaciones: 'Observaciones'
                },
                vales: {
                    title: 'Vales Phoenix',
                    numeroGama: 'Nº Misión',
                    fecha: 'Fecha',
                    cantidad: 'Cantidad',
                    turno: 'Turno',
                    movimiento: 'Movimiento',
                    lugarDeteccion: 'Lugar Detección',
                    numeroVale: 'Nº Vale',
                    fechaDictamen: 'Fecha Dictamen',
                    diasDictamen: 'Días Dictamen',
                    agrupacion: 'Agrupación'
                },
                esign: {
                    title: 'e-Sign',
                    numeroGama: 'Nº Misión',
                    numeroPedido: 'Nº Pedido',
                    numeroEsign: 'Nº e-Sign',
                    fechaApertura: 'Fecha Apertura',
                    fechaCierre: 'Fecha Cierre',
                    actividad: 'Actividad',
                    motivo: 'Motivo',
                    montanteFinalAcumuladoSQF: 'Montante Final Acumulado SQF',
                    montanteFinalAcumuladoCLA: 'Montante Final Acumulado CLA',
                    albaran: 'Albarán',
                    comentarios: 'Comentarios'
                }
            },
            popupvalegama: { vale_guardado: 'Vale phoenix guardado correctamente' },
            popupesigngama: { esing_guardado: 'e-Sign guardado correctamente' }
        },
        selecciones: {
            title: "UMs",
            nuevo: 'Nueva',
            numeroGama: "Gama",
            categoria: "Categoría",
            etiqueta: "Etiqueta",
            PJI: "PJI",
            dateLabel: "Fecha Etiqueta",
            bl: "BL",
            nOrden: "Nº Orden",
            etiquetaGalia: "Galia",
            etiquetaMadre: "Madre",
            etiquetaActual: "Etiqueta",
            tipo: "Tipo",
            tipo_elemento: "Tipo de etiqueta",
            referencia: "Referencia",
            ubicacion: "Ubicación",
            abierta: "Abierta",
            enVehiculo: "En Línea",
            cerrada: "Cerrada",
            repetida: "Repetida",
            fecha_apertura: "F. Apertura",
            fecha_cierre: "F. Cierre",
            poe: 'POE',
            centrogasto: 'Centro de Gasto',
            fase: 'Fase',
            reabrir: 'Reabrir',
            alertas: 'Mostrar alertas',
            etiquetas: {
                title: "Etiquetas",
            },
            detalle: {
                title: "Detalle de la UM",
                codigo: 'Código',
                trabajos: 'Trabajos',
                denominacion: 'Trabajo a realizar',
                tipo: 'Tipo',
                fecha: 'Fecha',
                turno: 'Turno',
                pji_inicio: 'PJI Ini',
                pji_fin: 'PJI Fin',
                num_orden: 'Nº',
                num_orden_ini: 'Nº Orden Ini',
                num_orden_fin: 'Nº Orden Fin',
                materiales: 'Materiales',
                envios: 'Envíos',
                codOperario: 'Operario',
                campo1: 'Campo 1',
                campo2: 'Campo 2',
                campo3: 'Campo 3',
                campo4: 'Campo 4',
                estado: 'Estado',
                defecto: 'Descripción Defecto',
                numNOK: 'Nº NOK',
                numOK: 'Nº OK',
                recuperadas: 'Nº Recuperadas',
                recuperada: 'Recuperada',
                numPiezasTotal: 'Nº Piezas Real',
                numPiezas: 'Nº Piezas',
                nueva: 'Nueva',
                elementos: {
                    etiqueta: 'Etiqueta',
                    padre: 'Vinculado a',
                    tipo: 'Tipo'
                },
                guardado: 'Modificaciones Guardadas',
                fechajornada: "F. Jornada"
            }
        },
        inspeccionescampa: {
            title: "Inspecciones en campa",
            nuevo: 'Nueva',
            fecha: "Fecha",
            turno: "Turno",
            detalle: {
                title: "Detalle de la Inspección en Campa",
                fecha: 'Fecha',
                turno: 'Turno',
                gamas: 'Gamas',
                numeroGama: 'Nº Misión',
                denominacion: 'Descripción',
                referencias: 'Referencias',
                guardado: 'Modificaciones Guardadas',
                coche: 'Vehículos inspeccionados',
                numero_chasis: 'Nº Chasis',
                numeroOrden: 'Nº Orden',
                referencia: 'Referencia',
                pji: 'PJI',
                numero_fab: 'Nº Fabricación',
                calle: 'Calle',
                motor: 'Motor',
                defectos: 'Defectos',
                resultado: 'Resultado',
                observaciones: 'Observaciones',
            }
        },
        informes: {
            title: 'Informes'
        },
        llegada: {
            title: 'Previsiones de llegada',
            editar: 'Editar',
            nuevo: 'Nueva',
            idPrevisionLlegada: 'Ref. Carga',
            fecha_carga: 'F. Carga',
            nombre: 'Nombre excel',
            usuario_creacion: 'Creador',
            referencia: 'Reference',
            numRAM: 'Nº RAM',
            cuentaProveedor: 'Compte fournisseur',
            motDirecteur: 'Mot directeur (fourn)',
            refTransportista: 'Identifiant transport',
            codigoTGP: 'Code TGP',
            noBL: 'No BL',
            fechaPrevista: 'Date prevue mise a dispo au + tot',
            horaPrevista: 'Heure prevue mise a dispo au + tot',
            cantidad: 'Qte actualisee',
            fechaEntrega: 'Date realisee mise a dispo',
            cantidadEntregada: 'Qte realisee',
            enRuta: 'Indicateur en cours transport',
            um: 'UM',
            uc: 'UC',
            num_lineas: 'Lineas',
            lineas: {
                title: 'Líneas de previsión de llegadas'
            },
            detalle: {
                title: 'Detalle de la llegada',
                nombre: 'Nombre'
            }
        },
        demanda: {
            title: 'J+36',
            editar: 'Editar',
            nuevo: 'Nueva',
            nombre: 'Nombre excel',
            usuario_creacion: 'Creador',
            idPrevisionDemanda: 'Ref. Carga',
            fecha_carga: 'F. Carga',
            fecha_prevision: 'F. prevision',
            codigo: 'Código',
            zona: 'Zona',
            consumo: 'Consumo',
            consProgr: 'Cons. Progr.',
            referencia: 'Referencia',
            denominacion: 'Denominación',
            PDT: 'PDT',
            UC: 'UC',
            UM: 'UM',
            dotMax: 'DOT_MAX',
            numPieUc: 'Nº PIE UC',
            numPieUm: 'Nº PIE UM',
            ucsDia: 'UC\'s DIA',
            umsDia: 'UM\'s DIA',
            resto: 'Resto',
            c00: '00',
            c02: '02',
            c04: '04',
            suma: 'Suma',
            diferencia: 'DIFERENCIA',
            pais_guardado: 'Datos guardados correctamente',
            engamaactiva: 'En Gama',
            lineas: {
                title: 'Líneas J+36'
            },
            detalle: {
                title: 'Detalle de la línea J+36',
                nombre: 'Nombre de la carga (por defecto el del fichero)',
                fecha_carga: 'Fecha de la carga',
                fecha_prevision: 'Fecha de previsión',
                usuario_creacion: 'Creación',
                guardado: 'Cambios guardados con éxito'
            }
        },
        reactividad: {
            title: 'Informes de reactividad',
            editar: 'Editar',
            nuevo: 'Nueva',
            nombre: 'Nombre excel',
            usuario_creacion: 'Creador',
            idReactividad: 'Ref. Carga',
            fecha_carga: 'F. Carga',
            lineas: {
                title: 'Líneas de informes de reactividad',
                numero_chasis: 'Nº Chasis',
                le: 'LE',
                hora_emon: 'Hora Emon',
                numero_orden: 'Nº Orden',
                pji: 'PJI',
                numero_fab: 'Nº Fab',
                calle: 'Calle',
                motor: 'Motor',
                resultado: 'Resultado'
            },
            detalle: {
                title: 'Detalle de la línea de informe de reactividad',
                nombre: 'Nombre de la carga (por defecto el del fichero)',
                fecha_carga: 'Fecha de la carga',
                usuario_creacion: 'Creación',
                guardado: 'Cambios guardados con éxito'
            }
        },
        tarifas: {
            title: 'Tarifas',
            editar: 'Editar',
            cerrar: 'Cerrar',
            abrir: 'Abrir',
            estado: 'Estado',
            codigo: 'Código',
            nombre: 'Nombre',
            nueva: 'Nueva',
            precioDia: 'Día',
            precioNoche: 'Noche',
            precioDomingoDia: 'Domingo Día',
            precioDomingoNoche: 'Domingo Noche',
            precioFestivoDia: 'Festivo Día',
            precioFestivoNoche: 'Festivo Noche',
            precioCarretilleroDia: 'Carretillero Día',
            precioCarretilleroNoche: 'Carret. Noche',
            precioCarretilleroDomingoDia: 'Carret. Dom. Día',
            precioCarretilleroDomingoNoche: 'Carret. Dom. Noche',
            precioCarretilleroFestivoDia: 'Carret. Fest. Día',
            precioCarretilleroFestivoNoche: 'Carret. Fest. Noche',
            mensaje_cerrar_tarifa: 'Al cerrar una tarifa, el sistema no dejará usarla en nuevos elementos.',
            mensaje_abrir_tarifa: 'Abrir una tarifa, esta aparecerá para poderla asociar a nuevos elementos y no se podrá modificar.',
            tarifa_guardada: 'Tarifa guardada con éxito.',
            title_prices: 'Precios de la tarifa',
            detalle: {
                title: 'Detalles de la Tarifa',
                descripcion: 'Descripción'
            }
        },
        ubicaciones: {
            title: 'Ubicaciones',
            editar: 'Editar',
            cerrar: 'Cerrar',
            nombre: 'Nombre',
            ignorarGalia: 'Ignorar Galia',
            nueva: 'Nueva',
            ubicacion_guardada: 'Ubicación guardada con éxito.',
            detalle: {
                title: 'Detalle de la Ubicación',
                descripcion: 'Descripción'
            }
        },
        centrostrabajo: {
            title: 'Centros de trabajo',
            editar: 'Editar',
            cerrar: 'Cerrar',
            nuevo: 'Nuevo',
            abrir: 'Abrir',
            estado: 'Estado',
            codigo: 'Código',
            nombre: 'Nombre',
            prefijo: 'Prefijo',
            patron: 'Patrón',
            email: 'e-Mail',
            mensaje_cerrar_centrotrabajo: 'Al cerrar un centro de trabajo, el sistema no dejará usarlo en nuevos elementos.',
            mensaje_abrir_centrotrabajo: 'Abrir un centro de trabajo, este podrá ser asociado a nuevos elementos.',
            centrotrabajo_guardada: 'Centro de trabajo guardado con éxito.',
            detalle: {
                title: 'Detalles del Centro de Trabajo',
                descripcion: 'Descripción'
            }
        },
        properties: {
            title: 'Properties',
            editar: 'Editar',
            nuevo: 'Nuevo',
            nombre: 'Nombre',
            valor: 'Valor',
            activo: 'Activo',
            editable: 'Editable',
            detalle: {
                title: 'Detalles la Property'
            }
        },
        jornada: {
            configuracion: {
                title: 'Configuración de jornada',
                nombre: 'Nombre',
                fechaInicio: 'Fecha inicio',
                horaInicio: 'Hora inicio',
                configuracion_guardado: 'Configuración guardada con éxito.',
                nueva: 'Nueva configuración de jornada',
            },
            trabajo: {
                title: 'Jornadas de Trabajo',
                CentroTrabajo: 'Centro de trabajo',
                referencia: 'Referencia',
                fecha: 'Fecha',
                horaInicio: 'Hora inicio',
                llevada: 'Llevada',
                trabajoAsignadoPropuesto: 'Propuesto',
                trabajoAsignado: 'Asignado',
                llevada: 'Llevada',
                trabajoRealizado: 'Realizado',
                abierta: 'Abierta',
                nueva: 'Creación manual de jornada',
                createAll: {
                    title: 'Crear todas - Gama acctiva',
                    message: '¿Está seguro de que desea crear todas las jornadas para referencias de gamas activas?',
                },
                calcular: {
                    title: 'Calcular jornada de trabajo',
                    message: '¿Está seguro de que desea calcular la jornada de trabajo?',
                }
            },
            turno: {
                nombre: 'Nombre',
                horaInicio: 'Hora inicio',
                horaFin: 'Hora fin',
                cargaTrabajo: 'Reparto',
                tramos: 'Tramos',
                nuevo: 'Nuevo',
            },
            tramo: {
                horaInicio: 'Hora inicio',
                horaFin: 'Hora fin',
            }
        },
        tiposdocumentos: {
            tiposdocumentos: 'Tipos de documentos',
            tipodocumento: 'Tipo de documento',
            editar: 'Editar',
            nuevo: 'Nueva',
            tipo: 'Tipo',
            nombre: 'Nombre',
            tipodocumento_guardado: 'Tipo de documento guardado correctamente'
        },

        configuracion: {
            configuracion: 'Configuración',
            politica_privacidad: 'Política de privacidad',
            tema_oscuro: 'Tema oscuro',
            idioma: {
                idioma: 'Idioma',
                espanol: 'Español',
                ingles: 'Inglés'
            }
        },

        rlist: {
            pregunta_eliminar_registro: "¿Está seguro de que desea eliminar este registro?",
            registro_eliminado: "Registro eliminado con éxito",
            n_registros_seleccionados: "{count} registro seleccionado | {count} registros seleccionados",
            n_registros: "{count} registro | {count} registros"
        },
        rform: {
            error_subiendo_archivos: "Error subiendo archivos",
            error_validacion_formulario: "Hay errores en el formulario"
        }
    },

    en: {
        atras: 'Go back',
        cerrar: 'Close',
        aceptar: 'Accept',
        searchBarBuscar: 'Search...',
        searchBarSinResultados: 'No Results...',
        Enero: 'January',
        Febrero: 'February',
        Marzo: 'March',
        Abril: 'April',
        Mayo: 'May',
        Junio: 'June',
        Julio: 'July',
        Agosto: 'August',
        Septiembre: 'September',
        Octubre: 'October',
        Noviembre: 'November',
        Diciembre: 'December',

        modificationDate: 'Modification Date',

        common: {
            cambiarFiltros: 'Change Filters',
            cerrar: 'Close',
            fechaVacia: 'Empty Date',
            fechaRellena: 'Filled Date',
            mesActual: 'Current Month',
            dia: 'Day',
            yes: 'Yes',
            no: 'No',
            desde: 'From',
            hasta: 'To',
            buscar: 'Search',
            anadir: 'Add',
            cargar: 'Load',
            ver: 'View',
            editar: 'Edit',
            duplicar: 'Duplicate',
            guardar: 'Save',
            aceptar: 'Accept',
            guardarComo: 'Save As',
            eliminar: 'Delete',
            cancelar: 'Cancel',
            compartir: 'Share',
            filtro: 'Filter',
            nombre: 'Name',
            bloqueado: 'Locked',
            desbloqueado: 'Unlocked',
            move: 'Move',
            copy: 'Copy',
            paste: 'Paste',
            any: 'Any',
            actualizarTabla: 'Update table data',
            exportarExcel: 'Export to Excel',
            consultasFavoritas: 'Favorite Queries',
            firstSearch: 'Make a search to get results',
            firstSearchTipoEstatico: 'Choose a query to get results',
            noResults: 'Search has no results',
            configurarColumnas: 'Configure columns',
            consultaGuardada: 'Query saved correctly',
            consultaBorrada: 'Query deleted correctly',
            consultaCompartida: 'Query shared correctly',
            filtrar: 'Filter',
            cleanFilter: 'Clean Filter',
            configurarFiltros: 'Configure Filters',
            guardarConsulta: 'Save Query',
            sharedConsulta: 'Share Query',
            sobreescribir: 'Overwrite',
            cambiosmasivos: 'Massive Changes',
            actualizarMasivamente: 'Update massively',
            desmarcarSeleccionados: 'Uncheck Selected',
            configurarAgregaciones: 'Configure Aggregations',
            ver: 'View',
            sin: 'Without',
            borrarFiltro: "Delete Filter",
            today: "Today",
            consultasComandos: "Choose a query",
            mostrar: "Show",
            ocultar: "Hide",
            nueva: "New",
        },
        main_menu: {
            inicio: 'Home',
            alertas: 'Alerts',
            selecciones: 'UMs',
            inspecciones_campa: 'Inspecciones Campa',
            reactividad: 'Informes de Reactividad',
            cargas_reactividad: 'Cargas',
            lineas_reactividad: 'Lineas',
            gamas: 'Gamas',
            tarifas: 'Tarifas',
            ubicaciones: 'Ubicaciones',
            cts: 'Centros de Trabajo',
            llegadas: 'Previsión de llegadas',
            cargas_llegadas: 'Cargas',
            lineas_llegadas: 'Líneas',
            demandas: 'J+36',
            cargas_demandas: 'Cargas',
            lineas_demandas: 'Líneas',
            ajustes: 'Ajustes',
            plantillasImportacion: 'Plantillas de importación',
            empleados: 'Usuarios',
            dispositivos: 'Dispositivos',
            combos: 'Combos',
            cerrar_sesion: 'Cerrar sesión',
            notificaciones: 'Notificaciones',
            informes: 'Informes',
            jornada: {
                configuracion: 'Configuración de jornada',
                trabajo: 'Jornadas de trabajo',
            },
            properties: 'Propiedades'
        },
        notificaciones: {
            notificaciones: 'Notifications',
            nuevo: 'New notification',
            usuario: 'User',
            titulo: 'Title',
            mensaje: 'Message',
            urlInterna: 'Internal URL',
            urlExterna: 'External URL',
            enviarATodos: 'Send to all'
        },
        dispositivo: {
            dispositivos: 'Devices',
            uuid: 'UUID',
            activo: 'Active',
            manufacturer: 'Manufacturer',
            model: 'Model',
            platform: 'Platform',
            serial: 'Serial',
            version: 'Version',
            appversion: 'App Version',
            creationDate: 'Creation Date'
        },
        usuatios: {
            login: {
                usuario: 'User',
                contrasena: 'Password',
                entrar: 'Login',
                olvide_mi_contrasena: 'I forgot my password'
            },
            usuarios: {
                usuarios: 'Users'
            },
            perfil: {
                perfil: 'Profile',
                actual_pass: 'Current Password',
                new_pass: 'New Password',
                repeat_new_pass: 'Repeat New Password',
                codigoacceso: 'Access Code',
                cambiarpass: 'Change password'
            },
            usuario_form: {
                username: 'Username',
                nombre: 'Name',
                apellidos: 'Lastname',
                telefono: 'Phone',
                email: 'Email',
                iniciales: 'Initials',
                roles: 'Roles',
                usuario: 'User',
                editar_usuario: 'Edit user',
                nuevo_usuario: 'New user',
                usuario_guardado: 'User saved',
                rol: 'Role',
                clientes_asociados: 'Associated clients',
                cliente: 'Client',
                add: 'Add',
                CHECK_MAN_data: 'CHECK_MAN data',
                foto: 'Select Photo',
                firma: 'Signature',
                select_firma: 'Select Signature'
            }
        },
        evaluadorjs: {
            evaluadoresjs: 'Evaluators JS',
            evaluadorjs: 'Evaluator JS',
            add: 'Add',
            editar: 'Edit',
            nuevo: 'New',
            nombre: 'Name',
            js: 'JS Code',
            ambito: 'Scope',
            guardado_ok: 'Evaluator JS saved correctly'
        },
        empleados: {
            title: 'Users',
            nuevo: 'New',
            username: 'User',
            codOperario: 'Cod. Operario',
            nombre: 'Name',
            dni: 'D.N.I.',
            email: 'e-Mail',
            telefono: 'Phone',
            rol: 'Role',
            centro_trabajo: 'Work Center',
            centros_trabajo: 'Work Centers',
            activo: 'Active',
            crearuser: 'Create user',
            fecha_alta: 'Creation Date',
            fecha_baja: 'End Date',
            guardado_ok: 'Saved successfully',
            generatedPassword: 'Generated password',
            editar: 'Edit user',
            detalle: {
                title: 'User '
            }
        },
        alertas: {
            title: 'Alerts',
            numeroGama: 'Mission',
            etiquetaMadre: 'Mother Label',
            fecha: 'Date',
            mensaje: 'Message',
            motivo: 'Reason',
            editar: 'Edit alert',
            detalle: {
                title: 'Alert '
            }
        },
        combos: {
            combos: 'Custom options',
            combo: 'Options group',
            editar: 'Edit',
            nuevo: 'New group',
            nombre: 'Group name',
            tipo: 'Type',
            tipos: {
                simple: 'Simple',
                texto: 'Text',
                html: 'HTML',
                numero: 'Number'
            },
            cantidad_opciones: 'Number of options',
            opciones: 'Options',
            nombre_opcion: 'Text',
            codigo_opcion: 'Code',
            sin_opciones: '** There are no options defined in the group',
            nueva_opcion: 'New option',
            combo_guardado: 'Options group saved correctly'
        },
        documentos: {
            documentos: 'Documents',
            documento: 'Document',
            editar: 'Edit',
            nuevo: 'New document',
            tiposDocumento: 'Document types',
            tipoDocumento: 'Document type',
            path: 'Path',
            nombre: 'Name',
            archivo: 'File',
            plantilla: 'Template',
            importarPlantilla: 'Import template',
            descargarPDFReferencias: 'Download PDF of references',
            expedientePrueba: 'Test file',
            probarPlantilla: 'Test template',
            fichero_existe_confirmacion: 'Saving the file will overwrite the file that currently exists in the indicated path. Do you want to continue?',
            alerta_sin_fichero: 'You cannot save without having included a file.',
            nueva_opcion: 'New option',
            documento_guardado: 'Document saved correctly',
            camposPdf: {
                tipo: 'Type',
                nombre: 'Field',
                valor: 'Value'
            }
        },
        gamas: {
            title: 'Gamas',
            editar: 'Edit',
            cerrar: 'Close',
            abrir: 'Open',
            nombre: 'Excel name',
            referencias: 'References',
            equipamiento_estandar: 'Standard equipment',
            equipamiento_especifico: 'Specific equipment',
            zona_seleccion: 'Selection zone',
            listado_defectos: 'List of defects',
            descripcion_defecto: 'Description of the defect',
            descripcion_trabajo: 'Description of the work',
            fecha_carga: 'Load Date',
            fecha_inicio: 'Start Date',
            fecha_fin: 'End Date',
            fecha_fin_prevista: 'Expected End Date',
            numero: 'Mission No.',
            tipoSeleccion: 'Selection Type',
            denominacion: 'Denomination',
            destino: 'Destination',
            modelo: 'Model',
            cg: 'CG',
            eSign: 'eSign',
            categoria: 'Category',
            proveedor: 'Supplier',
            nombre_peticionario: 'Petitioner',
            telefono: 'Phone',
            nuevo: 'New',
            importar: 'Import',
            codigo: 'Code',
            estado: 'State',
            gama_guardada: 'Gama saved correctly',
            mensaje_cerrar_gama: 'When closing a range, the system will not allow you to do any work in UMs with it.',
            mensaje_abrir_gama: 'Opening a range will allow us to do work again in UMs with it and will activate the edit option.',
            tarifa: 'Tariff',
            centrotrabajo: 'Work Center',
            wo: 'Work Order',
            nombreCliente: 'Client',
            operativos: 'Operatives',
            porcOperativos: '%Operatives',
            detalle: {
                title: 'Details of the Range',
                PJI_arranque: 'PJI (Start)',
                PJI_corte: 'PJI (cut)',
                pais: 'Country',
                departamento: 'Department',
                centrogasto: 'Cost Center',
                justificacion: 'Justification / Comments',
                fabricante: 'Manufacturer',
                deroutage: 'Deroutage',
                auditadaSQF: 'Audited SQF',
                addhoras: 'Add hours',
                addenvio: 'Add shipment',
                addvale: 'Add Phoenix voucher',
                addesign: 'Add e-Sign',
                aIntervenir: "Intervene",
                noMostrarPortalCliente: "Do not show in customer portal",
                horas: {
                    title: 'Hours',
                    numeroGama: 'Mission No.',
                    zona: 'Zone',
                    fecha: 'Date',
                    horasDia: 'Hours Day',
                    horasNoche: 'Hours Night',
                    horasFestivoDia: 'H. Fest. Day',
                    horasFestivoNoche: 'H. Fest. Night',
                    horasDomingoDia: 'H. Sun. Day',
                    horasDomingoNoche: 'H. Sun. Night',
                    horasCarretilleroDia: 'H. Carr. Day',
                    horasCarretilleroNoche: 'H. Carr. Night',
                    horasCarretilleroFestivoDia: 'H. Carr. Fest. Day',
                    horasCarretilleroFestivoNoche: 'H. Carr. F. Night',
                    horasCarretilleroDomingoDia: 'H. Carr. Sun. Day',
                    horasCarretilleroDomingoNoche: 'H. Carr. Sun. Night',
                },
                movimientos: {
                    title: 'Movements',
                    fecha: 'Date',
                    movimientos: 'Movements',
                    observaciones: 'Observations'
                },
                envios: {
                    title: 'Shipments',
                    numeroGama: 'Mission No.',
                    planta: 'Plant',
                    lugarRecogida: 'Pickup Location',
                    responsableEnvio: 'Shipping Manager',
                    fechaEnvio: 'Shipping Date',
                    horaEnvio: 'Shipping Time',
                    tracking: 'Tracking',
                    fechaLlegadaPrevista: 'Expected Arrival Date',
                    fechaLlegadaReal: 'Actual Arrival Date',
                    tiempoEnvio: 'Shipping Time',
                    estado: 'State',
                    observaciones: 'Observations'
                },
                vales: {
                    title: 'Phoenix Vouchers',
                    numeroGama: 'Mission No.',
                    fecha: 'Date',
                    cantidad: 'Quantity',
                    turno: 'Shift',
                    movimiento: 'Movement',
                    lugarDeteccion: 'Detection Place',
                    numeroVale: 'Voucher No.',
                    fechaDictamen: 'Report Date',
                    diasDictamen: 'Days Report',
                    agrupacion: 'Grouping'
                },
                esign: {
                    title: 'e-Sign',
                    numeroGama: 'Nº Mission',
                    numeroPedido: 'Nº Order',
                    numeroEsign: 'Nº e-Sign',
                    fechaApertura: 'Opening Date',
                    fechaCierre: 'Closing Date',
                    actividad: 'Activity',
                    motivo: 'Reason',
                    montanteFinalAcumuladoSQF: 'Final Accumulated Amount SQF',
                    montanteFinalAcumuladoCLA: 'Final Accumulated Amount CLA',
                    albaran: 'Delivery Note',
                    comentarios: 'Comments'
                }
            },
            popupvalegama: { vale_guardado: 'Phoenix voucher saved correctly' },
            popupesigngama: { esing_guardado: 'e-Sign saved correctly' }
        },
        selecciones: {
            title: "UMs",
            nuevo: 'New',
            numeroGama: "Range",
            categoria: "Category",
            etiqueta: "Label",
            PJI: "PJI",
            dateLabel: "Label Date",
            bl: "BL",
            nOrden: "Nº Order",
            etiquetaGalia: "Galia",
            etiquetaMadre: "Mother",
            referencia: "Reference",
            ubicacion: "Location",
            abierta: "Open",
            enVehiculo: "In Line",
            cerrada: "Closed",
            repetida: "Repeated",
            fecha_apertura: "Opening Date",
            fecha_cierre: "Closing Date",
            poe: 'POE',
            fase: 'Phase',
            reabrir: 'Reopen',
            alertas: 'Show alerts',
            detalle: {
                title: "UM Details",
                codigo: 'Code',
                trabajos: 'Works',
                denominacion: 'Work to be done',
                tipo: 'Type',
                fecha: 'Date',
                turno: 'Shift',
                pji_inicio: 'PJI Start',
                pji_fin: 'PJI End',
                num_orden: 'Nº',
                num_orden_ini: 'Nº Order Start',
                num_orden_fin: 'Nº Order End',
                materiales: 'Materials',
                envios: 'Shipments',
                codOperario: 'Operator',
                campo1: 'Field 1',
                campo2: 'Field 2',
                campo3: 'Field 3',
                campo4: 'Field 4',
                estado: 'State',
                defecto: 'Description Defect',
                numNOK: 'Nº NOK',
                numOK: 'Nº OK',
                recuperadas: 'Nº Recovered',
                recuperada: 'Recovered',
                numPiezasTotal: 'Nº Pieces Real',
                numPiezas: 'Nº Pieces',
                nueva: 'New',
                elementos: {
                    etiqueta: 'Label',
                    padre: 'Linked to',
                    tipo: 'Type'
                },
                guardado: 'Changes Saved',
                fechajornada: "Journey Date"
            }
        },
        inspeccionescampa: {
            title: "Inspections in camp",
            nuevo: 'New',
            fecha: "Date",
            turno: "Shift",
            detalle: {
                title: "Inspection Details in Camp",
                fecha: 'Date',
                turno: 'Shift',
                gamas: 'Ranges',
                numeroGama: 'Mission No.',
                denominacion: 'Description',
                referencias: 'References',
                guardado: 'Changes Saved',
                coche: 'Inspected Vehicles',
                numero_chasis: 'Nº Chassis',
                numeroOrden: 'Nº Order',
                referencia: 'Reference',
                pji: 'PJI',
                numero_fab: 'Nº Fabrication',
                calle: 'Street',
                motor: 'Engine',
                defectos: 'Defects',
                resultado: 'Result',
                observaciones: 'Observations',
            }
        },
        informes: {
            title: 'Reports'
        },
        llegada: {
            title: 'Arrival Forecasts',
            editar: 'Edit',
            nuevo: 'New',
            idPrevisionLlegada: 'Load Ref.',
            fecha_carga: 'Load Date',
            nombre: 'Excel name',
            usuario_creacion: 'Creator',
            referencia: 'Reference',
            numRAM: 'Nº RAM',
            cuentaProveedor: 'Supplier account',
            motDirecteur: 'Mot directeur (fourn)',
            refTransportista: 'Transport identifier',
            codigoTGP: 'TGP Code',
            noBL: 'No BL',
            fechaPrevista: 'Expected delivery date at + soon',
            horaPrevista: 'Expected delivery time at + soon',
            cantidad: 'Updated Qty',
            fechaEntrega: 'Date realized delivery',
            cantidadEntregada: 'Qty realized',
            enRuta: 'Indicator in current transport',
            um: 'UM',
            uc: 'UC',
            num_lineas: 'Lines',
            lineas: {
                title: 'Arrival Forecast Lines'
            },
            detalle: {
                title: 'Arrival Details',
                nombre: 'Name'
            }
        },
        demanda: {
            title: 'J+36',
            editar: 'Edit',
            nuevo: 'New',
            nombre: 'Excel name',
            usuario_creacion: 'Creator',
            idPrevisionDemanda: 'Load Ref.',
            fecha_carga: 'Load Date',
            fecha_prevision: 'Forecast Date',
            codigo: 'Code',
            zona: 'Zone',
            consumo: 'Consumption',
            consProgr: 'Cons. Progr.',
            referencia: 'Reference',
            denominacion: 'Denomination',
            PDT: 'PDT',
            UC: 'UC',
            UM: 'UM',
            dotMax: 'DOT_MAX',
            numPieUc: 'Nº PIE UC',
            numPieUm: 'Nº PIE UM',
            ucsDia: 'UC\'s DIA',
            umsDia: 'UM\'s DIA',
            resto: 'Rest',
            c00: '00',
            c02: '02',
            c04: '04',
            suma: 'Sum',
            diferencia: 'DIFFERENCE',
            pais_guardado: 'Data saved correctly',
            engamaactiva: 'In Range',
            lineas: {
                title: 'J+36 Lines'
            },
            detalle: {
                title: 'J+36 Line Details',
                nombre: 'Load name (by default the file\'s)'
            }
        },
        reactividad: {
            title: 'Reactivity Reports',
            editar: 'Edit',
            nuevo: 'New',
            nombre: 'Excel name',
            usuario_creacion: 'Creator',
            idReactividad: 'Load Ref.',
            fecha_carga: 'Load Date',
            lineas: {
                title: 'Reactivity Report Lines',
                numero_chasis: 'Nº Chassis',
                le: 'LE',
                hora_emon: 'Hour Emon',
                numero_orden: 'Nº Order',
                pji: 'PJI',
                numero_fab: 'Nº Fab',
                calle: 'Street',
                motor: 'Engine',
                resultado: 'Result'
            },
            detalle: {
                title: 'Reactivity Report Line Details',
                nombre: 'Load name (by default the file\'s)',
                fecha_carga: 'Load Date',
                usuario_creacion: 'Creation',
                guardado: 'Changes saved successfully'
            }
        },
        tarifas: {
            title: 'Rates',
            editar: 'Edit',
            cerrar: 'Close',
            abrir: 'Open',
            estado: 'State',
            codigo: 'Code',
            nombre: 'Name',
            nueva: 'New',
            precioDia: 'Day',
            precioNoche: 'Night',
            precioDomingoDia: 'Sunday Day',
            precioDomingoNoche: 'Sunday Night',
            precioFestivoDia: 'Holiday Day',
            precioFestivoNoche: 'Holiday Night',
            precioCarretilleroDia: 'Forklift Driver Day',
            precioCarretilleroNoche: 'Forklift Driver Night',
            precioCarretilleroDomingoDia: 'Forklift Driver Sunday Day',
            precioCarretilleroDomingoNoche: 'Forklift Driver Sunday Night',
            precioCarretilleroFestivoDia: 'Forklift Driver Holiday Day',
            precioCarretilleroFestivoNoche: 'Forklift Driver Holiday Night',
            mensaje_cerrar_tarifa: 'When closing a rate, the system will not allow you to use it in new elements.',
            mensaje_abrir_tarifa: 'Opening a rate, it will appear to be associated with new elements and it cannot be modified.',
            tarifa_guardada: 'Rate saved successfully.',
            title_prices: 'Rate prices',
            detalle: {
                title: 'Rate Details',
                descripcion: 'Description'
            }
        },
        centrostrabajo: {
            title: 'Work Centers',
            editar: 'Edit',
            cerrar: 'Close',
            nuevo: 'New',
            abrir: 'Open',
            estado: 'State',
            codigo: 'Code',
            nombre: 'Name',
            prefijo: 'Prefix',
            patron: 'Pattern',
            email: 'e-Mail',
            mensaje_cerrar_centrotrabajo: 'When closing a work center, the system will not allow you to use it in new elements.',
            mensaje_abrir_centrotrabajo: 'Opening a work center, it can be associated with new elements.',
            centrotrabajo_guardada: 'Work center saved successfully.',
            detalle: {
                title: 'Work Center Details',
                descripcion: 'Description'
            }
        },
        paises: {
            title: 'Configuraciones de Paises',
            editar: 'Editar',
            nuevo: 'Nuevo',
            codigo: 'Código ISO',
            nombre: 'Nombre',
            alias: 'Alias',
            plazo: 'Plazo fin previsto (en días)',
            detalle: {
                title: 'Detalle de '
            }
        },
        properties: {
            title: 'Properties',
            editar: 'Edit',
            nuevo: 'New',
            nombre: 'Name',
            valor: 'Value',
            activo: 'Active',
            editable: 'Editable',
            detalle: {
                title: 'Property Details'
            }
        },
        jornada: {
            configuracion: {
                title: 'Workday configuration',
                nombre: 'Name',
                fechaInicio: 'Start Date',
                horaInicio: 'Start Time',
                configuracion_guardado: 'Configuration saved successfully.',
                nueva: 'New workday configuration',
            },
            trabajo: {
                title: 'Workday',
                CentroTrabajo: 'Work Center',
                referencia: 'Reference',
                fecha: 'Date',
                horaInicio: 'Start Time',
                llevada: 'Taken',
                trabajoAsignadoPropuesto: 'Proposed',
                trabajoAsignado: 'Assigned',
                llevada: 'Taken',
                trabajoRealizado: 'Done',
                abierta: 'Open',
                nueva: 'Manual workday creation',
                createAll: {
                    title: 'Create all - Active range',
                    message: 'Are you sure you want to create all workdays for active range references?',
                },
                calcular: {
                    title: 'Calculate workday',
                    message: 'Are you sure you want to calculate the workday?',
                }
            },
            turno: {
                nombre: 'Name',
                horaInicio: 'Start Time',
                horaFin: 'End Time',
                cargaTrabajo: 'Distribution',
                tramos: 'Sections',
                nuevo: 'New',
            },
            tramo: {
                horaInicio: 'Start Time',
                horaFin: 'End Time',
            }
        },
        tiposdocumentos: {
            tiposdocumentos: 'Document types',
            tipodocumento: 'Document type',
            editar: 'Edit',
            nuevo: 'New',
            tipo: 'Type',
            nombre: 'Name',
            tipodocumento_guardado: 'Document type saved correctly'
        },

        configuracion: {
            configuracion: 'Configuration',
            politica_privacidad: 'Privacy policy',
            tema_oscuro: 'Dark theme',
            idioma: {
                idioma: 'Language',
                espanol: 'Spanish',
                ingles: 'English'
            }
        },

        rlist: {
            pregunta_eliminar_registro: "Are you sure you want to delete this record?",
            registro_eliminado: "Record deleted successfully",
            n_registros_seleccionados: "{count} selected record | {count} selected records",
            n_registros: "{count} record | {count} records"
        },
        rform: {
            error_subiendo_archivos: "Error uploading files",
            error_validacion_formulario: "There are errors in the form"
        }
    }
}
export default messages;