import LineasPrevisionLlegadas from './lineasPrevisionLlegadas.vue';
import LineasPrevisionLlegadaForm from './lineasPrevisionLlegada-form.vue';
import RoutesUtils from '../../js/RoutesUtils.js';

var routes = [{
        path: '/lineasPrevisionLlegadas/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('lineasPrevisionLlegadas', ['ADMIN', 'CHECK_MAN'], LineasPrevisionLlegadas),
        keepAlive: true
    },
    {
        path: '/lineasPrevisionLlegadas/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('lineasPrevisionLlegadas/new', ['ADMIN', 'CHECK_MAN'], LineasPrevisionLlegadaForm)
    },
    {
        path: '/lineasPrevisionLlegadas/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('lineasPrevisionLlegadas/edit', ['ADMIN', 'CHECK_MAN'], LineasPrevisionLlegadaForm)
    },
    {
        path: '/lineasPrevisionLlegadas/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('lineasPrevisionLlegadas/view', ['ADMIN', 'CHECK_MAN'], LineasPrevisionLlegadaForm, { editable: false })
    },
];
export default routes;