import DatabaseModel from './DatabaseModel.js';
import CacheService from './Cache.js';

var Tarifas = Object.create(DatabaseModel);

Tarifas.model = 'tarifas';
Tarifas.modelFront = 'tarifas';

Tarifas.importar = function(documento) {
    var self = this;
    var params = {
        url: self.server_zipado + self.prefix + self.model + '/importar/',
        method: 'POST',
        data: documento
    };
    return self.sendRequest(params, false).then((res) => {
        CacheService.clearCache(self.model);
        return res;
    }).catch((error) => {
        var message = typeof error == "string" ? error : error.message;
        app.dialog.alert(message);
    });
}

// Vamos a sobrescribir el método update para que si falla la actualización porque la tarifa se está usando, se pregunte si quiere modificarla igualmente y le marcamos ignorarTarifaEnUso como true
Tarifas.put = async function(params) {
    const self = this;
    if (!params || !params.itemId) {
        app.dialog.alert('itemId not defined' + JSON.stringify(params));
        // reject({ error: 'itemId not defined' });
    }
    var requestParams = {
        url: self.server_zipado + self.prefix + self.model + '/' + params.itemId,
        method: 'PUT',
        data: params.data
    };
    return new Promise((resolve, reject) => {
        self.sendRequest(requestParams, false).then(function(res) {
            CacheService.clearCache(self.model);
            resolve(res);
        }).catch((error) => {
            var message = typeof error == "string" ? error : error.message;
            // Si el mensaje comienza por "Alguna gama está usando la tarifa ", preguntamos si quiere modificarla de todas formas
            if (message.indexOf("Alguna gama está usando la tarifa ") > -1) {
                app.preloader.hide();
                app.dialog.confirm("La tarifa se está usando en algún pedido. ¿Desea modificarla de todas formas?", "Atención", async function() {
                    params.data.ignorarTarifaEnUso = true;
                    Tarifas.put(params).then((res) => {
                        resolve(res);
                    }).catch((error) => {
                        reject(error);
                    });
                });
            } else {
                reject(error);
            }
        });
    });
}

Tarifas.cerrar = function(params) {
    var self = this;
    params = params || {};
    if (!params.itemId) reject({ error: 'itemId not defined' });
    var params = {
        url: self.server_zipado + self.prefix + self.model + '/cerrar/' + params.itemId,
        method: 'GET',
        data: params.data
    };
    return self.sendRequest(params, false).then(function(res) {
        CacheService.clearCache(self.model);
        return res;
    });
}

Tarifas.abrir = function(params) {
    var self = this;
    params = params || {};
    if (!params.itemId) reject({ error: 'itemId not defined' });
    var params = {
        url: self.server_zipado + self.prefix + self.model + '/abrir/' + params.itemId,
        method: 'GET',
        data: params.data
    };
    return self.sendRequest(params, false).then(function(res) {
        CacheService.clearCache(self.model);
        return res;
    });
}

export default Tarifas;