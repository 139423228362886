<template>
  <div v-if="grafica" :style="grafica.style">
    <bar-chart ref="grafica" v-if="grafica.tipo=='BARRAS'" @submit="sendFiltros" :height="grafica.height" :filtros="grafica.filtros" :fields="grafica.fields" :data="grafica.data" :title="grafica.nombre" :nombre="grafica.nombreField" :valor="grafica.valorField">
    </bar-chart>
    <pie-chart ref="grafica" v-else-if="grafica.tipo=='POR_SECTORES'" @submit="sendFiltros" :height="grafica.height" :filtros="grafica.filtros" :data="grafica.data" :title="grafica.nombre" :nombre="grafica.nombreField" :valor="grafica.valorField">
    </pie-chart>
    <radar-chart ref="grafica" v-else-if="grafica.tipo=='RADAR'" @submit="sendFiltros" :height="grafica.height" :filtros="grafica.filtros" :fields="grafica.fields" :data="grafica.data" :title="grafica.nombre" :nombre="grafica.nombreField" :valor="grafica.valorField">
    </radar-chart>
  </div>
</template>

<script>

  import BarChart from "./barChart.vue"
  import PieChart from "./pieChart.vue"
  import RadarChart from "./radarChart.vue"
  import Grafica from "./../../js/db/Grafica"

  export default {
    components: {
      BarChart,
      PieChart,
      RadarChart
    },
    data() {
      return {
        filtros: null
      };
    },
    props: {
      grafica: {
        required: true,
        type: Object
      }
    },
    watch: {
      
    },
    methods: {
      sendFiltros(filtros) {
        const self = this;
        self.filtros = filtros;
        self.reload();
      },
      reload() {
        const self = this;
        Grafica.consulta(self.grafica.codigo, self.filtros).then(res => {
          self.grafica.data = res.list;
          if (self.$refs && self.$refs.grafica) {
            self.$refs.grafica.reloadData(self.grafica.data);
          }
        }).catch(e => self.catchError(e));
      },
      catchError: function(error) {
        console.log(error);
        const self = this;
        const app = self.$f7;
        let ms = error;
        if (error.message) {
          ms = error.message;
        }
        app.dialog.alert(ms);
      }
    },
    mounted: function() {
      const self = this;
      self.reload();

    },
  };
</script>