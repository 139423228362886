// Import Vue
import Vue from 'vue';

// Import Framework7
import Framework7 from 'framework7/framework7-lite.esm.bundle.js';

// Import Framework7-Vue Plugin
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js';

// Import Framework7 Styles
import 'framework7/css/framework7.bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.css';

// Import App Component
import App from '../components/app.vue';

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);


/* VENDOR */
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
import VueFilterDateFormat from './vue-filter-date-format';
Vue.use(VueFilterDateFormat);
import VueFilterDateParse from './vue-filter-date-parse.js'
Vue.use(VueFilterDateParse)
import VueFilterTimeInHoursParse from './vue-filter-time-in-hours-parse.js'
Vue.use(VueFilterTimeInHoursParse)
import VueFilterNumberFormat from './vue-filter-number-format.js'
Vue.use(VueFilterNumberFormat)
import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

import rPage from '../components/rPage.vue'
Vue.component('r-page', rPage);

// Import VTooltip
import VTooltip from 'v-tooltip';
Vue.use(VTooltip);

import ViserVue from 'viser-vue';
Vue.use(ViserVue);

var lang = localStorage.getItem("lang") || 'es';
import messages from './translations.js';
const i18n = new VueI18n({
    locale: lang,
    messages,
});

    /******** */
import VueSignature from "vue-signature-pad";
Vue.use(VueSignature);

Vue.directive('roles', {
    bind: function(el, binding, vnode) {
        el.setAttribute("data-initial-display", el.style.display == null ? "" : el.style.display);
        checkPermission(el, binding.value.roles, binding.value.tipoCliente);
    },
    update: function(el, binding, vnode) {
        checkPermission(el, binding.value.roles, binding.value.tipoCliente);
    }
});

function checkPermission(el, roles, tipoCliente) {
    let user = getUser();
    if (!user || user == null || (user.roles.indexOf("ADMIN") < 0 && roles.filter(r => user.roles.indexOf(r) > -1).length <= 0)) {
        el.style.display = "none";
        el.classList.add("display-none");
    } else {
        if (tipoCliente && user.clientes && user.clientes.filter(c => c.tipoCliente == tipoCliente).length <= 0) {
            el.style.display = "none";
            el.classList.add("display-none");
        } else {
            el.style.display = el.getAttribute("data-initial-display");
            el.classList.remove("display-none");
        }
    }
}

function getUser() {
    var user = localStorage.getItem("user");
    if (typeof user != "undefined" && user) {
        var userObj = JSON.parse(user);
        return userObj;
    }
    return null;
}


// Init App
new Vue({
    el: '#app',
    render: (h) => h(App),
    i18n,
    // Register App Component
    components: {
        app: App
    },
});