import RoutesUtils from '../../js/RoutesUtils.js';
import paises from './paises.vue';
import PropertiyForm from './pais-form.vue';

var routes = [{
        path: '/paises/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('paises', ['ADMIN', 'CHECK_MAN'], paises),
        keepAlive: true
    },
    {
        path: '/paises/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('paises/new', ['ADMIN', 'CHECK_MAN'], PropertiyForm)
    },
    {
        path: '/paises/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('paises/edit', ['ADMIN', 'CHECK_MAN'], PropertiyForm)
    },
    {
        path: '/paises/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('paises/view', ['ADMIN', 'CHECK_MAN'], PropertiyForm, { editable: false })
    },
];
export default routes;