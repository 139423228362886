<template>
  <r-page :page-content="true">
    <f7-navbar :back-link="$t('atras')">
      <f7-nav-title sliding>Cerrar Sesión</f7-nav-title>
      <f7-nav-right>
      </f7-nav-right>
    </f7-navbar>
    <f7-block-title>
      Cerrando sesion...
    </f7-block-title>
      <f7-card class="padding-vertical-half padding-right" style="z-index: 2;">
        Cerrando Sesión....
      </f7-card>
  </r-page>
</template>
<style scoped>
.label-group {
  margin-top: 12px;
  margin-left: 20px;
  font-weight: bold;
}
.btn-cliente {
  width: 200px;
  float: right;
}
</style>
<script>
import Vue from "vue";

export default {
  components: {
  },
  data: function() {
    var self = this;
    return {
    };
  },
  props: {
  },
  computed: {},
  beforeDestroy() {},
  methods: {
  },
  mounted: function() {
    var self = this;
    var app = self.$f7;
    self.$nextTick(() => app.views.main.router.navigate("/login/"));
    
  }
};
</script>