<template>
  <r-page :page-content="true">
    <f7-navbar :back-link="$t('atras')">
      <!-- <f7-nav-title v-if="id && !readonly" sliding>{{$t('gamas.editar')}}</f7-nav-title> -->
      <f7-nav-title v-if="id" sliding>{{$t('gamas.detalle.title') + ' ' + id}}</f7-nav-title>
      <f7-nav-title v-else sliding>{{$t('gamas.nuevo')}}</f7-nav-title>
      <f7-nav-right>
        <f7-button
          v-if="!readonly"
          fill
          icon-f7="floppy_disk"
          class="display-flex"
          @click="$refs.rform.saveItem()"
        >{{$t('common.guardar')}}</f7-button>
        <template slot="right" v-else>
          <f7-button v-roles="{route: $f7route.url, roles: ['ADMIN']}" color="blue" @click="readonly=false">Editar</f7-button>
          <f7-button v-if="$refs.rform && $refs.rform.formData && $refs.rform.formData.estado && $refs.rform.formData.estado.estado !== 'CERRADA'" color="red" @click="deleteItem">Eliminar</f7-button>
        </template>
      </f7-nav-right>
    </f7-navbar>
    <r-form
      ref="rform"
      v-slot="{rform}"
      :viewTooltip="!readonly"
      :itemId="id"
      :beforeSave="beforeSave"
      @save="onSave"
      @loadItem="loadItem"
      :readonly="readonly"
      :dbAdapter="Gamas"
      :documentoAdapter="Documento"
      :defaultData="{valores: [], tipo: 'SIMPLE'}"
    >
      <f7-row no-gap class="list list-form no-hairlines">
        <f7-col width="100" medium="90">
          <f7-card class="padding-vertical-half padding-right">
            <f7-card-content>
              <f7-row no-gap class="list list-form no-hairlines">
                    <r-input
                      class="col-50"
                      floating-label
                      :label="$t('gamas.nombre')"
                      :form="rform"
                      type="text"
                      name="nombre"
                      :readonly="true"
                      :overrideReadonly="true"
                    ></r-input>
                    <r-input
                      class="col-10"
                      floating-label
                      :label="$t('gamas.numero')"
                      :form="rform"
                      type="text"
                      name="numero"
                    ></r-input>
                    <r-input
                      class="col-10"
                      floating-label
                      :label="$t('gamas.fecha_carga')"
                      :form="rform"
                      name="fechaCarga"
                      type="datepicker"
                      :readonly="true"
                      :overrideReadonly="true"
                      :calendar-params="{
                          closeOnSelect: true,
                          routableModals: false,
                          dateFormat: {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                          },
                        }"
                    ></r-input>
                    <r-input
                      class="col-10"
                      floating-label
                      :label="$t('gamas.fecha_inicio')"
                      :form="rform"
                      type="datepicker"
                      name="fechaInicio"
                      :calendar-params="{
                          closeOnSelect: true,
                          routableModals: false,
                          dateFormat: {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                          },
                        }"
                    ></r-input>
                    <r-input
                      class="col-10"
                      floating-label
                      :label="$t('gamas.fecha_fin_prevista')"
                      :form="rform"
                      type="datepicker"
                      name="fechaFinPrevista"
                      :calendar-params="{
                          closeOnSelect: true,
                          routableModals: false,
                          dateFormat: {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                          },
                        }"
                    ></r-input>
                    <r-input
                      class="col-10"
                      floating-label
                      :label="$t('gamas.fecha_fin')"
                      :form="rform"
                      type="datepicker"
                      name="fechaFin"
                      :calendar-params="{
                          closeOnSelect: true,
                          routableModals: false,
                          dateFormat: {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                          },
                        }"
                    ></r-input>
                    <r-input
                      class="col-50"
                      floating-label
                      :label="$t('gamas.referencias')"
                      :form="rform"
                      type="text"
                      name="referencias"
                    ></r-input>
                    <r-input
                      class="col-30"
                      floating-label
                      :label="$t('gamas.denominacion')"
                      :form="rform"
                      type="text"
                      name="denominacion"
                    ></r-input>
                  <r-input
                      class="col-10"
                      floating-label
                      :label="$t('gamas.categoria')"
                      :form="rform"
                      type="text"
                      name="categoria"
                  ></r-input>
                  <r-input
                      class="col-10"
                      floating-label
                      :label="$t('gamas.eSign')"
                      :form="rform"
                      type="text"
                      name="eSign"
                  ></r-input>
                  <r-input
                      class="col-20"
                      floating-label
                      :label="$t('gamas.proveedor')"
                      :form="rform"
                      type="text"
                      name="proveedor"
                  ></r-input>
                  <r-input
                      class="col-20"
                      floating-label
                      :label="$t('gamas.nombre_peticionario')"
                      :form="rform"
                      type="text"
                      name="nombrePeticionario"
                  ></r-input>
                  <r-input
                      class="col-10"
                      floating-label
                      :label="$t('gamas.telefono')"
                      :form="rform"
                      type="text"
                      name="telefono"
                  ></r-input>
                  <!-- <r-input
                      class="col-10"
                      floating-label
                      :label="$t('gamas.zona_seleccion')"
                      :form="rform"
                      type="text"
                      name="zonaSeleccion"
                  ></r-input> -->
                  <r-input
                      class="col-20"
                      floating-label
                      :label="$t('Zonas posibles')"
                      :form="rform"
                      type="text"
                      name="zonasPosibles"
                  ></r-input>
                  <r-input
                    class="col-10"
                    floating-label
                    :label="$t('gamas.destino')"
                    :form="rform"
                    type="text"
                    name="destino"
                  ></r-input>
                  <r-autocomplete
                    class="col-10"
                    :dbAdapter="Tarifas"
                    floating-label
                    :label="$t('gamas.tarifa')"
                    :form="rform"
                    name="tarifa"
                    nameId="codigo"
                    labelProp="nombre"
                    :idProp="null"
                    :allFields="true"
                    :readonly="readonly"
                    :filter="{filter:{'estado': 'ABIERTA'}}"
                  ></r-autocomplete>
                  <f7-list-input
                    class="col-10"
                    floating-label
                    :label="'Acumulado eSign'"
                    :value="acumuladoEsign"
                    autocomplete="off"
                    :readonly="true"
                  >
                  </f7-list-input>
                  <r-autocomplete
                    class="col-10"
                    :dbAdapter="CentrosTrabajo"
                    floating-label
                    :label="$t('gamas.centrotrabajo')"
                    :form="rform"
                    name="centroTrabajo"
                    nameId="codigo"
                    labelProp="nombre"
                    :idProp="null"
                    :allFields="true"
                    :readonly="readonly"
                    :filter="{filter:{'estado.estado': 'ABIERTO'}}"
                    :source_="centroTrabajoSource(rform.formData)"
                  ></r-autocomplete>
                  <r-input
                    class="col-10"
                    floating-label
                    :label="$t('gamas.detalle.departamento')"
                    :form="rform"
                    type="text"
                    name="departamento"
                  ></r-input>
                  <r-input
                    class="col-10"
                    floating-label
                    :label="$t('gamas.modelo')"
                    :form="rform"
                    type="text"
                    name="modelo"
                  ></r-input>
                  <r-input
                    class="col-10"
                    floating-label
                    :label="'TQF'"
                    :form="rform"
                    type="text"
                    name="tqf"
                  ></r-input>
                  <r-input
                    class="col-30"
                    floating-label
                    :label="$t('gamas.descripcion_defecto')"
                    :form="rform"
                    type="text"
                    name="descripcionDefecto"
                  ></r-input>
                  
                  <r-input
                    class="col-10"
                    floating-label
                    :label="$t('gamas.tipoSeleccion')"
                    :form="rform"
                    type="text"
                    name="tipoSeleccion"
                  ></r-input>
                  <r-input
                    class="col-10"
                    floating-label
                    :label="$t('gamas.detalle.PJI_arranque')"
                    :form="rform"
                    type="text"
                    name="pjiArranque"
                  ></r-input>
                  <r-input
                    class="col-10"
                    floating-label
                    :label="$t('gamas.detalle.PJI_corte')"
                    :form="rform"
                    type="text"
                    name="pjiCorte"
                  ></r-input>
                  <r-input
                    class="col-10"
                    floating-label
                    :label="$t('gamas.detalle.fabricante')"
                    :form="rform"
                    type="text"
                    name="fabricante"
                  ></r-input>
                  <r-input
                    class="col-80"
                    floating-label
                    :label="$t('gamas.listado_defectos')"
                    :form="rform"
                    type="text"
                    name="listadoDefectos"
                  ></r-input>
                  <r-input
                    v-roles="{route: $f7route.url, roles: ['ADMIN']}" 
                    class="col-10"
                    floating-label
                    :label="$t('gamas.estado')"
                    :form="rform"
                    type="badge"
                    name="estado.estado"
                    :text="rform.formData && rform.formData.estado && rform.formData.estado.estado?rform.formData.estado.estado:'-' "
                    :color="rform.formData && rform.formData.estado && rform.formData.estado.estado == 'CERRADA'? 'grey':'green'"
                    :decorationConfig="readonly?{}:{
                      icon: rform.formData && rform.formData.estado && rform.formData.estado.estado == 'CERRADA'?'lock_open':'lock',
                      color: rform.formData && rform.formData.estado && rform.formData.estado.estado == 'CERRADA'? 'green':'grey',
                      tooltip: rform.formData && rform.formData.estado && rform.formData.estado.estado == 'CERRADA'? 'Abrir':'cerrar',
                      action: rform.formData && rform.formData.estado && rform.formData.estado.estado == 'CERRADA'? abrir:cerrar
                    }"
                  ></r-input>
                  <r-input outline
                      class="col-100"
                      floating-label
                      :label="$t('gamas.descripcion_trabajo')"
                      :form="rform"
                      type="textarea"
                      name="descripcionTrabajo"
                  ></r-input>
                  <!-- private Boolean wo; -->
                  <r-input
                    class="col-10"
                    floating-label
                    :label="$t('gamas.wo')"
                    :form="rform"
                    type="check"
                    name="wo"
                  ></r-input>
                  <!-- private String nombreCliente; -->
                  <r-input
                    class="col-10"
                    floating-label
                    :label="$t('gamas.nombreCliente')"
                    :form="rform"
                    type="text"
                    name="nombreCliente"
                  ></r-input>
                  <!-- private String pais; -->
                  <r-input
                    class="col-10"
                    floating-label
                    :label="$t('gamas.detalle.pais')"
                    :form="rform"
                    type="text"
                    name="pais"
                  ></r-input>
                  <!-- private String departamento; -->
                  <r-input
                    class="col-20"
                    floating-label
                    :label="'Planta proveedor'"
                    :form="rform"
                    type="text"
                    name="plantaProveedor"
                  ></r-input>
                  <!-- <r-input
                    class="col-20"
                    floating-label
                    :label="$t('gamas.detalle.departamento')"
                    :form="rform"
                    type="text"
                    name="departamento"
                  ></r-input> -->
                  <!-- private String centrogasto; -->
                  <r-input
                    class="col-10"
                    floating-label
                    :label="$t('gamas.detalle.centrogasto')"
                    :form="rform"
                    type="text"
                    name="centrogasto"
                  ></r-input>
                  <!-- private Integer operativos; -->
                  <r-input
                      class="col-10"
                      floating-label
                      :label="$t('gamas.operativos')"
                      :form="rform"
                      type="number"
                      name="operativos"
                  ></r-input>
                  <!-- private BigDecimal porcOperativos; -->
                  <r-input
                      class="col-10"
                      floating-label
                      :label="$t('gamas.porcOperativos')"
                      :form="rform"
                      type="number"
                      name="porcOperativos"
                  ></r-input>
	                <!-- private String deroutage; -->
                  <r-input
                    class="col-10"
                    floating-label
                    :label="$t('gamas.detalle.deroutage')"
                    :form="rform"
                    type="text"
                    name="deroutage"
                  ></r-input>

	                <!-- private String auditadaSQF; -->
                  <r-input
                    class="col-10"
                    floating-label
                    :label="$t('gamas.detalle.auditadaSQF')"
                    :form="rform"
                    type="text"
                    name="auditadaSQF"
                  ></r-input>


                  <!-- private String justificación/observaciones; -->
                  <r-input outline
                      class="col-90"
                      floating-label
                      :label="$t('gamas.detalle.justificacion')"
                      :form="rform"
                      type="textarea"
                      name="justificacion"
                  ></r-input>
                  <img style="width: 117px;" :src="rform.formData.qr"/>

                  <!-- private Boolean aIntervenir; -->
                  <r-input
                    class="col-10"
                    :label="$t('gamas.detalle.aIntervenir')"
                    :form="rform"
                    type="check"
                    name="aIntervenir"
                  ></r-input>
                  <!-- No mostrar en portal del cliente -->
                  <r-input
                    class="col-10"
                    :label="$t('gamas.detalle.noMostrarPortalCliente')"
                    :form="rform"
                    type="check"
                    name="noMostrarPortalCliente"
                  ></r-input>
                  <!-- este div solo lo pongo para que ocupe el espacio libre de los elementos de la línea -->
                  <div class="col-80"></div>

              </f7-row>
            </f7-card-content>
          </f7-card>
          <!-- Documentos con nombre de la gama -->
          <div class="content-documentos">
            <f7-card 
              v-for="(documentosconnombre) in getArrayFromObject(rform.formData.documentosConNombre)" 
              class="elevation-3 padding-vertical-half w-31"  
              :key="'documentosConNombre_' + documentosconnombre.codigo"
            >
              <f7-card-content>
                <f7-list class="list-form">
                  <ul>
                    <f7-row>
                      <f7-col width="100" medium="100">
                        <f7-row>

                        <r-input
                          class="col-60 medium-60"
                          outline
                          floating-label
                          :form="rform"
                          type="text"
                          :name="'documentosConNombre.' + documentosconnombre.codigo + '.nombre'"
                          label="Nombre"
                          :readonly="!rform.formData.documentosConNombre || !rform.formData.documentosConNombre[documentosconnombre.codigo] || !rform.formData.documentosConNombre[documentosconnombre.codigo].editable"
                          :overrideReadonly="true"
                        ></r-input>
                        <r-input
                          class="col-30 medium-30"
                          outline
                          floating-label
                          :form="rform"
                          type="check"
                          :name="'documentosConNombre.' + documentosconnombre.codigo + '.visibleCliente'"
                          label="Visible Cliente"
                        ></r-input>
                        <f7-button
                          v-if="!rform.readonly && rform.formData.documentosConNombre[documentosconnombre.codigo] && rform.formData.documentosConNombre[documentosconnombre.codigo].editable"
                          class="col-10 medium-10"
                          color="red"
                          icon-f7="trash" 
                          v-tooltip="'Eliminar'"
                          @click="eliminarDocumentosConNombre(documentosconnombre.codigo)"
                        ></f7-button>
                        </f7-row>
                        <r-files 
                          :itemHeight="70"
                          :path="'/gamas/' + rform.formData.numero + '/'" 
                          :maxHeight="1080"
                          :maxWidth="1920"
                          :form="rform" 
                          :documentoAdapter="Documento"
                          :name="'documentosConNombre.' + documentosconnombre.codigo + '.documentos'"
                          :tooltip="false"
                          :title="rform.formData.documentosConNombre[documentosconnombre.codigo] ? rform.formData.documentosConNombre[documentosconnombre.codigo].nombre : ''"
                        ></r-files>
                      </f7-col>
                    </f7-row>
                  </ul>
                </f7-list>
              </f7-card-content>
            </f7-card>

            <f7-card v-if="!rform.readonly" class="elevation-3 padding-vertical-half w-31">
              <f7-card-content>
                <f7-button
                  class="add-document-btn"
                  color="green"
                  icon-f7="plus_circle" 
                  v-tooltip="'Nuevos documentos con nombre'"
                  @click="nuevaDocumentacionConNombre()"
                ></f7-button>
              </f7-card-content>
            </f7-card>

          </div>
          <!-- FIN Documentos con nombre de la gama -->
        </f7-col>
        <f7-col width="100" medium="10">
          <r-files 
            path="/gamas/" 
            :form="rform" 
            :documentoAdapter="Documento"
            name="documento" 
            :maxFiles='1' 
            label="Documento" 
            :preview="true"
            :tooltip="false"
            :readonly="true"
            :overrideReadonly="true"
          ></r-files>
            <!-- @loadFile="loadFile"  -->
        </f7-col>
      </f7-row>
      <pre style="display:none;">{{rform.formData}}</pre>








      <!-- Page content listado de líneas de horas, envíos y movimientos-->
      <f7-page-content v-if="id" class="" style="padding-top: 0;">
        <!-- <f7-subnavbar style="background-color: transparent;"> -->
        <div class="margin-horizontal" style="background-color: transparent;">
          <f7-segmented raised class="elevation-6">
            <f7-button
              fill
              tab-link="#tab-horas"
              v-tooltip="'Esta pestaña nos muestra las horas imputadas en esta gama'"
              tab-link-active
              @click="tabActive = 'HORAS'"
            >{{$t('gamas.detalle.horas.title')}}</f7-button>
            <f7-button fill tab-link="#tab-envios" @click="tabActive = 'ENVIOS'">{{$t('gamas.detalle.envios.title')}}</f7-button>
            <f7-button fill tab-link="#tab-vales" @click="tabActive = 'VALES PHOENIX'">{{$t('gamas.detalle.vales.title')}}</f7-button>
            <f7-button fill tab-link="#tab-movimientos" @click="tabActive = 'MOVIMIENTOS'">{{$t('gamas.detalle.movimientos.title')}}</f7-button>
          </f7-segmented>
        </div>
        <!-- </f7-subnavbar> -->
        <f7-tabs class="" ref="tabs"  style="padding-top: 0;">
          <!-- tab de horas -->
          <f7-tab id="tab-horas" class="page-content" tab-active style="padding-top: 0;">
              <!-- rList Table CARD-->
              <r-dynamic-list
                ref="rlist"
                :dbAdapter="LineasHorasGamas"
                :tableCode="'HORAS_GAMA'"
                :primary="['id']"
                :tableMode="true"
                :virtualListParams="{height:32, rowsBefore:60, rowsAfter:60}"
                class="card full-height data-table"
                classContent="card-content full-height"
                :shownFields="['numeroGama']"
                :massiveActions="true"
                :mandatoryFilter ="{numeroGama: id}"
                :filtersNoCounters="['numeroGama']"
                :funcShowCheck="showCheckInList"
                :controlsWidth="90"
                :searchOnInit="true"
              >
                <template v-slot:tableLinks>
                  <f7-button
                    v-if="rform.formData && rform.formData.estado && rform.formData.estado.estado == 'ABIERTA'" 
                    fill
                    icon-f7="pencil_ellipsis_rectangle"
                    class="display-flex"
                    color="green"
                    @click.prevent="popupHorasGamaOpened = true;  selectedHora = {numeroGama: rform.formData.numero, fecha: new Date()};"
                  >{{$t('gamas.detalle.addhoras')}}</f7-button>
                </template>
                <template v-slot:optionsColumn="{item,rlist}">
                  <!-- <f7-link v-tooltip="$t('common.ver')" icon-f7="eye" color="green" :href="`/lineasHorasGama/view/${item.numero}/`"></f7-link> -->
                  <f7-link v-if="rform.formData && rform.formData.estado && rform.formData.estado.estado == 'ABIERTA'" :title="$t('common.editar')"   icon-f7="square_pencil" color="blue" @click="popupHorasGamaOpened = true; selectedHora = item;"></f7-link>
                  <f7-link v-if="rform.formData && rform.formData.estado && rform.formData.estado.estado == 'ABIERTA'" :title="$t('common.eliminar')" icon-f7="trash" color="red" @click="rlist.deleteItem(item.id);"></f7-link>
                </template>
              </r-dynamic-list>
          </f7-tab>
          <!-- tab de envíos -->
          <f7-tab id="tab-envios" class="page-content"  style="padding-top: 0;">
              <!-- rList Table CARD-->
              <r-dynamic-list
                ref="rlistEnvios"
                :dbAdapter="LineasEnvioGamas"
                :tableCode="'ENVIOS_GAMA'"
                :primary="['id']"
                :tableMode="true"
                :virtualListParams="{height:32, rowsBefore:60, rowsAfter:60}"
                class="card full-height data-table"
                classContent="card-content full-height"
                :shownFields="['numeroGama']"
                :massiveActions="true"
                :mandatoryFilter ="{numeroGama: id}"
                :filtersNoCounters="['numeroGama']"
                :funcShowCheck="showCheckInList"
                :controlsWidth="90"
                :searchOnInit="true"
              >
                <template v-slot:tableLinks>
                  <f7-button
                    v-if="rform.formData && rform.formData.estado && rform.formData.estado.estado == 'ABIERTA'" 
                    fill
                    icon-f7="pencil_ellipsis_rectangle"
                    class="display-flex"
                    color="green"
                    @click.prevent="popupEnviosGamaOpened = true"
                  >{{$t('gamas.detalle.addenvio')}}</f7-button>
                </template>
                <template v-slot:optionsColumn="{item,rlist}">
                  <f7-link v-if="rform.formData && rform.formData.estado && rform.formData.estado.estado == 'ABIERTA'" :title="$t('common.editar')" icon-f7="square_pencil" color="blue" @click="popupEnviosGamaOpened = true; selectedEnvio = item;"></f7-link>
                  <f7-link v-if="rform.formData && rform.formData.estado && rform.formData.estado.estado == 'ABIERTA'" :title="$t('common.eliminar')" icon-f7="trash" color="red" @click="rlist.deleteItem(item.id);"></f7-link>
                </template>
              </r-dynamic-list>
          </f7-tab>
          <!-- tab de vales -->
          <f7-tab id="tab-vales" class="page-content" style="padding-top: 0;">
              <!-- rList Table CARD-->
              <r-dynamic-list
                ref="rlistVales"
                :dbAdapter="LineasValesGamas"
                :tableCode="'VALES_GAMA'"
                :primary="['id']"
                :tableMode="true"
                :virtualListParams="{height:32, rowsBefore:60, rowsAfter:60}"
                class="card full-height data-table"
                classContent="card-content full-height"
                :shownFields="['numeroGama']"
                :massiveActions="true"
                :mandatoryFilter ="{numeroGama: id}"
                :filtersNoCounters="['numeroGama']"
                :funcShowCheck="showCheckInList"
                :controlsWidth="90"
                :searchOnInit="true"
              >
                <template v-slot:tableLinks>
                  <f7-button
                    v-if="rform.formData && rform.formData.estado && rform.formData.estado.estado == 'ABIERTA'" 
                    fill
                    icon-f7="pencil_ellipsis_rectangle"
                    class="display-flex"
                    color="green"
                    @click.prevent="popupValesGamaOpened = true"
                  >{{$t('gamas.detalle.addvale')}}</f7-button>
                </template>
                <template v-slot:optionsColumn="{item,rlist}">
                  <f7-link v-if="rform.formData && rform.formData.estado && rform.formData.estado.estado == 'ABIERTA'"  :title="$t('common.editar')" icon-f7="square_pencil" color="blue" @click="popupValesGamaOpened = true; selectedVale = item;"></f7-link>
                  <f7-link v-if="rform.formData && rform.formData.estado && rform.formData.estado.estado == 'ABIERTA'"  :title="$t('common.eliminar')" icon-f7="trash" color="red" @click="rlist.deleteItem(item.id);"></f7-link>
                </template>
              </r-dynamic-list>
          </f7-tab>
          <!-- tab de e-signs -->
          <!-- tab de movimientos -->
          <f7-tab id="tab-movimientos" class="page-content" style="padding-top: 0;">
              <div class="label-group col-100"  style="display: inline-flex;">
                {{$t('gamas.detalle.movimientos.title')}}
                <f7-link
                  v-if="!readonly"
                  @click="nuevoMovimiento"
                  icon-f7="plus_app_fill"
                ></f7-link>
              </div>
              <f7-block-title>
              </f7-block-title>
              <f7-card 
                v-if="$refs.rform && $refs.rform.formData && $refs.rform.formData.movimientosCarretilla"
                class="padding-vertical-half padding-right"
              >
                <f7-row 
                  v-for="(movimiento,indice) in $refs.rform.formData.movimientosCarretilla"
                  :key="'movimientosCarretilla.'+indice"
                  no-gap 
                  class="list list-form no-hairlines"
                >
                  <r-input
                    class="col-10"
                    floating-label
                    :label="$t('gamas.detalle.movimientos.fecha')"
                    :form="rform"
                    type="datepicker"
                    :name="'movimientosCarretilla.'+indice+'.fecha'"
                    :calendar-params="{
                        closeOnSelect: true,
                        routableModals: false,
                        dateFormat: {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit'
                        }
                      }"
                  ></r-input>
                  <r-input
                    class="col-10"
                    floating-label
                    :label="$t('gamas.detalle.movimientos.movimientos')"
                    :form="rform"
                    type="number"
                    :name="'movimientosCarretilla.'+indice+'.movimientos'"
                  ></r-input>
                  <r-input
                    class="col-70"
                    floating-label
                    :label="$t('gamas.detalle.movimientos.observaciones')"
                    :form="rform"
                    type="text"
                    :name="'movimientosCarretilla.'+indice+'.observaciones'"
                  ></r-input>
                  <div style="position: absolute; top: 3px; right: 3px;">
                    <f7-link v-if="!readonly" :title="$t('common.eliminar')" icon-f7="trash" color="red" @click="deleteMovimiento($refs.rform.formData.movimientosCarretilla,indice);"></f7-link>
                  </div>
                </f7-row>
              </f7-card>
          </f7-tab>
        </f7-tabs>
      </f7-page-content>




    </r-form>









    <r-form
      ref="rformHorasGama"
      v-slot="{rform}"
      :readonly="false"
      :dbAdapter="LineasHorasGamas"
      :beforeSave="beforeSaveHorasGama"
      @save="onSaveHorasGama"
      :defaultData="{'numeroGama': id}"
    >
      <f7-popup v-if="popupHorasGamaOpened" ref="popupHorasGama" class="popup-addhoras" @popup:opened="$refs.rformHorasGama.formData = selectedHora ? selectedHora : {}" :opened="true" @popup:closed="popupHorasGamaOpened = false" swipe-to-close>
        <f7-page>
          <f7-navbar title="Horas de la gama">
            <f7-nav-right>
              <f7-link popup-close>Close</f7-link>
            </f7-nav-right>
          </f7-navbar>

          <!-- <div style="height: 100%" class="display-flex justify-content-center align-items-center"> -->
        <f7-card
          class="elevation-3 padding-vertical-half padding-right"
        >
            <f7-card-content>
              <f7-list class="list-form">
                <ul>
                  <f7-row>
                    <f7-col width="100" medium="100">
                      <r-input
                        class="col-10"
                        floating-label
                        :label="$t('gamas.detalle.horas.numeroGama')"
                        :form="rform"
                        type="text"
                        name="numeroGama"
                      ></r-input>
                      <!-- <r-input
                        class="col-10"
                        floating-label
                        :label="$t('gamas.detalle.horas.zona')"
                        :form="rform"
                        type="text"
                        name="zona"
                      ></r-input> -->
                      <r-input
                        class="col-10"
                        floating-label
                        :label="$t('gamas.detalle.horas.fecha')"
                        :form="rform"
                        type="datepicker"
                        name="fecha"
                        :calendar-params="{
                            closeOnSelect: true,
                            routableModals: false,
                            dateFormat: {
                              year: 'numeric',
                              month: '2-digit',
                              day: '2-digit',
                            },
                          }"
                      ></r-input>
                      <r-input
                          class="col-10"
                          floating-label
                          :label="$t('gamas.detalle.horas.horasDia')"
                          :form="rform"
                          type="number"
                          name="horasDia"
                      ></r-input>
                      <r-input
                          class="col-10"
                          floating-label
                          :label="$t('gamas.detalle.horas.horasNoche')"
                          :form="rform"
                          type="number"
                          name="horasNoche"
                      ></r-input>
                      <r-input
                          class="col-10"
                          floating-label
                          :label="$t('gamas.detalle.horas.horasFestivoDia')"
                          :form="rform"
                          type="number"
                          name="horasFestivoDia"
                      ></r-input>
                      <r-input
                          class="col-10"
                          floating-label
                          :label="$t('gamas.detalle.horas.horasFestivoNoche')"
                          :form="rform"
                          type="number"
                          name="horasFestivoNoche"
                      ></r-input>
                      <r-input
                          class="col-10"
                          floating-label
                          :label="$t('gamas.detalle.horas.horasDomingoDia')"
                          :form="rform"
                          type="number"
                          name="horasDomingoDia"
                      ></r-input>
                      <r-input
                          class="col-10"
                          floating-label
                          :label="$t('gamas.detalle.horas.horasDomingoNoche')"
                          :form="rform"
                          type="number"
                          name="horasDomingoNoche"
                      ></r-input>
                      <r-input
                          class="col-10"
                          floating-label
                          :label="$t('gamas.detalle.horas.horasCarretilleroDia')"
                          :form="rform"
                          type="number"
                          name="horasCarretilleroDia"
                      ></r-input>
                      <r-input
                          class="col-10"
                          floating-label
                          :label="$t('gamas.detalle.horas.horasCarretilleroNoche')"
                          :form="rform"
                          type="number"
                          name="horasCarretilleroNoche"
                      ></r-input>
                      <r-input
                          class="col-10"
                          floating-label
                          :label="$t('gamas.detalle.horas.horasCarretilleroFestivoDia')"
                          :form="rform"
                          type="number"
                          name="horasCarretilleroFestivoDia"
                      ></r-input>
                      <r-input
                          class="col-10"
                          floating-label
                          :label="$t('gamas.detalle.horas.horasCarretilleroFestivoNoche')"
                          :form="rform"
                          type="number"
                          name="horasCarretilleroFestivoNoche"
                      ></r-input>
                      <r-input
                          class="col-10"
                          floating-label
                          :label="$t('gamas.detalle.horas.horasCarretilleroDomingoDia')"
                          :form="rform"
                          type="number"
                          name="horasCarretilleroDomingoDia"
                      ></r-input>
                      <r-input
                          class="col-10"
                          floating-label
                          :label="$t('gamas.detalle.horas.horasCarretilleroDomingoNoche')"
                          :form="rform"
                          type="number"
                          name="horasCarretilleroDomingoNoche"
                      ></r-input>
                    </f7-col>
                  </f7-row>
                </ul>
              </f7-list>
            </f7-card-content>
          <f7-row>
            <f7-col>
              <f7-button
                  fill
                  icon-f7="floppy_disk"
                  text="Guardar HorasGama"
                  class="display-flex"
                  color="green"
                  @click="$refs.rformHorasGama.saveItem();"
                ></f7-button>
            </f7-col>
          </f7-row>
        </f7-card>
          <!-- </div> -->
        </f7-page>
      </f7-popup>
    </r-form>




    <!-- popup de envios de la gama -->
    <r-form
      ref="rformEnviosGama"
      v-slot="{rform}"
      :readonly="false"
      :dbAdapter="LineasEnvioGamas"
      :beforeSave="beforeSaveEnvioGama"
      @save="onSaveEnvioGama"
      :defaultData="{'numeroGama': id}"
    >
      <f7-popup v-if="popupEnviosGamaOpened" ref="popupEnvioGama" class="popup-addenvio"  :opened="true" @popup:opened="$refs.rformEnviosGama.formData = selectedEnvio ? selectedEnvio : {}" @popup:closed="popupEnviosGamaOpened = false; selectedEnvio = false;" swipe-to-close>
        <f7-page>
          <f7-navbar title="Envios de la gama">
            <f7-nav-right>
              <f7-link popup-close>Close</f7-link>
            </f7-nav-right>
          </f7-navbar>

        <f7-card
          class="elevation-3 padding-vertical-half padding-right"
        >
            <f7-card-content>
              <f7-list class="list-form">
                <ul>
                  <f7-row>
                    <f7-col width="100" medium="100">
                      <r-input
                        class="col-10"
                        floating-label
                        label="Tracking"
                        :form="rform"
                        type="text"
                        name="tracking"
                      ></r-input>
                      <r-input
                        class="col-10 medium-10 small-100"
                        :floating-label="false"
                        :label="$t('gamas.detalle.envios.fechaEnvio')"
                        :form="rform"
                        name="fechaEnvio"
                        type="datepicker"
                        :calendar-params="{
                            closeOnSelect: true,
                            routableModals: false,
                            dateFormat: {
                              year: 'numeric',
                              month: '2-digit',
                              day: '2-digit',
                            },
                          }"
                      ></r-input>
                      <r-input
                        class="col-10 medium-10 small-100"
                        :floating-label="false"
                        label="Fecha Proveedor"
                        :form="rform"
                        name="fechaProveedor"
                        type="datepicker"
                        :calendar-params="{
                            closeOnSelect: true,
                            routableModals: false,
                            dateFormat: {
                              year: 'numeric',
                              month: '2-digit',
                              day: '2-digit',
                            },
                          }"
                      ></r-input>
                      <r-input
                        class="col-10 medium-10 small-100"
                        :floating-label="false"
                        label="Fecha Análisis"
                        :form="rform"
                        name="fechaAnalisis"
                        type="datepicker"
                        :calendar-params="{
                            closeOnSelect: true,
                            routableModals: false,
                            dateFormat: {
                              year: 'numeric',
                              month: '2-digit',
                              day: '2-digit',
                            },
                          }"
                      ></r-input>
                      <r-input
                        class="col-10"
                        floating-label
                        label="Comentarios"
                        :form="rform"
                        type="text"
                        name="comentarios"
                      ></r-input>
                    </f7-col>
                  </f7-row>
                </ul>
              </f7-list>
            </f7-card-content>
          <f7-row>
            <f7-col>
              <f7-button
                  fill
                  icon-f7="floppy_disk"
                  text="Guardar Envío"
                  class="display-flex"
                  color="green"
                  @click="$refs.rformEnviosGama.saveItem();"
                ></f7-button>
            </f7-col>
          </f7-row>
        </f7-card>
          <!-- </div> -->
        </f7-page>
      </f7-popup>
    </r-form>

    <!-- popup de vales phoenix de la gama -->
    <r-form
      ref="rformValesGama"
      v-slot="{rform}"
      :readonly="false"
      :dbAdapter="LineasValesGamas"
      :beforeSave="beforeSaveValeGama"
      @save="onSaveValeGama"
      :defaultData="{'numeroGama': id}"
    >
      <f7-popup v-if="popupValesGamaOpened" ref="popupValeGama" class="popup-addvale"  :opened="true" @popup:opened="$refs.rformValesGama.formData = selectedVale ? selectedVale : {}"  @popup:closed="popupValesGamaOpened = false; selectedVale = false" swipe-to-close>
        <f7-page>
          <f7-navbar title="Vales Phoenix de la gama">
            <f7-nav-right>
              <f7-link popup-close>Close</f7-link>
            </f7-nav-right>
          </f7-navbar>

          <f7-card
            class="elevation-3 padding-vertical-half padding-right"
          >
            <f7-card-content>
              <f7-list class="list-form">
                <ul>
                  <f7-row>
                    <f7-col width="100" medium="100">
                      <r-input
                        class="col-10"
                        floating-label
                        label="Nº Vale"
                        :form="rform"
                        type="text"
                        name="numeroVale"
                      ></r-input>
                      <r-input
                        class="col-10"
                        floating-label
                        label="Referencia"
                        :form="rform"
                        type="select"
                        name="referencia"
                      >
                        <option selected></option>
                        <option v-for="(item,index) in $refs.rform.formData.referencias.split(' ')"
                          :key="'referencia-'+index" :value="item">{{item}}</option>
                      </r-input>
                      <r-input
                        class="col-10"
                        floating-label
                        label="Motivo"
                        :form="rform"
                        type="text"
                        name="motivo"
                      ></r-input>
                      <r-input
                        class="col-10"
                        floating-label
                        :label="$t('gamas.detalle.vales.cantidad')"
                        :form="rform"
                        type="number"
                        name="cantidad"
                      ></r-input>


                      <li
                        v-if="$refs.rformValesGama.formData && !$refs.rformValesGama.formData.turno"
                        class="col-10 has-tooltip"
                        floating-label=""
                      >
                        <div class="item-content item-input item-input-with-value">
                          <div class="item-inner">
                            <div class="item-title item-label item-floating-label">{{$t('gamas.detalle.vales.turno')}}</div>
                            <div class="item-input-wrap">
                              <template>
                                <f7-chip style="margin:2px;" :text="'Mañana'" :color="rform.formData.turnos && rform.formData.turnos.includes('M')?'green':'gray'" @click="switchTurno('M')"></f7-chip>
                                <f7-chip style="margin:2px;" :text="'Tarde'" :color="rform.formData.turnos && rform.formData.turnos.includes('T')?'green':'gray'" @click="switchTurno('T')"></f7-chip>
                                <f7-chip style="margin:2px;" :text="'Noche'" :color="rform.formData.turnos && rform.formData.turnos.includes('N')?'green':'gray'" @click="switchTurno('N')"></f7-chip>
                              </template>
                            </div>
                          </div>
                        </div>
                      </li>
                      <r-input
                        v-if="$refs.rformValesGama.formData && $refs.rformValesGama.formData.turno"
                        class="col-10"
                        floating-label
                        :label="$t('gamas.detalle.vales.turno')"
                        :form="rform"
                        type="select"
                        name="turno"
                      >
                        <option selected></option>
                        <option :key="'turno-M'" :value="'M'">M</option>
                        <option :key="'turno-T'" :value="'T'">T</option>
                        <option :key="'turno-N'" :value="'N'">N</option>
                      </r-input>

                      <r-input
                        class="col-10"
                        floating-label
                        :label="$t('gamas.detalle.vales.movimiento')"
                        :form="rform"
                        type="text"
                        name="movimiento"
                      ></r-input>
                      <r-input
                        class="col-10"
                        floating-label
                        :label="$t('gamas.detalle.vales.lugarDeteccion')"
                        :form="rform"
                        type="text"
                        name="lugarDeteccion"
                      ></r-input>
                      <r-input
                        class="col-10"
                        floating-label
                        :label="'Días restantes'"
                        :form="rform"
                        type="number"
                        name="diasRestantes"
                      ></r-input>
                      <r-input
                        class="col-10"
                        floating-label
                        :label="$t('gamas.detalle.vales.fechaDictamen')"
                        :form="rform"
                        name="fechaDictamen"
                        type="datepicker"
                        :calendar-params="{
                            closeOnSelect: true,
                            routableModals: false,
                            dateFormat: {
                              year: 'numeric',
                              month: '2-digit',
                              day: '2-digit',
                            },
                          }"
                      ></r-input>
                      <r-input
                        class="col-10"
                        floating-label
                        :label="$t('gamas.detalle.vales.diasDictamen')"
                        :form="rform"
                        type="number"
                        name="diasDictamen"
                      ></r-input>
                      <r-input
                        class="col-10"
                        floating-label
                        :label="$t('gamas.detalle.vales.agrupacion')"
                        :form="rform"
                        type="text"
                        name="agrupacion"
                      ></r-input>
                    </f7-col>
                  </f7-row>
                </ul>
              </f7-list>
            </f7-card-content>
            <f7-row>
              <f7-col>
                <f7-button
                    fill
                    icon-f7="floppy_disk"
                    text="Guardar Vale Phoenix"
                    class="display-flex"
                    color="green"
                    @click="$refs.rformValesGama.saveItem();"
                  ></f7-button>
              </f7-col>
            </f7-row>
          </f7-card>
          <!-- </div> -->
        </f7-page>
      </f7-popup>
    </r-form>

  </r-page>
</template>
<style scoped>
  .label-group {
    margin-top: 12px;
    margin-left: 20px;
    font-weight: bold;
  }
  .label-group-inspeccion {
    margin-top: 30px;
  }
  label.btn {
    padding: 1px;
    cursor: pointer;
    border: 1px solid;
  }
  .valor-row {
    position: relative;
  }
  .valor-codigo {
    padding-left: 3em;
  }
  .valor-controles {
    position:absolute;
    left: 0;
    top: 0;
    z-index: 2;
  }
  .valor-delete {
    position:absolute;
    left: 0;
    top: 7px;
  }
  .valor-reordenar {
    margin-top:2px;
    position:absolute;
    left: 12px;
    top: 11px;
  }
  .tab-link-active {
    background-color: var(--f7-button-hover-bg-color, rgba(var(--f7-theme-color-rgb), 0.07));
  }
  .w-31 {
    width: 31%;
  }
  .content-documentos {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .add-document-btn {
    height: 100%;
  }

</style>
<style>
  .add-document-btn i.f7-icons {
    font-size: 70px;
  }
  #tab-expediente .list .bordeAzul .item-input-outline input,
  #tab-expediente .list .bordeAzul .item-input-outline textarea {
    color: blue;
    padding: 5px;
  }
  #tab-expediente .bordeAzul .item-input-outline .item-input-wrap:after {
    border-color: lightblue;

  }
  #tab-cliente .list .bordeAzul .item-input-outline input {
    color: blue;
  }
  #tab-cliente .bordeAzul .item-input-outline .item-input-wrap:after {
    border-color: lightblue;
  }

</style>
<script>
import Vue from "vue";
import rForm from "../../components/rForm.vue";
import rInput from "../../components/rInput.vue";
import rBadge from './../../components/rBadge';
import rFiles from "./../../components/rFiles.vue";
import Documento from "./../../js/db/Documento.js";
import rAutocomplete from "../../components/rAutocomplete.vue";
import rTimepicker from "../../components/rTimepicker.vue";
import rRichTextInput from "../../components/rRichTextInput.vue";
import { Container, Draggable } from "vue-smooth-dnd";
import Gamas from "../../js/db/Gamas.js";
import Tarifas from "../../js/db/Tarifas.js";
import CentrosTrabajo from "../../js/db/CentrosTrabajo.js";
import rDynamicList from "../../components/rDynamicList.vue";
import LineasHorasGamas from "../../js/db/LineasHorasGamas.js";
import LineasEnvioGamas from "../../js/db/LineasEnvioGamas.js";
import LineasValesGamas from "../../js/db/LineasValesGamas.js";
import Property from "./../../js/db/Property.js";

export default {
  components: {
    rForm,
    rInput,
    rBadge,
    rTimepicker,
    rFiles,
    rRichTextInput,
    rAutocomplete,
    rDynamicList,
    Container,
    Draggable
  },
  data: function() {
    var self = this;
    return {
      Gamas,
      Tarifas,
      CentrosTrabajo,
      LineasHorasGamas,
      LineasEnvioGamas,
      LineasValesGamas,
      Documento,
      Property,
      popupHorasGamaOpened: false,
      popupEnviosGamaOpened: false,
      popupValesGamaOpened: false,
      selectedHora: false,
      selectedEnvio: false,
      selectedVale: false,
      readonly: !this.editable,
      acumuladoEsign: 0,
      documentosOrdenados: []
    };
  },
  props: {
    id: {
      type: String
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  computed: {},
  beforeDestroy() {},
  methods: {
    switchTurno: function(turno) {
      var self = this;
      var app = self.$f7;
      console.log("switchTurno: ", turno);
      if(!self.$refs.rformValesGama.formData.turnos){
        self.$set(self.$refs.rformValesGama.formData, "turnos", []);
      }
      // si el turno está en el array de turnos, lo quitamos
      if(self.$refs.rformValesGama.formData.turnos.indexOf(turno) > -1){
        self.$refs.rformValesGama.formData.turnos.splice(self.$refs.rformValesGama.formData.turnos.indexOf(turno),1);
      } else {
        self.$refs.rformValesGama.formData.turnos.push(turno);
      }
    },
    getArrayFromObject: function(obj) {
      var self = this;
      var arr = [];
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
          arr.push(obj[key]);
        }
      }
      return arr;
    },
    editaItem: function(rform, item) {
      var self = this;
      var app = self.$f7;
      self.popupEnviosGamaOpened = true;
      rform.formData = item;
    },
    deleteItem: function() {
      var self = this;
      var app = self.$f7;
      app.dialog.confirm(
        self.$t("rlist.pregunta_eliminar_registro"),
        "Eliminar",
        function() {
          Gamas.delete({itemId: self.id}).then(function(res) {
            if (res) {
              app.toast
                .create({
                  icon: '<i class="f7-icons">checkmark</i>',
                  text: self.$t("rlist.registro_eliminado"),
                  position: "center",
                  destroyOnClose: true,
                  closeTimeout: 2000
                })
                .open();
              self.$f7router.back()
            }
          }).catch(function(error){
            if(error.message){
              app.dialog.alert(error.message);
            } else {
              app.dialog.alert(error);
            }
          });
        }
      );
    },
    onDropValor: function(arr, dragResult){
      var self = this;
      const { removedIndex, addedIndex, payload } = dragResult;
      if (removedIndex === null && addedIndex === null) return arr;
      if (!arr) arr = [];
      var result = arr;
      let itemToAdd = payload;
      if (removedIndex !== null) {
        itemToAdd = Object.assign({},  result.splice(removedIndex, 1)[0]);
      }
      if (addedIndex !== null) {
        result.splice(addedIndex, 0, Object.assign({}, itemToAdd));
      }
    },
    deleteValor: function(gama, indexValor){
      // gama.valores.splice(indexValor,1);
    },
    deleteMovimiento: function(lista, index) {
      var self = this;
      var app = self.$f7;
      self.$f7.dialog.confirm(
        self.$t("rlist.pregunta_eliminar_registro"),
        "Eliminar",
        function() {
          lista.splice(index,1);
        }
      );
    },
    beforeSave: function(data, resolve, reject) {
      // Si hay movimientos de carretilla en la gama, los ordeno y les pongo en la fecha las 12 del medio dia
      if (data.movimientosCarretilla) {
        data.movimientosCarretilla.sort(function(a, b) {
          return (a.fecha?a.fecha.getTime():0) - (b.fecha?b.fecha.getTime():0);
        });
        data.movimientosCarretilla.forEach(function(movimiento, index) {
          if(movimiento.fecha){
            movimiento.fecha.setHours(12,0,0,0);
          }
        });
      }
      // Si el valor de la propiedad zonasPosibles es de tipo string, lo convierto en array
      if (data.zonasPosibles && typeof data.zonasPosibles == "string") {
        data.zonasPosibles = data.zonasPosibles.split(",");
      }
      resolve(data);
    },
    showCheckInList: function(item) {
      return false; 
      // return item.estado && item.estado.estado && item.estado.estado == "ABIERTA"; 
    },
    abrir: function() {
      var self = this;
      self.$set(self.$refs.rform.formData.estado,'estado','ABIERTA');
    },
    cerrar: function() {
      var self = this;
      self.$set(self.$refs.rform.formData.estado,'estado','CERRADA');
    },
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.$refs.rform.formData = res;
      self.readonly = true;
      app.toast
        .create({
          text: 'Gama guardada correctamente',
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
      
    },
    beforeSaveHorasGama: function(data, resolve, reject) {
      var self = this;
      var o = Object.assign({}, data);
      o.numeroGama = self.$refs.rform.itemId;
      resolve(o);
    },
    onSaveHorasGama: function(res) {
      var self = this;
      var app = self.$f7;
      app.toast.close(".popup-horasgama");
      app.toast
        .create({
          text: self.$t("gamas.popuphorasgama.horas_guardadas"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
      
      self.$refs.popupHorasGama.close();
      self.$refs.rlist.reload();
    },
    beforeSaveEnvioGama: function(data, resolve, reject) {
      var self = this;
      var o = Object.assign({}, data);
      o.numeroGama = self.$refs.rform.itemId;
      resolve(o);
    },
    onSaveEnvioGama: function(res) {
      var self = this;
      var app = self.$f7;
      app.toast.close(".popup-enviogama");
      app.toast
        .create({
          text: "Envío guardado",
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
      
      self.$refs.popupEnvioGama.close();
      self.$refs.rlistEnvios.reload();
    },
    beforeSaveValeGama: function(data, resolve, reject) {
      var self = this;
      var o = Object.assign({}, data);
      o.numeroGama = self.$refs.rform.itemId;
      resolve(o);
    },
    onSaveValeGama: function(res) {
      var self = this;
      var app = self.$f7;
      app.toast.close(".popup-valegama");
      app.toast
        .create({
          text: self.$t("gamas.popupvalegama.vale_guardado"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
      
      self.$refs.popupValeGama.close();
      self.$refs.rlistVales.reload();
    },
    loadItem: function(res) {
      var self = this;
      if(!res.tipo){
        res.tipo = "SIMPLE";
      }

      LineasHorasGamas.getList({filter: {numeroGama: self.id}}, true).then(res2 => {
        console.log(res);
        console.log(res2);
        let horasCarretilleroDia = 0;
        let horasCarretilleroDomingoDia = 0;
        let horasCarretilleroDomingoNoche = 0;
        let horasCarretilleroFestivoDia = 0;
        let horasCarretilleroFestivoNoche = 0;
        let horasCarretilleroNoche = 0;
        let horasDia = 0;
        let horasDomingoDia = 0;
        let horasDomingoNoche = 0;
        let horasFestivoDia = 0;
        let horasFestivoNoche = 0;
        let horasNoche = 0;

        res2.list.forEach(h => {
          horasCarretilleroDia += (h.horasCarretilleroDia || 0);
          horasCarretilleroDomingoDia += (h.horasCarretilleroDomingoDia || 0);
          horasCarretilleroDomingoNoche += (h.horasCarretilleroDomingoNoche || 0);
          horasCarretilleroFestivoDia += (h.horasCarretilleroFestivoDia || 0);
          horasCarretilleroFestivoNoche += (h.horasCarretilleroFestivoNoche || 0);
          horasCarretilleroNoche += (h.horasCarretilleroNoche || 0);
          horasDia += (h.horasDia || 0);
          horasDomingoDia += (h.horasDomingoDia || 0);
          horasDomingoNoche += (h.horasDomingoNoche || 0);
          horasFestivoDia += (h.horasFestivoDia || 0);
          horasFestivoNoche += (h.horasFestivoNoche || 0);
          horasNoche += (h.horasNoche || 0);
        });

        let tarifa = res.tarifa || {};

         
        let acum = 0;
        acum += (tarifa.precioCarretilleroDia || 0) * horasCarretilleroDia;
        acum += (tarifa.precioCarretilleroDomingoDia || 0) * horasCarretilleroDomingoDia;
        acum += (tarifa.precioCarretilleroDomingoNoche || 0) * horasCarretilleroDomingoNoche;
        acum += (tarifa.precioCarretilleroFestivoDia || 0) * horasCarretilleroFestivoDia;
        acum += (tarifa.precioCarretilleroFestivoNoche || 0) * horasCarretilleroFestivoNoche;
        acum += (tarifa.precioCarretilleroNoche || 0) * horasCarretilleroNoche;
        acum += (tarifa.precioDia || 0) * horasDia;
        acum += (tarifa.precioDomingoDia || 0) * horasDomingoDia;
        acum += (tarifa.precioDomingoNoche || 0) * horasDomingoNoche;
        acum += (tarifa.precioFestivoDia || 0) * horasFestivoDia;
        acum += (tarifa.precioFestivoNoche || 0) * horasFestivoNoche;
        acum += (tarifa.precioNoche || 0) * horasNoche;

        self.acumuladoEsign = acum.toFixed(2);

      });

      self.getDocumentosConNombreOrdenados();

    },
    nuevoMovimiento: function() {
      var self = this;
      var app = self.$f7;
      let movimientos = self.$refs.rform.formData.movimientosCarretilla;
      if(!movimientos){
        movimientos = [];
        self.$set(self.$refs.rform.formData,'movimientosCarretilla',movimientos);
      }
      movimientos.push({
        fecha: null,
	      movimientos: 0,
        observaciones: "",
      });
    },
    // Funciones de documentos con nombre
    async getDocumentosConNombreOrdenados() {
      const self = this;
      const app = self.$f7;
      if(self.$refs.rform && self.$refs.rform.formData){
        let documentosConNombre = self.$refs.rform.formData.documentosConNombre;
        documentosConNombre = await self.crearDocumentosConNombre();
        // ordenamos los documentos con nombre por el campo orden y si el valor de orden es null, lo ponemos al final.
        documentosConNombre = Object.values(documentosConNombre).sort((a, b) => { 
          if(a.orden == null) return 1;
          if(b.orden == null) return -1;
          return a.orden - b.orden; 
         });
        let documentosConNombreOrdenados = {};
        documentosConNombre.forEach(function(documentoConNombre) {
          documentosConNombreOrdenados[documentoConNombre.codigo] = documentoConNombre;
        });
        self.$set(self.$refs.rform.formData, "documentosConNombre", documentosConNombreOrdenados);
      }
    },    
    nuevaDocumentacionConNombre: function() {
      const self = this;
      const app = self.$f7;
      let documentosConNombre = self.$refs.rform.formData.documentosConNombre?self.$refs.rform.formData.documentosConNombre:[]
      let orden = Object.keys(documentosConNombre).length == 0 ? 1 : Math.max(...Object.keys(documentosConNombre).filter(x => documentosConNombre[x].orden).map(x => documentosConNombre[x].orden)) + 1;
      if (isNaN(orden)) {
        orden = documentosConNombre.length + 1; 
      }
      let codigo = 'DOC_' + orden;
      self.$set(self.$refs.rform.formData.documentosConNombre, codigo, {codigo: codigo, orden: orden,nombre: '',editable: true,documentos: []});
      return documentosConNombre;
    },
    eliminarDocumentosConNombre: function(codigo) {
      let self = this;
      let app = self.$f7;
      let documentosConNombre = self.$refs.rform.formData.documentosConNombre;
      app.dialog.confirm("¿Está seguro?", "Eliminar documentos con nombre (" + (!!(documentosConNombre && documentosConNombre[codigo] && documentosConNombre[codigo].nombre) ? documentosConNombre[codigo].nombre : "Sin nombre") + ")", function() {
        self.$delete(self.$refs.rform.formData.documentosConNombre, codigo);
      });
    },  
    crearDocumentosConNombre: async function() {
      const self = this;
      const app = self.$f7;
      // Recuperamos la lista de nombres de documentación a presentar.
      let documentosconnombrePredefinidos = await self.recuperarDocumentosConNombrePredefinidos();
      let documentosConNombre = self.$refs.rform.formData.documentosConNombre;
      // Si no tiene documentos con nombre, se le crea el array.
      if(!documentosConNombre) {
        documentosConNombre = {};
        self.$set(self.$refs.rform.formData, "documentosConNombre", documentosConNombre);
      } else {
        // si ya tenemos documentos con nombre, los vamos a poner todos editables (esto es para poderlos eliminar si queremos).
        Object.keys(documentosConNombre).forEach(function(key) {
          self.$set(documentosConNombre[key], "editable", true);
        });
      }
      // Vamos a mirar si tiene todos los documentos con nombre predefinidos.
      documentosconnombrePredefinidos.forEach(function(documentoconnombrePredefinido) {
        // Si no tiene el documento con nombre predefinido, se le agrega.
        if(!documentosConNombre[documentoconnombrePredefinido.codigo]) {
          if (!documentoconnombrePredefinido.documentos) {
            documentoconnombrePredefinido.documentos = [];
          }
          self.$set(documentosConNombre, documentoconnombrePredefinido.codigo, documentoconnombrePredefinido);
        } else {
          // Si tiene el documento con nombre predefinido, se le actualiza el nombre.
          self.$set(documentosConNombre[documentoconnombrePredefinido.codigo], "nombre", documentoconnombrePredefinido.nombre);
          // y también se le actualiza el orden.
          self.$set(documentosConNombre[documentoconnombrePredefinido.codigo], "orden", documentoconnombrePredefinido.orden);
          // y por último se le actualiza el campo editable.
          self.$set(documentosConNombre[documentoconnombrePredefinido.codigo], "editable", documentoconnombrePredefinido.editable);
        }
      });
      return documentosConNombre;
    },
    // recuperar documentos con nombre predefinidos
    recuperarDocumentosConNombrePredefinidos: async function() {
      const self = this;
      const app = self.$f7;
      try {
        let res = await Property.getList({filter: {nombre: 'gama.documentosconnombre.nombres'} });
        return JSON.parse(res.list[0].valor);
      } catch (error) {
        if (error.message) { app.dialog.alert(error.message); } else { app.dialog.alert(error); }
      }
      // return [
      //   // a.	PJI / Número de batería
      //   {codigo: 'DOC_PJI_NUMBAT', orden: 1, nombre: 'PJI / Número de batería', editable: false, documentos: []},
      //   // b.	Denuncia
      //   {codigo: 'DOC_DENUNCIA', orden: 2, nombre: 'Denuncia', editable: false, documentos: []},
      //   // c.	Solicitud Work Order
      //   {codigo: 'DOC_WO', orden: 3, nombre: 'Solicitud Wor Order', editable: false, documentos: []},
      //   // d.	Work Order firmada
      //   {codigo: 'DOC_WD_SIGNED', orden: 4, nombre: 'Work Order firmada', editable: false, documentos: []},
      //   // e.	Solicitud e-Sign
      //   {codigo: 'DOC_ESIGN', orden: 5, nombre: 'Solicitud e-Sign', editable: false, documentos: []},
      //   // f.	e-Sign realizado / validado
      //   {codigo: 'DOC_ESIGN_RV', orden: 6, nombre: 'e-Sign realizado / validado', editable: false, documentos: []}
      // ];
    },
    centroTrabajoSource: function(self) {
      return function(query, render) {
        var results = [];
        debugger;
        for (var i = 0; i < self.items.length; i++) {
          if (( self.items[i] && self.items[i][self.searchProp] && self.items[i][self.searchProp].toLowerCase().indexOf(query.toLowerCase()) >= 0 ) && (!rform.formData.codigoInstalacion || rform.formData.codigoInstalacion == self.items[i].codigoInstalacion)) {
            self.items[i]['txtValue'] = self.items[i]['codigo'] + ' - ' + self.items[i]['nombre'] + ' (' + self.items[i]['prefijo'] + ')';
            results.push(self.items[i]);
          }
        }
        render(results);
      };
    }

  },
  mounted: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;
  }
};
</script>