import DatabaseModel from './DatabaseModel.js';

var Grafica = Object.create(DatabaseModel);

Grafica.model = 'graficas';

Grafica.consulta = function(codigo, filtros) {
    const self = this;
    return new Promise(function(resolve, reject) {
        //var params_ser = '';
        self.request.promise({
            url: self.server_zipado + self.prefix + self.model + '/consulta/',
            method: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true,
            data: {codigo, filtros}
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response).error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
} 

export default Grafica;