import Login from './login.vue';
import Perfil from './perfil.vue';
import Logout from './logout.vue';
// import Perfil from './perfil.vue';
/*import Registro from './registro.vue';
import ForgotPassword from './olvide_contrasena.vue';
import ProfileView from './perfil_view.vue';
import ProfileEdit from './perfil_edit.vue';
//import Notifications from './notificaciones.vue';*/
import RoutesUtils from './../../js/RoutesUtils.js';

var routes = [{
        path: '/login/',
        beforeEnter: function(routeTo, routeFrom, resolve, reject) {
            var self = this;
            if (RoutesUtils.checkAuth()) {
                reject();
                self.app.views.main.router.navigate('/');
            } else {
                resolve();
            }
        },
        popup: {
            component: Login
        }
    },
    {
        path: '/perfil/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('perfil', ['ADMINISTRATIVO', 'CLIENTE', 'CHECK_MAN'], Perfil)
    },
    {
        path: '/logout/',
        component: Logout
    },
];
export default routes;