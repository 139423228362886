<template>
  <r-page>
    <!-- Top Navbar -->
    <f7-navbar :title="$t('jornada.trabajo.title')" :back-link="$t('atras')"></f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height">
      <!-- rList Table CARD-->
      <r-dynamic-list
        ref="rlist"
        :dbAdapter="JornadaTrabajo"
        :tableCode="'JORNADA_TRABAJO'"
        :tableMode="true"
        :virtualListParams="{height:32, rowsBefore:60, rowsAfter:60}"
        class="card full-height data-table"
        classContent="card-content full-height"
        :shownFields="['numero']"
        :massiveActions="true"
        :funcShowCheck="showCheckInList"
        :controlsWidth="90"
        :searchOnInit="true"
      >
        <template v-slot:tableLinks>
          <!-- <a class="link" href="/jornadaTrabajo/new/">{{$t('jornada.trabajo.nueva')}}</a> -->
          <f7-button fill icon-f7="calendar_badge_plus" :text="$t('jornada.trabajo.nueva')" color="gray" class="display-flex" href="/jornadaTrabajo/new/" ></f7-button>
          <f7-button fill icon-f7="burst" :text="$t('jornada.trabajo.createAll.title')" color="green" class="display-flex" @click="createAll" ></f7-button>
        </template>
        <template v-slot:optionsColumn="{item,rlist}">
          <f7-link v-tooltip="$t('common.ver')" icon-f7="eye" color="green" :href="`/jornadaTrabajo/view/${item.id}/`"></f7-link>
          <f7-link v-tooltip="$t('common.editar')" icon-f7="square_pencil" color="blue" :href="`/jornadaTrabajo/edit/${item.id}/`"></f7-link>
          <f7-link v-tooltip="$t('common.delete')" icon-f7="trash" color="red" @click="rlist.deleteItem(item.id);"></f7-link>
        </template>
      </r-dynamic-list>
    </f7-page-content>

  </r-page>
</template>
<style scoped>
  .trabajo-completo {
    background-color: rgba(0, 128, 0, 0.342);
  }
  .trabajo-incompleto {
    background-color: rgba(255, 0, 0, 0.342);
  }
</style>
<script>
import rForm from "../../components/rForm.vue";
import rDynamicList from "../../components/rDynamicList.vue";
import JornadaTrabajo from "../../js/db/jornadaTrabajo.js";
import Utils from "../../js/Utils.js";
import rFiles from "../../components/rFiles.vue";
import Documento from "../../js/db/Documento.js";
export default {
  components: {
    rDynamicList,
    rFiles,
    rForm
  },
  data: function() {
    return {
      JornadaTrabajo,
      Utils,
      Documento
    };
  },
  computed: {},
  methods: {
    showCheckInList: function(item) {
      return false; 
    },
    importar(){
      var self = this;
      var app = self.$f7;
      app.popup.open(".popupimportar");
    },
    onImport: function (res) {
      var self = this;
      var app = self.$f7;
      self.JornadaTrabajo.importar(res.documento).then((res) => {
        self.$set(self.$refs.rformImport.formData,'documento',[]);
        self.$refs.rlist.submitFilterForm();
        app.preloader.hide();
      }).finall;
    },
    onImportError: function (e) {
      var self = this;
      var app = self.$f7;
      app.preloader.hide(); 
      // TODO mensaje error;
    },
    beforeSaveImport: function(data, resolve, reject) {
      var self = this;
      var app = self.$f7;
      var o = Object.assign({}, data);
      o.documento = Array.isArray(o.documento) ? o.documento[0] : o.documento;
      app.preloader.hide();
      resolve(o);
    },
    loadFile: function () {
      var self = this;
      var app = self.$f7;
      self.$refs.rformImport.saveItem();
      app.preloader.show();
    },
    createAll: function() {
      var self = this;
      var app = self.$f7;
      app.dialog.confirm(self.$t('jornada.trabajo.createAll.message'), self.$t('jornada.trabajo.createAll.title'), () => {
        self.JornadaTrabajo.createAll().then((res) => {
          app.preloader.hide();
          self.$refs.rlist.reload();
        });
      });
    }
  },
  mounted: function() {
    var self = this;
    var app = self.$f7;
  }
};
</script>