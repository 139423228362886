<template>
  <r-page>
    <!-- Top Navbar -->
    <f7-navbar :title="$t('centrostrabajo.title')" :back-link="$t('atras')"></f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height">
      <!-- rList Table CARD-->
      <r-dynamic-list
        ref="rlist"
        :dbAdapter="CentrosTrabajo"
        :tableCode="'CTS'"
        :tableMode="true"
        :virtualListParams="{height:32, rowsBefore:60, rowsAfter:60}"
        class="card full-height data-table"
        classContent="card-content full-height"
        :shownFields="['codigo','estado']"
        :massiveActions="true"
        :funcShowCheck="showCheckInList"
        :controlsWidth="110"
        :searchOnInit="true"
      >
        <template v-slot:tableLinks>
          <a class="link" href="/centrostrabajo/new/">{{$t('centrostrabajo.nuevo')}}</a>
          <!-- <f7-link v-tooltip="$t('centrostrabajo.importar')" color="green" @click="importar()">{{$t('centrostrabajo.importar')}}</f7-link> -->
          <!-- <r-form
            class="form-inline"
            ref="rformImport"
            v-slot="{rform}"
            :readonly="false"
            :dbAdapter="null"
            :documentoAdapter="Documento"
            :beforeSave="beforeSaveImport"
            @save="onImport"
            @error="onImportError"
          >
            <r-files path="/centrostrabajo/" :form="rform" name="documento" :maxFiles='1' label="Importar Documento" @loadFile="loadFile" :preview="false"></r-files>
          </r-form> -->
        </template>
        <template v-slot:optionsColumn="{item,rlist}">
          <f7-link v-tooltip="$t('common.ver')" icon-f7="eye" color="green" :href="`/centrostrabajo/view/${item.codigo}/`"></f7-link>
          <f7-link v-if="item.estado && item.estado.estado == 'ABIERTO'" v-tooltip="$t('common.editar')" icon-f7="square_pencil" color="blue" :href="`/centrostrabajo/edit/${item.codigo}/`"></f7-link>
          <f7-link v-if="item.estado && item.estado.estado == 'ABIERTO'" v-tooltip="$t('centrostrabajo.cerrar')" icon-f7="lock" color="blue" @click="cerrar(item.codigo);"></f7-link>
          <f7-link v-if="item.estado && (item.estado.estado == 'CREADO' || item.estado.estado == 'CERRADO')" v-tooltip="$t('centrostrabajo.abrir')" icon-f7="lock_open" color="green" @click="abrir(item.codigo);"></f7-link>
          <f7-link v-if="item.estado && item.estado.estado == 'CREADO'" v-tooltip="$t('common.eliminar')" icon-f7="trash" color="red" @click="rlist.deleteItem(item.codigo);"></f7-link>
        </template>
      </r-dynamic-list>
    </f7-page-content>

  </r-page>
</template>
<script>
import rForm from "../../components/rForm.vue";
import rDynamicList from "../../components/rDynamicList.vue";
import CentrosTrabajo from "../../js/db/CentrosTrabajo.js";
import Utils from "../../js/Utils.js";
import rFiles from "../../components/rFiles.vue";
import Documento from "../../js/db/Documento.js";
export default {
  components: {
    rDynamicList,
    rFiles,
    rForm
  },
  data: function() {
    return {
      CentrosTrabajo,
      Utils,
      Documento
    };
  },
  computed: {},
  methods: {
    showCheckInList: function(item) {
      return false; 
      // return item.estado && item.estado.estado && item.estado.estado == "ABIERTO"; 
    },
    importar(){
      var self = this;
      var app = self.$f7;
      app.popup.open(".popupimportar");
    },
    onImport: function (res) {
      var self = this;
      var app = self.$f7;
      self.CentrosTrabajo.importar(res.documento).then((res) => {
        self.$set(self.$refs.rformImport.formData,'documento',[]);
        self.$refs.rlist.submitFilterForm();
        app.preloader.hide();
      }).finall;
    },
    onImportError: function (e) {
      var self = this;
      var app = self.$f7;
      app.preloader.hide(); 
      // TODO mensaje error;
    },
    beforeSaveImport: function(data, resolve, reject) {
      var self = this;
      var app = self.$f7;
      var o = Object.assign({}, data);
      o.documento = Array.isArray(o.documento) ? o.documento[0] : o.documento;
      app.preloader.hide();
      resolve(o);
    },
    loadFile: function () {
      var self = this;
      var app = self.$f7;
      self.$refs.rformImport.saveItem();
      app.preloader.show();
    },
    cerrar: function(id) {
      var self = this;
      var app = self.$f7;
      self.$f7.dialog.confirm(
        self.$t("centrostrabajo.mensaje_cerrar_centrotrabajo"),
        "Cerrar",
        function() {
          self.CentrosTrabajo
            .cerrar({ itemId: id })
            .then(function(res) {
              if (res) {
                app.toast
                  .create({
                    icon: '<i class="f7-icons">checkmark</i>',
                    text: self.$t("centrostrabajo.mensaje_centrotrabajo_cerrada"),
                    position: "center",
                    destroyOnClose: true,
                    closeTimeout: 2000
                  })
                  .open();
                self.$refs.rlist.refresh();
              }
            })
            .catch(function(error) {
              var message = typeof error == "string" ? error : error.message;
              app.dialog.alert(message);
            });
        }
      );
    },
    abrir: function(id) {
      var self = this;
      var app = self.$f7;
      self.$f7.dialog.confirm(
        self.$t("centrostrabajo.mensaje_abrir_centrotrabajo"),
        "Abrir",
        function() {
          self.CentrosTrabajo
            .abrir({ itemId: id })
            .then(function(res) {
              if (res) {
                app.toast
                  .create({
                    icon: '<i class="f7-icons">checkmark</i>',
                    text: self.$t("centrostrabajo.mensaje_centrotrabajo_reabierta"),
                    position: "center",
                    destroyOnClose: true,
                    closeTimeout: 2000
                  })
                  .open();
                self.$refs.rlist.refresh();
              }
            })
            .catch(function(error) {
              var message = typeof error == "string" ? error : error.message;
              app.dialog.alert(message);
            });
        }
      );
    }

  },
  mounted: function() {
    var self = this;
    var app = self.$f7;
    /*app.dataTable.create({
      el: self.$refs.datatable
    });*/
  }
};
</script>