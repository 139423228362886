<template>
  <base-chart v-bind="$props" v-on="$listeners" >
    <h3 v-if="title && title!=''" slot="title">{{title}}</h3>
    <v-chart slot="chart" :forceFit="true" :height="height" :data="data" :scale="scale">
      <v-tooltip />
      <v-axis :dataKey="axis1Opts.dataKey" :line="axis1Opts.line" :tickLine="axis1Opts.tickLine" :grid="axis1Opts.grid" />
      <v-axis :dataKey="axis2Opts.dataKey" :line="axis2Opts.line" :tickLine="axis2Opts.tickLine" :grid="axis2Opts.grid" />
      <v-legend dataKey="key" marker="circle" :offset="30" />
      <v-coord type="polar" :radius="0.8" />
      <v-line :position="nombre + '*' + valor" color="key" :size="2" />
      <v-point :position="nombre + '*' + valor" color="key" :size="4" shape="circle" />
      <v-area :position="nombre + '*' + valor" color="key" />
    </v-chart>
  </base-chart>
</template>

<script>
  import baseChart from "./baseChart.vue";
  import DataSet from '@antv/data-set';

  export default {
    extends:baseChart,
    components: {
      baseChart
    },
    data() {
      return {
        scale: [{
          dataKey: self.valor,
          min: 0
        }],
        axis1Opts: {
          dataKey: self.nombre,
          line: null,
          tickLine: null,
          grid: {
            lineStyle: {
              lineDash: null
            },
            hideFirstLine: false,
          }
        },
        axis2Opts: {
          dataKey: self.valor,
          line: null,
          tickLine: null,
          grid: {
            type: 'polygon',
            lineStyle: {
              lineDash: null,
            },
          },
        }
      };
    },
    methods: {
      getData(data) {
        const self = this;
        const dv = new DataSet.View().source(data);
        dv.transform({
          type: 'fold',
          fields: self.fields,
          key: 'key',
          value: self.valor,
        });
        return dv.rows;
      }
    },
    props: {
    }
  };
</script>