<template>
  <r-page>
    <!-- Top Navbar -->
    <f7-navbar :title="$t('gamas.title')" :back-link="$t('atras')"></f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height">
      <!-- rList Table CARD-->
      <r-dynamic-list
        ref="rlist"
        :dbAdapter="Gamas"
        :tableCode="'GAMA'"
        :primary="['numero']"
        :tableMode="true"
        :virtualListParams="{height:32, rowsBefore:60, rowsAfter:60}"
        class="card full-height data-table"
        classContent="card-content full-height"
        private BigDecimal horasDia = BigDecimal.ZERO;
        :shownFields="['numero','estado','propietario','horasDia','horasNoche','horasFestivoDia','horasFestivoNoche','horasDomingoDia','horasDomingoNoche','horasCarretilleroDia','horasCarretilleroNoche','horasCarretilleroFestivoDia','horasCarretilleroFestivoNoche','horasCarretilleroDomingoDia','horasCarretilleroDomingoNoche','tarifa']"

        :massiveActions="true"
        :funcShowCheck="showCheckInList"
        :controlsWidth="110"
        :searchOnInit="true"
      >
        <template v-slot:tableLinks>
          <div class="table-links">
            <a class="link display-flex" href="/gamas/new/">{{$t('gamas.nuevo')}}</a>
          </div>
          <!-- <f7-link v-tooltip="$t('gamas.importar')" color="green" @click="importar()">{{$t('gamas.importar')}}</f7-link> -->
          <r-form
            class="form-inline display-flex"
            ref="rformImport"
            v-slot="{rform}"
            :readonly="false"
            :dbAdapter="null"
            :documentoAdapter="Documento"
            :beforeSave="beforeSaveImport"
            @save="onImport"
            @error="onImportError"
          >
            <r-files path="/gamas/" :form="rform" name="documento" :maxFiles='1' label="Importar Documento" @loadFile="loadFile" :preview="false"></r-files>
          </r-form>
        </template>
        <template v-slot:optionsColumn="{item,rlist}">
          <f7-link v-tooltip="$t('common.ver')" icon-f7="eye" color="green" :href="`/gamas/view/${item.numero}/`"></f7-link>
          <f7-link v-if="item.estado && item.estado.estado != 'CERRADA'" v-tooltip="$t('common.editar')" icon-f7="square_pencil" color="blue" :href="`/gamas/edit/${item.numero}/`"></f7-link>
          <f7-link v-roles="{route: $f7route.url, roles: ['ADMIN']}" v-if="item.estado && item.estado.estado != 'CERRADA'" v-tooltip="$t('gamas.cerrar')" icon-f7="lock" color="blue" @click="cerrar(item.numero);"></f7-link>
          <f7-link v-roles="{route: $f7route.url, roles: ['ADMIN']}" v-if="item.estado && item.estado.estado == 'CERRADA'" v-tooltip="$t('gamas.abrir')" icon-f7="lock_open" color="green" @click="abrir(item.numero);"></f7-link>
          <f7-link v-if="puedeVerla(item)" v-tooltip="$t('common.eliminar')" icon-f7="trash" color="red" @click="rlist.deleteItem(item.numero);"></f7-link>
        </template>
      </r-dynamic-list>
    </f7-page-content>

  </r-page>
</template>
<script>
import rForm from "./../../components/rForm.vue";
import rDynamicList from "./../../components/rDynamicList.vue";
import Gamas from "./../../js/db/Gamas.js";
import Utils from "./../../js/Utils.js";
import rFiles from "./../../components/rFiles.vue";
import Documento from "./../../js/db/Documento.js";
export default {
  components: {
    rDynamicList,
    rFiles,
    rForm
  },
  data: function() {
    return {
      Gamas,
      Utils,
      Documento
    };
  },
  computed: {},
  methods: {
    showCheckInList: function(item) {
      return false; 
      // return item.estado && item.estado.estado && item.estado.estado == "ABIERTA"; 
    },
    puedeVerla(item){
      var self = this;
      var app = self.$f7;
      let user = Utils.getUser();
      let puedeVerla = false;
      if((user.roles.includes("ADMIN") || user.username == item.propietario)) {
        puedeVerla = (item.estado && item.estado.estado == "ABIERTA");
      }
      return puedeVerla;
    },
    importar(){
      var self = this;
      var app = self.$f7;
      app.popup.open(".popupimportar");
    },
    onImport: function (res) {
      var self = this;
      var app = self.$f7;
      self.Gamas.importar(res.documento).then((res) => {
        self.$set(self.$refs.rformImport.formData,'documento',[]);
        self.$refs.rlist.submitFilterForm();
        app.preloader.hide();
      }).finall;
    },
    onImportError: function (e) {
      var self = this;
      var app = self.$f7;
      app.preloader.hide(); 
      // TODO mensaje error;
    },
    beforeSaveImport: function(data, resolve, reject) {
      var self = this;
      var app = self.$f7;
      var o = Object.assign({}, data);
      o.documento = Array.isArray(o.documento) ? o.documento[0] : o.documento;
      app.preloader.hide();
      resolve(o);
    },
    loadFile: function () {
      var self = this;
      var app = self.$f7;
      self.$refs.rformImport.saveItem();
      app.preloader.show();
    },
    cerrar: function(id, comprobarbl = true) {
      const self = this;
      const app = self.$f7;
      self.$f7.dialog.confirm(
        self.$t("gamas.mensaje_cerrar_gama"),
        "Cerrar",
        function () {
          app.preloader.show();
          self.Gamas
            .cerrar({ itemId: id, comprobarbl: comprobarbl })
            .then(function (res) {
              app.preloader.hide();
              if (res) {
                app.toast
                  .create({
                    icon: '<i class="f7-icons">checkmark</i>',
                    text: self.$t("gamas.mensaje_gama_cerrada"),
                    position: "center",
                    destroyOnClose: true,
                    closeTimeout: 2000
                  })
                  .open();
                self.$refs.rlist.refresh();
              }
            })
            .catch(function (error) {
              app.preloader.hide();
              var message = typeof error == "string" ? error : error.message;
              if(error.code == 'BL_NO_EJECUTADAS'){
                self.$f7.dialog.confirm(
                  message + ". <b>¿Desea continuar de todas formas?</b>",
                  "Cerrar",
                  () => {
                    app.preloader.show();
                    self.Gamas
                    .cerrar({ itemId: id, comprobarbl: false })
                    .then(function (res) {
                      app.preloader.hide();
                      if (res) {
                        app.toast
                          .create({
                            icon: '<i class="f7-icons">checkmark</i>',
                            text: self.$t("gamas.mensaje_gama_cerrada"),
                            position: "center",
                            destroyOnClose: true,
                            closeTimeout: 2000
                          })
                          .open();
                        self.$refs.rlist.refresh();
                      }
                    })
                    .catch(function (error) {
                      app.preloader.hide();
                      var message = typeof error == "string" ? error : error.message;
                      app.dialog.alert(message);
                    });
                  }
                );
              } else {
                app.dialog.alert(message);
              }
            });
        }
      );
    },
    abrir: function(id) {
      var self = this;
      var app = self.$f7;
      self.$f7.dialog.confirm(
        self.$t("gamas.mensaje_abrir_gama"),
        "Abrir",
        function () {
          app.preloader.show();
          self.Gamas
            .abrir({ itemId: id })
            .then(function (res) {
              app.preloader.hide();
              if (res) {
                app.toast
                  .create({
                    icon: '<i class="f7-icons">checkmark</i>',
                    text: self.$t("gamas.mensaje_gama_reabierta"),
                    position: "center",
                    destroyOnClose: true,
                    closeTimeout: 2000
                  })
                  .open();
                self.$refs.rlist.refresh();
              }
            })
            .catch(function (error) {
              app.preloader.hide();
              var message = typeof error == "string" ? error : error.message;
              app.dialog.alert(message);
            });
        }
      );
    }

  },
  mounted: function() {
    var self = this;
    var app = self.$f7;
    /*app.dataTable.create({
      el: self.$refs.datatable
    });*/
  }
};
</script>