import { routesLeft, routesMain } from '../pages/routes-main.js';

import alertas from '../pages/alertas/routes.js';
import centrostrabajo from '../pages/centrostrabajo/routes.js';
import combos from '../pages/combos/routes.js';
import dispositivos from '../pages/dispositivos/routes.js';
import empleados from '../pages/empleados/routes.js';
import evaluadorjs from '../pages/evaluadorjs/routes.js';
import gamas from '../pages/gamas/routes.js';
import informes from '../pages/informes/routes.js';
import inspeccionesCampa from '../pages/inspeccionesCampa/routes.js';
import jornadaConfiguracion from '../pages/jornadaConfiguracion/routes.js';
import jornadaTrabajo from '../pages/jornadaTrabajo/routes.js';
import lineasPrevisionDemandas from '../pages/lineasPrevisionDemandas/routes.js';
import lineasPrevisionLlegadas from '../pages/lineasPrevisionLlegadas/routes.js';
import lineasReactividad from '../pages/lineasReactividad/routes.js';
import notificaciones from '../pages/notificaciones/routes.js';
import previsionDemanda from '../pages/previsionDemandas/routes.js';
import previsionLlegada from '../pages/previsionLlegadas/routes.js';
import paises from '../pages/paises/routes.js';
import properties from '../pages/properties/routes.js';
import reactividad from '../pages/reactividad/routes.js';
import selecciones from '../pages/selecciones/routes.js';
import tarifas from '../pages/tarifas/routes.js';
import ubicaciones from '../pages/ubicaciones/routes.js';
import usuarios from '../pages/usuarios/routes.js';

var routes = [
    ...gamas,
    ...selecciones,
    ...inspeccionesCampa,
    ...reactividad,
    ...lineasReactividad,
    ...alertas,
    ...previsionDemanda,
    ...lineasPrevisionDemandas,
    ...previsionLlegada,
    ...lineasPrevisionLlegadas,
    ...usuarios,
    ...empleados,
    ...tarifas,
    ...ubicaciones,
    ...centrostrabajo,
    ...dispositivos,
    ...evaluadorjs,
    ...notificaciones,
    ...informes,
    ...combos,
    ...jornadaConfiguracion,
    ...jornadaTrabajo,
    ...paises,
    ...properties,
    ...routesMain
];
export { routes, routesLeft };

