import DatabaseModel from './DatabaseModel.js';

var Empleado = Object.create(DatabaseModel);

Empleado.model = 'empleado';
Empleado.modelFront = 'empleado';

export default Empleado;

Empleado.resetPass = function(params) {
    var self = this;
    if (!params.itemId) reject({ error: 'itemId not defined' });
    return new Promise(function(resolve, reject) {
        self.request.promise({
            url: self.server_zipado + 'zipado/' + self.model + '/reset_password/' + params.itemId,
            method: 'PUT',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data);
            }

        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response));
            } catch (e) {
                reject(xhr.response);
            }
        });

    });
};