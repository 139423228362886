import LineasPrevisionDemandas from './lineasPrevisionDemandas.vue';
import LineasPrevisionDemandaForm from './lineasPrevisionDemanda-form.vue';
import RoutesUtils from '../../js/RoutesUtils.js';

var routes = [{
        path: '/lineasPrevisionDemandas/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('lineasPrevisionDemandas', ['ADMIN', 'CHECK_MAN'], LineasPrevisionDemandas),
        keepAlive: false
    },
    {
        path: '/lineasPrevisionDemandas/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('lineasPrevisionDemandas/new', ['ADMIN', 'CHECK_MAN'], LineasPrevisionDemandaForm)
    },
    {
        path: '/lineasPrevisionDemandas/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('lineasPrevisionDemandas/edit', ['ADMIN', 'CHECK_MAN'], LineasPrevisionDemandaForm)
    },
    {
        path: '/lineasPrevisionDemandas/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('lineasPrevisionDemandas/view', ['ADMIN', 'CHECK_MAN'], LineasPrevisionDemandaForm, { editable: false })
    },
];
export default routes;