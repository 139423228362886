import RoutesUtils from '../../js/RoutesUtils.js';
import ReactividadForm from './reactividad-form.vue';
import Reactividad from './reactividad.vue';

var routes = [{
        path: '/reactividad/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('reactividad', ['ADMIN', 'CHECK_MAN'], Reactividad),
        keepAlive: true
    },
    {
        path: '/reactividad/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('reactividad/new', ['ADMIN', 'CHECK_MAN'], ReactividadForm)
    },
    {
        path: '/reactividad/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('reactividad/edit', ['ADMIN', 'CHECK_MAN'], ReactividadForm)
    },
    {
        path: '/reactividad/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('reactividad/view', ['ADMIN', 'CHECK_MAN'], ReactividadForm, { editable: false })
    },
];
export default routes;