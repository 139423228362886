<template>
  <f7-app :params="f7params">
    <f7-appbar>
      <div class="left">
        <f7-button small panel-toggle="left" class="display-flex" icon-f7="bars"></f7-button>
        <a href="/">
          <img src="static/img/logo_TRACER2021-1.png" style="max-height: 1.6rem;"  />
        </a>
      </div>

      <div class="right">
        <f7-button
          small
          class="display-flex"
          icon-f7="lightbulb"
          popup-open=".popup-sugerencia"
        >Sugerencia de Mejora</f7-button>
        <f7-button
          small
          class="display-flex"
          icon-f7="question_circle_fill"
          @click="popupAyudaOpened=true"
        ></f7-button>
      </div>
      <f7-popup v-if="popupAyudaOpened" :opened="true" @popup:closed="popupAyudaOpened = false">
        <r-page :page-content="true">
          <f7-navbar title="Ayuda">
            <f7-nav-right>
              <f7-link popup-close icon-f7="multiply"></f7-link>
            </f7-nav-right>
          </f7-navbar>
          <div class="list no-hairlines no-margin full-height">
            <ul class="full-height">
              <li class="item-content item-input full-height">
                <div class="item-inner full-height">
                  <div class="item-input-wrap full-height">
                    Si necesita ayuda o tiene cualquier problema con el uso de la aplicación puede contactar con:
                    <br />
                    <br />
                    <f7-link
                      href="mailto:soporte_trace@grupolince.com"
                      class="justify-left"
                      external
                    >soporte_trace@grupolince.com</f7-link>
                    <!-- <f7-link href="tel:+34918326123" class="justify-left" external>918326123</f7-link> -->
                  </div>
                  <div class="item-input-wrap full-height">
                    Para descargar la aplicación Android puede utilizar el siguiente enlace:
                    <br />
                    <br />
                    <f7-link
                      :href="appDownload"
                      class="justify-left"
                      external
                    >APK TRACE</f7-link>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </r-page>
      </f7-popup>
      <f7-popup ref="popupSugerencia" class="popup-sugerencia" swipe-to-close>
        <r-page :page-content="true">
          <f7-navbar title="Sugerencia de Mejora">
            <f7-nav-right>
              <f7-link popup-close>Cerrar</f7-link>
            </f7-nav-right>
          </f7-navbar>

          <!-- <div style="height: 100%" class="display-flex justify-content-center align-items-center"> -->
          <f7-card class="elevation-3 padding-vertical-half padding-right">
            <f7-card-content>
              <textarea
                floating-label
                placeholder="Sugerencia"
                name="sugerenciaMejora"
                ref="sugerenciaMejora"
                class="padding-left margin-right"
              ></textarea>
            </f7-card-content>
            <f7-button
              fill
              icon-f7="paperplane"
              text="Enviar"
              class="margin-left display-flex"
              color="blue"
              @click="sendSugerencia"
            ></f7-button>
          </f7-card>
          <!-- </div> -->
        </r-page>
      </f7-popup>
    </f7-appbar>
    <!-- Left panel with cover effect-->
    <f7-panel left cover :visibleBreakpoint="1024">
      <f7-view class="view-left" :init="false"></f7-view>
    </f7-panel>

    <!-- Your main view, should have "view-main" class -->
    <f7-view main class="safe-areas" url="/" :init="false"></f7-view>
  </f7-app>
</template>
<style>
  @import '../css/medium-editor-tables.css';
</style>
<style scoped>
app textarea {
  border: none;
  width: 100%;
  min-height: 200px;
}
.justify-left {
  justify-content: initial;
}
.appbar {
  border-bottom: 1px solid #aaa;

}
</style>
<script>
import { routes, routesLeft } from "../js/routes.js";
import Usuario from "./../js/db/Usuario.js";
import Sugerencia from "./../js/db/Sugerencia.js";
import Notificacion from "./../js/db/Notificacion.js";
import FileUtils from '../js/FileUtils';
import Flash from '../js/FlashUtils';

export default {
  methods: {
    processNotification(data, app) {
      if (data.redirectUrl) {
        app.views.main.router.navigate(data.redirectUrl);
      } else if (data.redirectExternalUrl) {
        cordova.InAppBrowser.open(data.redirectExternalUrl, "_blank");
      }
    },
    sendSugerencia: function() {
      var self = this;
      var app = self.$f7;
      Sugerencia.post({
        data: { sugerencia: self.$refs.sugerenciaMejora.value }
      })
        .then(function(res) {
          self.$refs.sugerenciaMejora.value = "";
          if (self.$refs.popupSugerencia) {
            self.$refs.popupSugerencia.close(true);
          }
          app.dialog.alert("Gracias por su sugerencia, se ha realizado el envío correctamente.");
        })
        .catch(function(error) {
          var app = self.$f7;
          if(error.message){
              app.dialog.alert(error.message);
            } else {
              app.dialog.alert(error);
            }
        });
    },
    _loadPages: function (f7, app) {
      // TODO Ver como hacerlo mejor
      const actualPath = window.location.hash.replace("#!", "");
      if (!actualPath.startsWith("/public")) {
        app.methods.startSession();
      }
      var leftView = app.views.create(".view-left", {
        name: "left",
        routes: routesLeft,
        url: "/main-menu/"
      });
      var mainView = app.views.create(".view-main", {
        url: "/",
        preloadPreviousPage: false,
        pushState: true,
        xhrCache: false
      });
    }
  },
  data() {
    return {
      appDownload: process.env.VUE_APP_DOWNLOAD,
      popupAyudaOpened: false,
      // Framework7 Parameters
      f7params: {
        id: "com.zipado.admin", // App bundle ID
        name: "Tracer", // App name
        theme: "aurora", // Automatic theme detection
        // App root data
        data: function() {
          return {
            URL_API: "",
            user: {},
            appDataPath:
              typeof eapp != "undefined" ? eapp.getPath("userData") : (typeof cordova != "undefined" && typeof cordova.file != "undefined" ? cordova.file.dataDirectory + 'files' : "")
          };
        },
        methods: {
          checkAuth: function() {
            return this.data.session ? true : false;
          },
          checkPermission: function(pagename) {
            return true;
          },
          startSession: function() {
            var session = localStorage.getItem("session");
            if (typeof session != "undefined" && session) {
              var sessionObj = JSON.parse(session);
              this.data.session = sessionObj;
              this.request.setup({
                headers: {
                  Authorization: "Bearer " + sessionObj.token
                }
              });
              var user = localStorage.getItem("user");
              var redirect = false;
              if (typeof user == "undefined" || !user) {
                redirect = true;
                this.methods.loadGlobals(redirect);
              }
            }
            //TODO: Load user data
          },
          saveSession: function(obj) {
            this.data.session = obj;
            localStorage.setItem("session", JSON.stringify(obj));
          },
          removeSession: function() {
            delete this.data.session;
            localStorage.removeItem("session");
            localStorage.removeItem("user");
          },
          loadGlobals: function(redirect) {
            var app = this;

            Usuario.me()
              .then(function(res) {
                var user = res;
                app.data.user = user;
                localStorage.setItem("user", JSON.stringify(user));
                if (redirect) {
                  app.methods.redirect();
                }
              })
              .catch(function(e) {
                console.log(e);
                app.toast
                  .create({
                    text: e.error_description,
                    position: "center",
                    closeTimeout: 2000
                  })
                  .open();
                if (e.error == "invalid_token") {
                  app.methods.removeSession();
                  var currentURL = app.views.main.router.currentRoute.path;
                  Flash.set("redirectTo", currentURL + "?reloadPage");
                  app.views.main.router.navigate("/logout/");
                }
              });
          },
          redirect: function(){
            var app = this;
            var pathToGo = "/";
            var redirectTo = Flash.get("redirectTo");
            var directURL = Flash.get("directURL");
            app.views.left.router.navigate("/main-menu/");
            if(redirectTo){
              pathToGo = redirectTo;
            } else if (directURL) {
              var path = directURL.split("#!")[1];
              if (path) {
                pathToGo = path;
              } 
            }
            app.views.main.router.navigate(pathToGo);
          }
        },

        // App routes
        routes: routes,

        // Input settings
        input: {
          scrollIntoViewOnFocus:
            this.$device && this.$device.cordova && !this.$device.electron,
          scrollIntoViewCentered:
            this.$device && this.$device.cordova && !this.$device.electron
        },
        // Cordova Statusbar settings
        statusbar: {
          iosOverlaysWebView: true,
          androidOverlaysWebView: false
        },

        toast: {
          destroyOnClose: true
        },
      },
      Sugerencia,
      Notificacion
    };
  },
  mounted() {
    this.$f7ready(f7 => {
      var self = this;
      // Init cordova APIs (see cordova-app.js)
      if (f7.device.cordova) {
        cordovaApp.init(f7);
      }
      // Call F7 APIs here
      var app = this.$f7;

      self._loadPages(f7, app);

      window.app = app;

      //FIX external links inAppBrowser
      this.$$(this.$el).on("click", "a", function(e) {
        if (self.$$(this).hasClass("external")) {
          if (self.$$(this).hasClass("base64")) {
            if (typeof cordova == "undefined") {
              var link =
                '<iframe src="' +
                this.href +
                '" frameborder="0" style="margin:0; padding:0; border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>';
              var win = window.open();
              win.document.write(link);
            } else {
              var base64Data = this.href.split(',')[1];
              var data = FileUtils.b64toData(base64Data);
              FileUtils.openFile(data, this.dataset["name"], function() {}, function() {});
            }
          } else {
            var link = this.href;
            window.open(link, "_blank");
          }
          e.preventDefault();
          return false;
        }
      });
    });
  }
};
</script>