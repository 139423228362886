import RoutesUtils from '../../js/RoutesUtils.js';
import LineasReactividadForm from './lineasReactividad-form.vue';
import LineasReactividad from './lineasReactividad.vue';

var routes = [{
        path: '/lineasReactividad/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('lineasReactividad', ['ADMIN', 'CHECK_MAN'], LineasReactividad),
        keepAlive: false
    },
    {
        path: '/lineasReactividad/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('lineasReactividad/new', ['ADMIN', 'CHECK_MAN'], LineasReactividadForm)
    },
    {
        path: '/lineasReactividad/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('lineasReactividad/edit', ['ADMIN', 'CHECK_MAN'], LineasReactividadForm)
    },
    {
        path: '/lineasReactividad/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('lineasReactividad/view', ['ADMIN', 'CHECK_MAN'], LineasReactividadForm, { editable: false })
    },
];
export default routes;